import React from 'react'
import TextField from '../../atoms/TextField/TextField'
import Button from '../../atoms/Button/Button'
import './style.scss'

const PromoCode = props => {
  let baseClass = 'c-promocode';
  const {
    buttonText,
    placeholder ,
    type,
    description,
    onChange,
    value,
    onButtonClick,
  } = props;

  return (
    <div className={baseClass}>
      <div className="c-promocode__body">
        <TextField
          type={type}
          placeholder={placeholder}
          description={description}
          value={value}
          onChange={onChange}
        />
        <Button
          insideInput
          text={buttonText}
          onClick={onButtonClick}
        />
      </div>
    </div>
  )
};

PromoCode.defaultProps = {
  buttonText: 'apply',
  placeholder: 'Promo code',
  type: 'text',
  description: '',
};

export default PromoCode
