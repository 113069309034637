import React from 'react'
import './style.scss'

const Post = props => {

  let postClass = 'c-post'

  const {children} = props;

  return (
    <div className={postClass}>
      {children}
    </div>
  )
}

export default Post
