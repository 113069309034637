import React from 'react'
import Flexbox from '../../molecules/Flexbox/Flexbox'
import FlexboxItem from '../../atoms/FlexboxItem/FlexboxItem'
import Thumbnail from "../../atoms/Thumbnail/Thumbnail";
import Logo from './img/logo.png'
import IconFacebook from './img/icon-facebook.png'
import IconInstagram from './img/icon-instagram.png'
import './style.scss'

const Footer = () => {

  return (
    <section className="c-footer">
      <div className="c-footer__body">
        <div className="c-footer__left">
          <Thumbnail
            className="c-footer__thumb"
            imageUrl={Logo}
            alt="GoRental"
          />
        </div>
        <div className="c-footer__right">
          <Flexbox className="o-grid--bleed">
            <FlexboxItem className="col-xs-12 col-sm-6 col-md-3 c-footer__right-item">
              <i className="fa fa-address-card" /> : Jl. K.L. Yos Sudarso Kom L No. 5 Medan
            </FlexboxItem>
            <FlexboxItem className="col-xs-12 col-sm-6 col-md-3 c-footer__right-item">
              <i className="fa fa-phone" /> : 0853-6135-6135
            </FlexboxItem>
            <FlexboxItem className="col-xs-12 col-sm-6 col-md-3 c-footer__right-item">
              <i className="fa fa-envelope" /> : cs@gorental-indonesia.com
            </FlexboxItem>
            <FlexboxItem className="col-xs-12 col-sm-6 col-md-3 c-footer__right-item">
              <div className="c-footer__year">
                <div className="c-footer__year-item">
                  &copy; 2010 — 2020
                </div>
                <div className="c-footer__year-item">
                  <a
                    className="c-footer__year-icon"
                    href="https://www.instagram.com/gorentalindonesia/"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Instagram"
                  >
                    <img src={IconInstagram} alt="Instagram" />
                  </a>
                  <a
                    className="c-footer__year-icon"
                    href="https://www.facebook.com/Go-Rental-Indonesia-2077576475867381"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Facebook"
                  >
                    <img src={IconFacebook} alt="Facebook" />
                  </a>
                </div>
              </div>
            </FlexboxItem>
          </Flexbox>
        </div>
      </div>
    </section>
  )
}

export default Footer
