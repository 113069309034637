import React, {Fragment} from 'react';
import CountdownTimer from '../atoms/CountdownTimer/CountdownTimer'
import Heading from '../atoms/Heading/Heading'
import Text from '../atoms/Text/Text'
import Thumbnail from '../atoms/Thumbnail/Thumbnail'
import Separator from '../atoms/Separator/Separator'
import Section from '../molecules/Section/Section'
import Flag from '../molecules/Flag/Flag'
import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { orderAction } from '../../store/actions';
import Preloader from '../atoms/Preloader/Preloader';
import Shimmer from '../atoms/Shimmer/Shimmer';

import Bca from './img/bca.png';
import Mandiri from './img/mandiri.png';
import Bri from './img/bri.png';
import Bni from './img/bni.png';
import Permata from './img/permata.png';
import PaymentHowToPay from '../organism/PaymentHowToPay';

const { getOrder } = orderAction;

class Transfer extends React.Component {
  componentDidMount() {
    const { match, history, getOrder } = this.props;
    const { idHash } = match.params;

    if (!idHash) {
      return history.push('/airport-shuttle');
    }

    getOrder(idHash);
  }

  render() {
    const separatorStyle = {
      maxWidth: '590px',
      margin: 'auto'
    };

    // const faqStyle = {
    //   background: '#FAF7F7'
    // };

    const { order } = this.props;
    const { fetching, data } = order;

    let imageUrl = ""
    if (data.bank === "BCA") {
      imageUrl = Bca
    } else if ( data.bank === "MANDIRI") {
      imageUrl = Mandiri
    } else if ( data.bank === "BRI" ) {
      imageUrl = Bri
    } else if ( data.bank === "BNI") {
      imageUrl = Bni
    } else if ( data.bank === "PERMATA") {
      imageUrl = Permata
    }

    return (
      <Fragment>
        <Preloader isActive={fetching} />
        <Helmet>
          <title>Transfer - Go Rental</title>
          <meta name="description" content="24/7 service Medan KNO airport transfer and car rental (with driver) at competitive prices. With safety as our priority, our experienced driver will make sure you reach your destination safely & on time. Book with us today, hassle-free later!" />
        </Helmet>
        <Section
          variant="o-container--tiny"
          className="u-mt-80 u-mb-80"
        >
          <div className="u-align-center u-mb-30">
            <Heading tag="h3" className="u-mb-8">
              Please proceed with payment to continue your booking
            </Heading>
            <Text size="medium">
              Please make the payment within the given time:
            </Text>
          </div>

          <div className="u-mb-40" style={{ textAlign: 'center' }}>
            {!fetching && (
              <CountdownTimer milliSecond={data.time_left || 3600000} />
            )}
            {fetching && (
              <Shimmer style={{ width: '360px', height: '92px' }} isActive={true} />
            )}
          </div>
          <div className="u-mb-40">
            <Separator style={separatorStyle}/>
          </div>
          <div className="u-align-center u-mb-40">
            <div className="u-mb-8">
              <Text size="medium">
                Payment amount
              </Text>
            </div>
            {!fetching && (
              <Heading tag="h2">
                {new Intl.NumberFormat('id-ID', {
                  style: 'currency',
                  currency: 'IDR',
                }).format(data.amount || 0)}
              </Heading>
            )}
            {fetching && (
              <Shimmer style={{ width: '300px', height: '48px' }} isActive={true} />
            )}
          </div>
          <div className="u-align-center">
            <div className="u-mb-8">
              <Text size="medium">Transfer to this virtual account</Text>
            </div>
            {!fetching && (
              <Flag>
                <Thumbnail imageUrl={imageUrl}/>
                <Heading tag="h2">
                  {data.account_number || ''}
                </Heading>
              </Flag>
            )}
            {fetching && (
              <Shimmer style={{ width: '450px', height: '70px' }} isActive={true} />
            )}
          </div>
        </Section>

        {!!data.bank && <PaymentHowToPay bank={data.bank} />}

      </Fragment>
    )
  }
}

const mapStateToProps = ({ order }) => ({ order });

const mapDispatchToProps = dispatch => bindActionCreators({
  getOrder,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Transfer));
