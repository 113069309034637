import React, { Component } from "react";
import Text from "../Text/Text";
import PropTypes from "prop-types";

class AccordionSection extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  };

  onClick = () => {
    this.props.onClick(this.props.label);
  };

  render() {
    const { onClick, props: { isOpen, label } } = this;

    return (
      <div
        className={isOpen ? 'c-accordion__head is-active' : 'c-accordion__head' }
      >
        <div
          className="c-accordion__head-body"
          onClick={onClick}
          style={{ cursor: "pointer" }}
        >
          <div className="c-accordion__title">
            <Text
              bold
              size="medium"
            >
              {label}
            </Text>
          </div>
          <div className="c-accordion__head-icon">
            {!isOpen && <span>+</span>}
            {isOpen && <span>-</span>}
          </div>
        </div>
        {isOpen && (
          <div className="c-accordion__body">
            <Text
              size="medium"
            >
              {this.props.children}
            </Text>
          </div>
        )}
      </div>
    );
  }
}

export default AccordionSection;
