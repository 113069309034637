import React from 'react'
import './style.scss'

const Separator = props => {

  const baseClass = 'c-separator'
  const {className, style} = props;

  return (
    <div
      className={baseClass +' '+ (className || '') }
      style={style}
      ></div>
  )
};

export default Separator

