import React from 'react'
import './style.scss'

class TextField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focus: false,
      visited: false,
      changed: false,
      inputClassNames: [],
    };

    this.className = 'c-textfield';
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  onFocus() {
    this.setState({
      focus: true,
      visited: true,
      changed: false,
      inputClassNames: [],
    });
  };

  onBlur() {
    const { value } = this.props;
    this.setState({ focus: false, inputClassNames: value ? [] : this.state.inputClassNames });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { visited, focus, changed } = this.state;
    const { required, value, error, markError } = this.props;

    if ((markError || (error && value) || (required && !focus && !value && visited)) && !changed) {
      this.setState({
        changed: true,
        inputClassNames: ['c-textfield__error'],
      });
    }
  }

  render() {
    const {
      type,
      name,
      placeholder,
      description,
      value,
      disabled,
      onChange,
      autoComplete,
    } = this.props;

    const { inputClassNames } = this.state;

    return (
      <div className={this.className}>
        <input
          className={inputClassNames.join(' ')}
          type={type}
          value={value}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          autoComplete={autoComplete || 'off'}
        />
        <div className="c-textfield__desc">
          {description}
        </div>
      </div>
    )
  }
};

TextField.defaultProps = {
  type: 'text',
  description: 'Description here'
};

export default TextField;
