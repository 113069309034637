import React, { Fragment } from 'react'
import './style.scss'

const CheckBox = props => {
  let baseClass = 'c-checkbox';

  const {
    title,
    description,
    checked,
    name,
    children,
    useChildren,
    onChange,
    id
  } = props;

  return (
    <Fragment>
      {!useChildren &&
        <div className={baseClass}>
          <label className="c-checkbox__label" htmlFor={id}>
            <div className="c-checkbox__title">
              {title}
            </div>
            <input
              type="checkbox"
              id={id}
              checked={checked}
              name={name}
              onChange={onChange}
            />
            <span className="c-checkbox__mark" />
            <div className="c-checkbox__desc">
              {description}
            </div>
          </label>
        </div>
      }
      {useChildren &&
        <div className={baseClass}>
          <label className="c-checkbox__label" htmlFor={id}>
            <div className="c-checkbox__title">
              {children}
            </div>
            <input type="checkbox" id={id} checked={checked} name={name} onChange={onChange} />
            <span className="c-checkbox__mark" />
          </label>
        </div>
      }
    </Fragment>
  )
};

CheckBox.defaultProps = {
  title: 'Title here',
  description: 'Description here'
};

export default CheckBox
