import React from 'react'
import './style.scss'

const SelectBox = props => {
  let baseClass = 'c-selectbox';
  const {
    name,
    id,
    description,
    children,
    defaultValue,
    value,
    onChange,
    disabled
  } = props;

  return (
    <div className={baseClass}>
      <select
        name={name}
        id={id}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        {children}
      </select>
      <div className="c-selectbox__desc">
        {description}
      </div>
    </div>
  )
};

export default SelectBox
