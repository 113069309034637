import React, { Fragment } from 'react';
import Header from './components/organism/Header/Header'
import Footer from './components/organism/Footer/Footer'
import StyleGuide from './components/organism/StyleGuide/StyleGuide'
import HomePage from './components/pages/HomePage'
import AirportShuttle from './components/pages/AirportShuttle/index'
import CarRental from './components/pages/CarRental/index'
import ManageRent from './components/pages/ManageRent';
import PaymentMethod from './components/pages/PaymentMethod/index'
import Transfer from './components/pages/Transfer'
import ModifyOrder from './components/pages/ModifyOrder'
import Tos from './components/pages/Tos/index'
import Privacy from './components/pages/Privacy/index'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import './App.scss'
import ScrollToTop from './components/atoms/ScrollToTop';
import OvoPay from './components/pages/OvoPay';
import VoucherPay from "./components/pages/VoucherPay";
import ReactGA from 'react-ga';


function initializeReactGA() {
    ReactGA.initialize('UA-176899673-1');
    ReactGA.pageview('/');
}

function App({ background }) {

  let baseClass = 'c-wrapper';

  if (background === 'grey') {
    baseClass += ' c-wrapper--grey'
  }

  return (
    <Fragment>
      <Helmet>
        <title>Go Rental - Airport Transfer KNO & Car Rental Medan</title>
        <meta name="description" content="24/7 service Medan car rental or KNO airport transfer at a competitive price. Reach your destination safely & on time. Book Go Rental today, hassle-free later!" />
      </Helmet>
      <Router>
        <div className={baseClass}>

          {/* Header */}
          <Header />

          {/* Pages */}
          <ScrollToTop>
            <Switch>
            <Route exact path="/" >
              <HomePage />
            </Route>
            <Route path="/airport-shuttle" component={AirportShuttle}/>
            <Route path="/car-rental" component={CarRental} />
            <Route path="/payment-method" component={PaymentMethod} />
            <Route path="/manage-rent" component={ManageRent} />
            <Route path="/transfer/:idHash?">
              <Transfer />
            </Route>
            <Route path="/ovo/:idHash?">
              <OvoPay />
            </Route>
            <Route path="/voucher/:idHash?">
              <VoucherPay />
            </Route>
            <Route path="/modify-order/:idHash" component={ModifyOrder} />
            <Route path="/tos">
              <Tos />
            </Route>
            <Route path="/privacy">
              <Privacy />
            </Route>
            <Route path="/comp">
              <StyleGuide />
            </Route>
          </Switch>
          </ScrollToTop>
        </div>

        {/* Footer */}
        <Footer />
      </Router>
    </Fragment>
  );
}

export default App;
