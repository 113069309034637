import React from 'react';
import Accordion from '../../../atoms/Accordion/Accordion';

const Mandiri = () => (
  <Accordion>
    <div label="Mandiri ATM">
      &bull; Insert your ATM card and select "ENGLISH"
      <br /><br />
      &bull; Enter PIN, then select "ENTER"
      <br /><br />
      &bull; Select "PAYMENT"
      <br /><br />
      &bull; Select "MULTI PAYMENT"
      <br /><br />
      &bull; Enter company code : "88608" (Xendit 88608), then press "CORRECT"
      <br /><br />
      &bull; Enter Virtual Account Number, then press "CORRECT"
      <br /><br />
      &bull; Enter the amount to transfer, then press "CORRECT"
      <br /><br />
      &bull; Customer details will be displayed. Choose number 1 according to the amount billed and then press "YES"
      <br /><br />
      &bull; Payment confirmation will be displayed. Select "YES" to proceed
      <br /><br />
      &bull; Keep your receipt as a formal proof of payment from Mandiri Bank
      <br /><br />
      &bull; Your transaction is successful
    </div>
    <div label="Mandiri Mobile Banking">
      &bull; Log in Mobile Banking Mandiri Online
      <br /><br />
      &bull; Click “Icon Menu” in the top left side
      <br /><br />
      &bull; Select "Payment"
      <br /><br />
      &bull; Select “Make New Payment”
      <br /><br />
      &bull; Select "Multi Payment"
      <br /><br />
      &bull; Select "Xendit 88608" as service provider
      <br /><br />
      &bull; Select "Virtual Account Number"
      <br /><br />
      &bull; Enter your virtual account number with “88608” as Company Code then select “Add as New Number”
      <br /><br />
      &bull; Input “Nominal” then select “Confirmation”
      <br /><br />
      &bull; Select “Continue”
      <br /><br />
      &bull; Appear Payment Confirmation Page
      <br /><br />
      &bull; Scroll down in payment confirmation page then select “Confirmation”
      <br /><br />
      &bull; Input “PIN” and the transaction will be completed
    </div>
    <div label="Mandiri Internet Banking">
      &bull; Go to Mandiri Internet Banking website: https://ibank.bankmandir i.co.id/retail3/
      <br /><br />
      &bull; Login with your USER ID and PIN
      <br /><br />
      &bull; Select "Payment"
      <br /><br />
      &bull; Select "Multi Payment"
      <br /><br />
      &bull; Select "My Account Number"
      <br /><br />
      &bull; Select "Xendit 88608" as service provider
      <br /><br />
      &bull; Select "Virtual Account Number"
      <br /><br />
      &bull; Enter your virtual account number
      <br /><br />
      &bull; Go to confirmation page 1
      <br /><br />
      &bull; Click on TOTAL if all details are correct and then click on "CONTINUE"
      <br /><br />
      &bull; Go to confirmation page 2
      <br /><br />
      &bull; Enter Challenge Code from your Internet Banking Token, then click on "Send"
      <br /><br />
      &bull; You will be directed to the confirmation page once your payment has been completed
    </div>
    <div label="Other Banks' ATM">
      &bull; Select​ ​Others 
      <br /><br />
      &bull; Select​ ​Transfer 
      <br /><br />
      &bull; Select​ ​from​ ​saving 
      <br /><br />
      &bull; Select​ ​to​ ​other​ ​Bank 
      <br /><br />
      &bull; Enter​ ​Bank​ ​Code​ ​followed​ ​by  your​ ​Virtual​ ​Account​ ​number  (Mandiri​ ​008+virtual​ ​account  number) 
      <br /><br />
      &bull; Input​ ​the​ ​amount​ ​billed​ ​as  Nominal​ ​Transfer 
      <br /><br />
      &bull; Finish,​ ​transaction​ ​has​ ​been  successful 
    </div>
    <div label="Other Banks' Mobile Banking or Internet Banking">
      &bull; Input​ ​User​ ​ID​ ​and​ ​Password 
      <br /><br />
      &bull; Select​ ​Transfer 
      <br /><br />
      &bull; Select​ ​Transfer​ ​to​ ​other​ ​bank 
      <br /><br />
      &bull; Select​ ​bank​ ​destination 
      <br /><br />
      &bull; Enter​ ​Virtual​ ​Account​ ​number  (Mandiri​ ​88608-virtual​ ​account  number) 
      <br /><br />
      &bull; Input​ ​the​ ​Nominal​ ​Bill​ ​as​ ​Nominal  Transfer 
      <br /><br />
      &bull; Finish,​ ​transaction​ ​has​ ​been  successful 
    </div>
  </Accordion>
);

export default Mandiri;
