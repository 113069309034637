import React from 'react'
import './style.scss'

const Logo = props => {
  const {src, alt} = props;

  return (
    <div className="c-logo">
      <div className="c-logo__body">
        <img src={ src } alt={ alt } className="c-logo__img" />
      </div>
    </div>
  )
}

export default Logo
