import dayjs from 'dayjs';
import React from 'react'
import Text from '../../atoms/Text/Text'
import './style.scss'
import CircleCheckBox from '../../atoms/CircleCheckBox/CircleCheckBox';

const Calendar = (props) => {

  const { date, disabledDates, selectedDates, days, onChange } = props;
  const dates = [];
  const dayNameData = ['Sun', 'Mon', 'Tue', 'Wed', 'Tru', 'Fri', 'Sat'];
  const time = dayjs(date);
  const title = time.format('MMMM YYYY');
  const maxDate = time.add(days - 1, 'day');
  const lines = Math.ceil((days + time.day()) / 7);

  let day = time.day(0);
  for (let i = 0; i < lines; i++) {
    dates[i] = [];
    for (let j = 0; j < 7; j++) {
      dates[i].push(day.add((7 * i) + j, 'day'))
    }
  }

  const isDisabled = selectedDate => {
    return selectedDate.isBefore(time)
      || selectedDate.isAfter(maxDate)
      || (disabledDates || []).indexOf(selectedDate.format('YYYY-MM-DD')) >= 0;
  }

  const isChecked = selectedDate => {
    return (selectedDates || []).indexOf(selectedDate.format('YYYY-MM-DD')) >= 0;
  }

  return (
    <div className="c-calendar">
      <div className="c-calendar__title">
        <Text size="medium" bold>
          { title }
        </Text>
      </div>
      <ul className="c-calendar__day">
        {dayNameData.map((value, index) => {
          return <li key={index}>{value}</li>
        })}
      </ul>
      <div className="c-calendar__number">
        <table>
          <tbody>
            {new Array(lines || 3).fill(undefined).map((_, i) => (
              <tr key={i}>
                {Array(7).fill(undefined).map((_, j) => (
                  <td width="14.285%" key={j}>
                    <CircleCheckBox
                      number={dates[i][j].date()}
                      checked={isChecked(dates[i][j])}
                      disabled={isDisabled(dates[i][j])}
                      onChange={e => onChange(e, dates[i][j].format('YYYY-MM-DD'))}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Calendar
