import React from 'react'

const Section = props => {

  let baseClass = 'o-container';
  const {className, variant, children, style} = props;

  return (
    <div
      className={baseClass +' '+ (variant || "") +' '+ (className || '')}
      style={style}
    >
      { children }
    </div>
  )
}

export default Section
