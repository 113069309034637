import React, { Component } from 'react'
import Flexbox from '../../molecules/Flexbox/Flexbox';
import FlexboxItem from '../../atoms/FlexboxItem/FlexboxItem';
import Text from '../../atoms/Text/Text';
import TextField from '../../atoms/TextField/TextField';
import SelectBox from '../../atoms/SelectBox/SelectBox';
import PromoCode from '../../molecules/PromoCode/PromoCode';
import CheckBox from '../../atoms/CheckBox/CheckBox';
import Button from '../../atoms/Button/Button';
import Separator from '../../atoms/Separator/Separator';
import { Link } from 'react-router-dom';
import './style.scss';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect, withRouter } from 'react-router';
import {
  airportShuttleBookingAction,
  carRentalBookingAction,
  paymentMethodAction,
} from '../../../store/actions';
import Preloader from '../../atoms/Preloader/Preloader';
import Shimmer from "../../atoms/Shimmer/Shimmer";

import { idr } from 'api/helpers/common';

class PaymentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      agreement: false,
      promoCode: null,
      successMessage: '',
    };

    this.checkPromoCode = this.checkPromoCode.bind(this);
  }

  toggleTos() {
    this.setState({
      agreement: !this.state.agreement,
    });
  }

  donePayWithOvo() {
    this.props.history.push('/');
  }

  calculatePriceAirportShuttle(promoCode, paymentMethod) {
    const {
      toAirport,
      airportID,
      pickupDatetime,
      flightNumber,
      numberOfPassanger,
      address,
      numberOfLuggage,
      numberOfCars,
      roundtrip,
      areaID,
      additionalAreaID,
      returnDatetime,
      productID,
    } = this.props.airportShuttleBooking.payload;

    this.props.calculatePriceAirportShuttle({
      toAirport,
      airportID,
      pickupDatetime,
      flightNumber,
      numberOfPassanger,
      address,
      numberOfLuggage,
      numberOfCars,
      roundtrip,
      areaID,
      additionalAreaID,
      returnDatetime,
      productID,
      promoCode,
      paymentMethod
    });
  }

  calculatePriceCarRental(promoCode, paymentMethod) {
    const {
      pickupAddress,
      pickupDistrict,
      startRentalDate,
      dropoffAddress,
      dropoffDistrict,
      numberOfCars,
      rentalDuration,
      rentalHours,
      allInPackage,
      productID,
      outOfTown,
    } = this.props.carRentalBooking.payload;

    this.props.calculatePriceCarRental({
      pickupAddress,
      pickupDistrict,
      startRentalDate,
      dropoffAddress,
      dropoffDistrict,
      numberOfCars,
      rentalDuration,
      rentalHours,
      allInPackage,
      productID,
      outOfTown,
      promoCode,
      paymentMethod
    });
  }

  calculatePrice(promoCode, paymentMethod) {
    if (this.props.from === 'airport-shuttle') return this.calculatePriceAirportShuttle(promoCode, paymentMethod);
    return this.calculatePriceCarRental(promoCode, paymentMethod);
  }

  setPromoCode(value) {
    this.props.setPromoCode(value);
    this.props.setState(['promoCode', value]);
  }

  async checkPromoCode(e, code) {
    e.preventDefault();

    const { airportShuttleBooking, carRentalBooking, from } = this.props;
    const { roundtrip, promoCode } =  airportShuttleBooking.payload;
    const isAirportShuttle = from === 'airport-shuttle';
    const paymentMethod = isAirportShuttle ? airportShuttleBooking.payload.paymentMethod : carRentalBooking.payload.paymentMethod;

    const payload = {
      orderType: isAirportShuttle ? 'AIRPORT SHUTTLE' : 'CAR RENTAL',
      userID: null,
      ...(isAirportShuttle ? { roundtrip } : {})
    };

    if (promoCode) {
      if (global.confirm('Remove promo code?')) {
        this.setPromoCode(null);
        this.setState({ promoCode: null });
        this.calculatePrice(null, paymentMethod);
      }
    }
    else if (code) {
      try {
        const response = await this.props.getPromoByCode(code, payload);
        if (response) {
          this.setState({
            successMessage: `Promo code [${code}] applied`
          });
          this.setPromoCode(code);
          setTimeout(() => {
            this.calculatePrice(code, paymentMethod);
          }, 0);
        }
      } catch (e) {
        this.setState({ promoCode: null });
      }
    }
  }

  componentDidMount() {
    const { getPaymentMethod } = this.props;

    getPaymentMethod();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let paymentMethodChanged = false;
    let paymentMethod = '';
    const { from } = this.props;


    if (from === 'airport-shuttle') {
      paymentMethod = this.props.airportShuttleBooking.payload.paymentMethod;
      paymentMethodChanged = prevProps.airportShuttleBooking.payload.paymentMethod !== paymentMethod
    } else if(from === 'car-rental') {
      paymentMethod = this.props.carRentalBooking.payload.paymentMethod;
      paymentMethodChanged = prevProps.carRentalBooking.payload.paymentMethod !== paymentMethod;
    }

    if (paymentMethodChanged) {
      this.calculatePrice(this.state.promoCode, paymentMethod)
    }
  }

  clearBookingErrorMessage() {
    this.props.clearBookingErrorMessage();
    this.props.clearBookingErrorMessageCarRental();
  }

  closeMessage() {
    this.props.clearPromoCodeMessage();
    this.clearBookingErrorMessage();
    this.setState({ successMessage: '' });
  }

  setOvoPhoneNumber(value) {
    this.props.setOvoPhoneNumber(value);
    this.props.setState(['ovoPhoneNumber', value]);
  }

  setPaymentMethod(value) {
    const isAirportShuttle = this.props.from === 'airport-shuttle';
    const isOvo = (value || '').toLowerCase() === 'ovo';
    const phoneNumber = isAirportShuttle ? this.props.airportShuttleBooking.payload.phoneNumber : this.props.carRentalBooking.payload.phoneNumber;
    const ovoPhoneNumber = isOvo ? phoneNumber : null
    this.setOvoPhoneNumber(ovoPhoneNumber);
    this.props.setPaymentMethod(value);

    this.props.setState(['paymentMethod', value]);
  }

  getTotalPrice(from) {
    if (from === 'airport-shuttle') return this.props.airportShuttleBooking.totalPrice;
    return this.props.carRentalBooking.totalPrice;
  }

  isValidPayload() {
    return this.props.airportShuttleBooking.isValidPayload || this.props.carRentalBooking.isValidPayload;
  }

  book(payload) {
    if (this.props.from === 'airport-shuttle') return this.props.bookAirportShuttle(payload);
    return this.props.bookCarRental(payload);
  }

  render() {
    const { agreement, successMessage } = this.state;

    const {
      airportShuttleBooking,
      carRentalBooking,
      from,
      paymentMethod,
    } = this.props;

    const isAirportShuttle = from === 'airport-shuttle';

    const {
      payload,
      isBooking,
      isCalculating,
      bookingErrorMessage,
      bookingData,
      fetchingPromoCode,
      promoCodeMessage,
    } = (isAirportShuttle ? airportShuttleBooking : carRentalBooking);

    const isValidPayload = this.isValidPayload();
    const totalPrice = this.getTotalPrice(from);

    const booked = Object.keys(bookingData).length > 0;
    const bookedWithOvo = booked && bookingData.paymentMethod && bookingData.paymentMethod.toLowerCase() === 'ovo';
    const payWithVoucher = booked && bookingData.total && bookingData.total === 0;
    const paymentPage = payWithVoucher === 0 ? 'voucher' : (bookedWithOvo ? 'ovo' : 'transfer');

    return (
      <React.Fragment>
        {booked && (
          <Redirect to={{ pathname: `/${paymentPage}/${bookingData.orderHash}` }} />
        )}
        <Preloader isActive={!!isBooking || fetchingPromoCode} />
        <Preloader
          isActive={!!bookingErrorMessage || promoCodeMessage || successMessage}
          displayImage={false}
          title={bookingErrorMessage || promoCodeMessage || successMessage}
          onClick={() => this.closeMessage()}
        />
        <div className="g-paymentform">
          <div className="g-paymentform__body">
            <div className="u-mb-30">
              <Text size="medium">
                Choose payment methods
              </Text>
            </div>
            <form>
              <div className="u-mb-30">
                <SelectBox
                   value={payload.paymentMethod || ''}
                   onChange={({ target }) => this.setPaymentMethod(target.value)}
                >
                  <option value="" disabled>Choose Payment</option>
                  {(paymentMethod.data || []).map(({ id, code, name }) => (
                    <option key={id} value={code}>{name}</option>
                  ))}
                </SelectBox>
              </div>
              <div className="u-mb-30">
                {payload.paymentMethod && payload.paymentMethod.toLowerCase() === 'ovo' && (
                  <TextField
                    type="tel"
                    placeholder="Mobile Phone Number"
                    description=""
                    value={payload.ovoPhoneNumber || ''}
                    onChange={({ target }) => this.setOvoPhoneNumber(target.value)}
                  />
                )}
              </div>
              <div className="u-mb-30">
                <PromoCode
                  type="text"
                  placeholder="Promo Code"
                  buttonText={payload.promoCode ? 'remove' : 'apply' }
                  value={this.state.promoCode || ''}
                  onChange={({ target }) => this.setState({ promoCode: target.value })}
                  onButtonClick={e => this.checkPromoCode(e, this.state.promoCode)}
                />
              </div>
              <div className="u-mb-30">
                <CheckBox useChildren onChange={() => this.toggleTos()}>
                  <Text>
                    I have read and agree to the
                    <Link to="/tos" target="_blank" rel="noopener noreferrer"> terms of service </Link>
                     and
                    <Link to="/privacy" target="_blank" rel="noopener noreferrer"> privacy policy </Link>
                  </Text>
                </CheckBox>
              </div>
              <div className="u-mb-30">
                <Separator />
              </div>
              <div className="u-mb-30">
                <Flexbox>
                  <FlexboxItem columnClass="col-xs-5 col-sm-6">
                    <Text bold size="medium">Total price</Text>
                  </FlexboxItem>
                  <FlexboxItem columnClass="col-xs-7 col-sm-6">
                    <div className="u-align-right">
                      { !!isCalculating && (
                        <Shimmer style={{ width: '120px', height: '26px' }} isActive={true} />
                      )}
                      { !isCalculating && (
                        <Text size="medium" bold>{idr(totalPrice.amount || totalPrice.totalAmount || 0)}</Text>
                      )}
                      <Text>includes fuel, toll, parking</Text>
                    </div>
                  </FlexboxItem>
                  {!isCalculating && totalPrice.details && !!totalPrice.details.length && (
                    <FlexboxItem columnClass="col-xs-12 col-sm-12">
                      <Separator className="u-mt-30" />
                      <div className="u-mt-30" style={{ textAlign: 'right' }}>
                        {totalPrice.details.map((dt, index) =>(
                          <div key={index}>{dt.text}</div>
                        ))}
                      </div>
                    </FlexboxItem>
                  )}
                  {!!isCalculating && (
                    <FlexboxItem columnClass="col-xs-12 col-sm-12">
                      <Separator className="u-mt-30" />
                      <div className="u-mt-30" style={{ textAlign: 'right' }}>
                        <Shimmer style={{ width: '70%', height: '18px' }} isActive={true} />
                        <Shimmer style={{ width: '50%', height: '18px', marginTop: '2px' }} isActive={true} />
                      </div>
                    </FlexboxItem>
                  )}
                </Flexbox>
              </div>
              <div className="u-mb-30">
                {(payload.paymentMethod || '').toLowerCase() === 'ovo' && (
                  <div style={{ textAlign: 'center', padding: '18px', backgroundColor: 'rgb(254, 0, 0, 0.1)', marginBottom: '8px', borderRadius: '8px' }}>
                    Make sure you open <strong>OVO</strong> app before pressing <strong>Book Now</strong>
                  </div>
                )}
                <Button
                  text="Book Now"
                  disabled={!isValidPayload || !agreement || isBooking || !payload.paymentMethod}
                  onClick={() => this.book({ ...payload, agreement })}
                />
              </div>
              <div className="u-align-center">
                <Link to={{
                  pathname: this.props.from,
                  state: {
                    back: true,
                  },
                }}>
                  <Button
                    isGhost
                    text="Back to Order Form"
                  />
                </Link>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ airportShuttleBooking, carRentalBooking, paymentMethod }) => ({ airportShuttleBooking, carRentalBooking, paymentMethod });

const {
  setOvoPhoneNumber,
  setPaymentMethod,
  getPaymentMethod,
  setPromoCode,
  clearBookingErrorMessage,
  bookAirportShuttle,
  getPromoByCode,
  clearPromoCodeMessage,
  calculatePrice: calculatePriceAirportShuttle,
} = { ...airportShuttleBookingAction, ...paymentMethodAction };

const { setState, bookCarRental, calculatePrice: calculatePriceCarRental, clearBookingErrorMessage: clearBookingErrorMessageCarRental } = carRentalBookingAction;

const mapDispatchToProps = dispatch => bindActionCreators({
  setState,
  setOvoPhoneNumber,
  setPaymentMethod,
  getPaymentMethod,
  setPromoCode,
  clearBookingErrorMessage,
  clearBookingErrorMessageCarRental,
  bookAirportShuttle,
  getPromoByCode,
  clearPromoCodeMessage,
  calculatePriceAirportShuttle,
  calculatePriceCarRental,
  bookCarRental,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentForm));
