import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Section from "../../molecules/Section/Section";
import Content from "../../molecules/Content/Content";
import PrivacyContent from "../../organism/Privacy/Privacy";

const Privacy = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Privacy Policy - Go Rental</title>
        <meta name="description" content="24/7 service Medan KNO airport transfer and car rental (with driver) at competitive prices. With safety as our priority, our experienced driver will make sure you reach your destination safely & on time. Book with us today, hassle-free later!" />
      </Helmet>
      <Content>
        <Section>
          <PrivacyContent />
        </Section>
      </Content>
    </Fragment>
  )
}

export default Privacy;
