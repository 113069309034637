import React, {Fragment} from 'react'
import Thumbnail from '../../atoms/Thumbnail/Thumbnail'
import Text from '../../atoms/Text/Text'

// airport shuttle
import OnTime from './img/airport/ontime.svg'
import Destination from './img/airport/2destinations.svg'
import Driver from './img/airport/driver.svg'
import WideArea from './img/airport/widearea.svg'

// car rental
import Clean from './img/car/clean.svg'
import Experience from './img/car/experience.svg'
import Refund from './img/car/refund.svg'
import Cashless from './img/car/cashless.svg'

import './style.scss'

const Tagline = props => {

  const {title, carRental} = props;

  return (
    <Fragment>
      {!carRental &&
      <div className="c-tagline">
        <div className="c-tagline__body">
          <div className="c-tagline__item col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <div className="c-tagline__img">
              <Thumbnail
                imageUrl={OnTime}
                alt={title}
              />
            </div>
            <div className="c-tagline__title">
              <Text bold>
                On Time
              </Text>
            </div>
          </div>
          <div className="c-tagline__item col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <div className="c-tagline__img">
              <Thumbnail
                imageUrl={Destination}
                alt={title}
              />
            </div>
            <div className="c-tagline__title">
              <Text bold>
                2 pick-up and drop-off Locations
              </Text>
            </div>
          </div>
          <div className="c-tagline__item col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <div className="c-tagline__img">
              <Thumbnail
                imageUrl={Driver}
                alt={title}
              />
            </div>
            <div className="c-tagline__title">
              <Text bold>
                Driver 24/7
              </Text>
            </div>
          </div>
          <div className="c-tagline__item col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <div className="c-tagline__img">
              <Thumbnail
                imageUrl={WideArea}
                alt={title}
              />
            </div>
            <div className="c-tagline__title">
              <Text bold>
                Greatest scope of area
              </Text>
            </div>
          </div>
        </div>
      </div>
      }
      {carRental &&
        <div className="c-tagline">
          <div className="c-tagline__body">
            <div className="c-tagline__item col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <div className="c-tagline__img">
                <Thumbnail
                  imageUrl={Clean}
                  alt={title}
                />
              </div>
              <div className="c-tagline__title">
                <Text bold>
                Clean, no odor
              </Text>
              </div>
            </div>
            <div className="c-tagline__item col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <div className="c-tagline__img">
                <Thumbnail
                  imageUrl={Experience}
                  alt={title}
                />
              </div>
              <div className="c-tagline__title">
                <Text bold>
                Experienced driver
              </Text>
              </div>
            </div>
            <div className="c-tagline__item col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <div className="c-tagline__img">
                <Thumbnail
                  imageUrl={Refund}
                  alt={title}
                />
              </div>
              <div className="c-tagline__title">
                <Text bold>
                Easy refund / reschedule
              </Text>
              </div>
            </div>
            <div className="c-tagline__item col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <div className="c-tagline__img">
                <Thumbnail
                  imageUrl={Cashless}
                  alt={title}
                />
              </div>
              <div className="c-tagline__title">
                <Text bold>
                Cashless payment
              </Text>
              </div>
            </div>
          </div>
        </div>
      }
    </Fragment>
  )
};

export default Tagline
