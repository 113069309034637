import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "./style.scss";

export default class Slideshow extends Component {
  render() {
    let settings = {
      className: "",
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      fade: false,
      arrows: false,
      lazyLoad: true,
      autoplay: true,
      autoplaySpeed: 5000,
      cssEase: "linear"
    };

    let slideshowClass = 'c-slideshow';

    return (
      <div className={slideshowClass}>
        <Slider {...settings}>
          { this.props.children }
        </Slider>
      </div>
    );
  }
}
