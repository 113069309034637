import React from 'react'
import Section from '../../molecules/Section/Section'
import Accordion from '../../atoms/Accordion/Accordion'
import CountdownDate from '../../atoms/CountdownDate/CountdownDate'
import CountdownTimer from '../../atoms/CountdownTimer/CountdownTimer'
import Button from "../../atoms/Button/Button";
import Separator from "../../atoms/Separator/Separator";
import TextField from "../../atoms/TextField/TextField";
import SelectBox from "../../atoms/SelectBox/SelectBox";
import CheckBox from "../../atoms/CheckBox/CheckBox";
import RadioButton from "../../atoms/RadioButton/RadioButton";
import RadioImage from "../../atoms/RadioImage/RadioImage";
import Counter from "../../atoms/Counter/Counter";
import Content from "../../molecules/Content/Content";
import Tagline from "../../molecules/Tagline/Tagline";
import PromoCode from "../../molecules/PromoCode/PromoCode";
import DateTime from "../../molecules/DateTime/DateTime";
import Outline from "../../molecules/Outline/Outline";

const StyleGuide = (props) => {

  const inlineStyle = {
    border: '1px solid #aaa',
    maxWidth: '960px',
    margin: 'auto',
    padding: '35px'
  };

  return (
    <Content background="white">
        <Section>
          <div style={inlineStyle}>
            <h4 className="u-mb-35">Preview Component</h4>

            <div className="u-mb-30">
              <TextField
                type="text"
                placeholder="Placeholder"
                description="Description here"
                value="Value"
              />
            </div>
          <div className="u-mb-30">
            <TextField
              type="email"
              placeholder="Placeholder"
              description="Description here"
              value="email@email.com"
            />
          </div>
            <div className="u-mb-30">
              <SelectBox />
            </div>
            <Separator className="u-mb-30" />
            <div className="u-mb-30">
              <CheckBox></CheckBox>
            </div>
            <div className="u-mb-30">
              <RadioButton
                id="formAirport"
                title="From Airport"
                description="Description here"
                name="airport"
              />
              <RadioButton
                id="toAirport"
                title="To Airport"
                description="Description here"
                name="airport"
              />
            </div>
            <div className="u-mb-30">
              <PromoCode />
            </div>
            <div className="u-mb-30">
              <Counter min="1" max="5" />
            </div>
            <div className="u-mb-30">
            <DateTime />
            </div>
            <div className="u-mb-30">
              <Outline>
                <RadioImage name="choose-car" id="standard-card"/>
                <RadioImage name="choose-car" id="premium-car"/>
                <RadioImage name="choose-car" id="luxury-card"/>
              </Outline>
            </div>
            <div className="u-mb-30">
              <Button
                text="Proceed to payment"
              />
            </div>

            {/* start preview */}
            <div className="u-mb-35">
              <h5 className="u-mb-30">Countdown Timer</h5>
              <CountdownTimer milliSecond={3600000} />
            </div>
            <div className="u-mb-35">
              <h5 className="u-mb-30">Countdown Date</h5>
              <CountdownDate
                targetDate="Apr 19, 2020"
                targetTime="18:30:00"
              />
            </div>
            <div className="u-mb-35">
              <h5 className="u-mb-15">Accordion</h5>
              <Accordion>
              <div label="When will I receive the pick-up details (e.g. vehicle license plate, driver’s name and contact details)? " isOpen>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Veniam doloremque quos ratione, nemo voluptatibus iste natus dolore dignissimos at maxime accusantium incidunt iusto dolores quam, distinctio possimus! Eligendi, at voluptates!
                </div>
                <div label="Alligator Sinensis">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Veniam doloremque quos ratione, nemo voluptatibus iste natus dolore dignissimos at maxime accusantium incidunt iusto dolores quam, distinctio possimus! Eligendi, at voluptates!
                </div>
              </Accordion>
            </div>
            <div className="u-mb-35">
              <Tagline />
            </div>
          </div>
        </Section>
    </Content>
  )
}

export default StyleGuide
