import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { orderAction } from '../../../store/actions';
import Preloader from '../../atoms/Preloader/Preloader';
import { Helmet } from 'react-helmet';
import Section from '../../molecules/Section/Section';
import Heading from '../../atoms/Heading/Heading';
import Text from '../../atoms/Text/Text';
import CountdownTimer from '../../atoms/CountdownTimer/CountdownTimer';
import Shimmer from '../../atoms/Shimmer/Shimmer';
import Separator from '../../atoms/Separator/Separator';

const { getOrder } = orderAction;

class OvoPay extends React.Component {
  constructor(props) {
    super(props);

    this.timer = null;
    this.state = {
      initialized: false,
      seconds: 30,
    };
  }
  componentDidMount() {
    const { match, history, getOrder } = this.props;
    const { idHash } = match.params;

    if (!idHash) {
      return history.push('/');
    }

    getOrder(idHash).then(({ status }) => {
      this.setState({ initialized: true });
      if (status === 'PENDING') {
        this.setState({
          seconds: this.state.seconds - 1,
        });
        this.periodicCheckOrder(idHash);
      }
    });
  }

  periodicCheckOrder(idHash) {
    this.timer = global.setInterval(() => {
      this.props.getOrder(idHash);
      this.setState({ seconds: this.state.seconds - 1 });
    }, 1000);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { seconds } = this.state;
    const { order } = this.props;
    const { data } = order;
    const { status } = data;

    if (status !== 'PENDING' || seconds === 0) {
      global.clearInterval(this.timer);
    }
  }

  render() {
    const separatorStyle = {
      maxWidth: '590px',
      margin: 'auto'
    };

    const{ initialized, seconds } = this.state;
    const { order } = this.props;
    const { fetching, data } = order;
    const isFetching = !initialized && fetching;
    const { status } = data;

    return (
      <Fragment>
        <Preloader isActive={isFetching} />
        <Helmet>
          <title>Ovo - Go Rental</title>
          <meta name="description" content="24/7 service Medan KNO airport transfer and car rental (with driver) at competitive prices. With safety as our priority, our experienced driver will make sure you reach your destination safely & on time. Book with us today, hassle-free later!" />
        </Helmet>
        <Section
          variant="o-container--tiny"
          className="u-mt-80 u-mb-80"
        >
          {status === 'PAID' && (
            <div className="u-align-center u-mb-30">
              <Heading tag="h3" className="u-mb-8">
                Order Paid
              </Heading>
              <Text size="medium">
                Thank You!
              </Text>
            </div>
          )}

          {status !== 'PAID' && (
            <div className="u-align-center u-mb-30">
              <Heading tag="h3" className="u-mb-8">
                {!isFetching && (
                  <span>Please proceed with payment to continue your booking</span>
                )}
              </Heading>
              <Text size="medium">
                {!isFetching && (
                  <span>Please make the payment within the given time:</span>
                )}
              </Text>
            </div>
          )}

          {status !== 'PAID' && (
            <div className="u-mb-40" style={{ textAlign: 'center' }}>
              {!isFetching && (
                <CountdownTimer milliSecond={seconds * 1000} />
              )}
              {isFetching && (
                <Shimmer style={{ width: '360px', height: '92px' }} isActive={true} />
              )}
            </div>
          )}
          <div className="u-mb-40">
            <Separator style={separatorStyle}/>
          </div>
          <div className="u-align-center u-mb-40">
            <div className="u-mb-8">
              <Text size="medium">
                Payment amount
              </Text>
            </div>
            {!isFetching && (
              <Heading tag="h2">
                {new Intl.NumberFormat('id-ID', {
                  style: 'currency',
                  currency: 'IDR',
                }).format(data.amount || 0)}
              </Heading>
            )}
            {isFetching && (
              <Shimmer style={{ width: '300px', height: '48px' }} isActive={true} />
            )}
          </div>
        </Section>
      </Fragment>
    )
  }
}

const mapStateToProps = ({ order }) => ({ order });

const mapDispatchToProps = dispatch => bindActionCreators({ getOrder }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OvoPay));
