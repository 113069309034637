import React from 'react'
import './style.scss'

const Heading = props => {
  let baseClass = 'c-heading'
  const {tag, children, className, style} = props;
  const Tag = tag

  return (
    <Tag className={baseClass+' '+ (className || '')} style={style}>
      {children}
    </Tag>
  )
};

export default Heading
