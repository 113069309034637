import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tab from './Tab';
import './style.scss'

class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.props.children[0].props.labelName,
      baseClass: 'c-tab',
      modifierClass: 'c-tab--'
    };
  }

  onClickTabItem = (tab) => {
    this.setState({ activeTab: tab });
  }

  render() {
    const {
      onClickTabItem,
      props: {
        children,
        color
      },
      state: {
        activeTab,
      }
    } = this;

    return (
      <div className={
        this.state.baseClass + ' ' + this.state.modifierClass + (color || '')
        }>
        <ol className="c-tab__trigger">
          {children.map((child) => {
            const { labelName } = child.props;

            return (
              <Tab
                activeTab={activeTab}
                key={labelName}
                labelName={labelName}
                onClick={onClickTabItem}
              />
            );
          })}
        </ol>
        <div className="c-tab__body">
          {children.map((child) => {
            if (child.props.labelName !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

export default Tabs;
