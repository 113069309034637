import React from 'react'
import './style.scss'

const Content = (props) => {

  let baseClass = 'c-content';
  const { children, isOverlapping, background} = props;

  if (isOverlapping) {
    baseClass += ' c-content--overlapping'
  }

  if (background === 'grey') {
    baseClass += ' c-content--grey'
  }

  return (
    <div className={baseClass}>
      <div className="c-content__body">
        {children}
      </div>
    </div>
  )
}

export default Content
