import React from 'react';
import TimePicker from 'rc-time-picker';
import DatePicker from 'react-datepicker'
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import 'rc-time-picker/assets/index.css';
import './style.scss';

export const DateTime = ({ date, onChange, minDate }) => {
  return (
    <div className="c-datetime u-desktop-only">
      <div className="c-datetime__head">
        <div className="c-datetime__label">
          Pickup Date
        </div>
        <div className="c-datetime__label">
          Pickup Time
        </div>
      </div>
      <div className="c-datetime__wrapper">
        <div className="c-datetime__body">
          <div className="c-datetime__item">
            <DatePicker
              selected={date}
              minDate={minDate || moment().toDate()}
              onChange={onChange}
            />
          </div>
          <div className="c-datetime__item">
            <TimePicker
              showSecond={false}
              value={moment(date)}
              minuteStep={5}
              onChange={value => onChange(value.toDate())}
            />
          </div>
        </div>
      </div>
    </div>
  )
};

export default DateTime;
