import React, {Fragment} from 'react'
import Content from '../molecules/Content/Content'
import Hero from "../organism/Hero/Hero"
import HowItWorks from '../organism/HowItWorks/HowItWorks'
import Payment from '../organism/Payment/Payment'
import Benefit from '../organism/Benefit/Benefit'
import Client from '../organism/Client/Client'
import Story from '../organism/Story/Story'

const Homepage = () => {
  return (
    <Fragment>
      <Hero hasSlideShow />
      <Content>

        <HowItWorks />

        <Payment />

        <Benefit />

        <Client />

        <Story />

      </Content>
    </Fragment>
  )
}

export default Homepage;
