import React from 'react'
import PreloadImg from "./img/preloader.png"
import './style.scss'

const Modal = props => {

  let modalClass = 'c-modal'

  const { children, className, handleClose, forVideo, sizeSmall, hideCloseButton } = props;

  if (forVideo) {
    modalClass += ' c-modal--for-video';
  }

  if (sizeSmall) {
    modalClass += ' c-modal--small';
  }

  return (
    <div
      className={modalClass + ' ' + (className || '')}
      onClick={handleClose}
    >
      <div
        className="c-modal__content"
        onClick={(e) => e.stopPropagation() }
      >
        {!hideCloseButton &&
          <div className="c-modal__head">
            <div className="c-modal__close" onClick={handleClose}>
              Close
            </div>
          </div>
        }
        <div className="c-modal__body">
          {forVideo &&
            <img className="c-modal__preload" src={PreloadImg} alt="Youtube" />
          }
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal
