import React from 'react';
import Section from '../../molecules/Section/Section';
import Text from '../../atoms/Text/Text';
import propTypes from 'prop-types';
import Mandiri from './Mandiri';
import BCA from './BCA';
import BNI from './BNI';
import BRI from './BRI';
import Permata from './Permata';

const PaymentHowToPay = ({ bank }) => (
  <Section
    variant="o-container--fluid"
    style={{ background: '#FAF7F7' }}
    className="u-pt-80 u-pb-80"
  >
    <Section variant="o-container--tiny">
      <div className="u-mb-25">
        <Text size="medium">
          How to pay
        </Text>
      </div>
      {bank.toLowerCase() === 'mandiri' && <Mandiri />}
      {bank.toLowerCase() === 'bca' && <BCA />}
      {bank.toLowerCase() === 'bni' && <BNI />}
      {bank.toLowerCase() === 'bri' && <BRI />}
      {bank.toLowerCase() === 'permata' && <Permata />}
    </Section>
  </Section>
);

PaymentHowToPay.propTypes = {
  bank: propTypes.string.isRequired,
};

export default PaymentHowToPay;
