import React from 'react'
import PreloadImg from "./img/preloader.png"
import './style.scss'

const Preloader = ({ title, isActive, onClick, displayImage = true }) => {
  let preloaderClass = 'c-preloader';

  if (isActive) {
    preloaderClass += ' is-active'
  }

  return (
    <div className={preloaderClass} onClick={onClick}>
      <div className="c-preloader__body">
        { !!displayImage && (
          <div className="c-preloader__loader">
            <img src={PreloadImg} alt="Go Rental" />
          </div>
        )}
        <div className="c-preloader__text">
          {title}
        </div>
      </div>
    </div>
  )
};

Preloader.defaultProps = {
  title: 'Please wait...'
};

export default Preloader;
