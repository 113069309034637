import React from 'react'
import Text from "../../atoms/Text/Text"
import Heading from "../../atoms/Heading/Heading"
import Card from "../../atoms/Card/Card"
import Thumbnail from "../../atoms/Thumbnail/Thumbnail";
import Section from "../../molecules/Section/Section"
import Mobility from "./img/mobility.png";
import Rent from "./img/rent.png";
import "./style.scss"

const Story = () => {

  const inlineStyle = {
    backgroundImage: `url(${Mobility})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover'
  }

  return (
    <Section
      className="g-story"
    >
      <Card
        colorGrey
        isBleed
      >
        <div className="g-story__body">
          <div className="g-story__grid-left" style={inlineStyle}></div>
          <div className="g-story__grid-right">
            <Heading tag="h2" className="u-mb-30">
              The Go Rental Story:
              <div>From the Beginning–Mobility Solution</div>
            </Heading>
            <Text size="medium">
              Our story began in 2007. We started off from corporate car rental to Medan’s industry leaders such as: Bank Sumut, Pertamina, PTPN, many more. Through the years the company gained a well-established reputation for its commitment to offer quality, timely and unparalleled customer service. Furthermore, to better serve our customers’ needs, we branch out to key cities in Sumatra & Java Island.
            </Text>
            <Text size="medium">
              In the meantime, we continue to grow, adapt, and change as necessary to meet your requirements. Since our clientele is diversified, we expand our range of services to airport transfer & retail car rental. We invite you to enjoy our exceptional services that have years of experience.
            </Text>
            <div className="g-story__ornament">
              <Thumbnail
                imageUrl={Rent}
                alt="Go Rental"
              />
            </div>
          </div>
        </div>
      </Card>
    </Section>
    )
}

export default Story
