import React, { useState, useEffect } from 'react'
import Thumbnail from '../../atoms/Thumbnail/Thumbnail'
import Card from '../../atoms/Card/Card'
import Button from "../../atoms/Button/Button";
import Heading from "../../atoms/Heading/Heading";
import Text from "../../atoms/Text/Text";
import Flexbox from '../../molecules/Flexbox/Flexbox'
import Modal from '../../molecules/Modal/Modal'
import FlexboxItem from '../../atoms/FlexboxItem/FlexboxItem'
import SlideShow from "../../atoms/Slideshow/Slideshow";
import AirportBackground from './img/airport-shuttle-bg.jpg'
import AirportBackgroundSmall from './img/airport-shuttle-small.jpg'
import CarRentalBackground from './img/car-rental-bg.jpg'
import CarRentalBackgroundSmall from './img/car-rental-small.jpg'
import ButtonIcon from './img/icon-arrow-right.svg';
import { Route, useHistory } from 'react-router-dom'
import MediaQuery from 'react-responsive'

// slideshow image
import SlidePromo from './img/slideshow/slide-promo-car.jpg';
import SlidePromoMobile from './img/slideshow/slide-promo-car-mobile.jpg';
import SlideTesti from './img/slideshow/slide-promo-airport.jpg';
import SlideTestiMobile from './img/slideshow/slide-promo-airport-mobile.jpg';
import SlideVideo from './img/slideshow/slide-video.jpg';
import SlideVideoMobile from './img/slideshow/slide-video-mobile.jpg';

import './style.scss'

const Hero = ({ hasSlideShow, headerHasMenu, carRental }) => {

  let baseClass = 'c-hero';

  const history = useHistory();

  const gotoPage = (pathName) => {
    history.push(pathName);
  };

  const [
    modalActive,
    setModalActive
  ] = useState('');

  const toggleModal = () => {
    setModalActive(
      modalActive === '' ? 'is-active' : '',
    )
  };

  useEffect(() => {
    document.body.className = modalActive;
    return () => {
      document.body.className = modalActive;
    }
  });

  return (
    <div
      className={baseClass}
      style={{
        marginTop: headerHasMenu ? '0' : '-65px'
      }}
    >
      <div className="c-hero__body">
        {hasSlideShow &&
          <MediaQuery minDeviceWidth={960}>
            <SlideShow>
              <div>
                <Thumbnail
                  imageUrl={SlidePromo}
                  alt="Gorental"
                />
              </div>
              <div>
                <Thumbnail
                  imageUrl={SlideTesti}
                  alt="Gorental"
                />
              </div>
              <div onClick={toggleModal}>
                <Thumbnail
                  imageUrl={SlideVideo}
                  alt="Gorental"
                />
              </div>
            </SlideShow>
          </MediaQuery>
        }
        {hasSlideShow &&
          <MediaQuery maxDeviceWidth={959}>
            <SlideShow>
              <div>
                <Thumbnail
                  imageUrl={SlidePromoMobile}
                  alt="Gorental"
                />
              </div>
              <div>
                <Thumbnail
                  imageUrl={SlideTestiMobile}
                  alt="Gorental"
                />
              </div>
              <div onClick={toggleModal}>
                <Thumbnail
                  imageUrl={SlideVideoMobile}
                  alt="Gorental"
                />
              </div>
            </SlideShow>
          </MediaQuery>
        }
        <Route exact path="/airport-shuttle" >
          {!hasSlideShow &&
            <MediaQuery minDeviceWidth={960}>
              <Thumbnail
                imageUrl={AirportBackground}
                alt="Gorental"
            />
            </MediaQuery>
          }
          {!hasSlideShow &&
            <MediaQuery maxDeviceWidth={959}>
              <Thumbnail
                imageUrl={AirportBackgroundSmall}
                alt="Gorental"
              />
            </MediaQuery>
          }
        </Route>
        <Route exact path="/payment-method" >
          {!hasSlideShow &&
            <Thumbnail
              imageUrl={carRental ? CarRentalBackground : AirportBackground}
              alt="Gorental"
            />
          }
        </Route>
        <Route exact path="/modify-order/:idHash" >
          {!hasSlideShow &&
            <MediaQuery minDeviceWidth={960}>
              <Thumbnail
                imageUrl={carRental ? CarRentalBackground : AirportBackground}
                alt="Gorental"
              />
            </MediaQuery>
          }
          {!hasSlideShow &&
            <MediaQuery maxDeviceWidth={959}>
              <Thumbnail
                imageUrl={carRental ? CarRentalBackground : AirportBackground}
                alt="Gorental"
              />
            </MediaQuery>
          }
        </Route>
        <Route exact path="/manage-rent" >
          {!hasSlideShow &&
            <MediaQuery minDeviceWidth={960}>
              <Thumbnail
                imageUrl={CarRentalBackground}
                alt="Gorental"
              />
            </MediaQuery>
          }
          {!hasSlideShow &&
            <MediaQuery maxDeviceWidth={959}>
              <Thumbnail
                imageUrl={CarRentalBackgroundSmall}
                alt="Gorental"
              />
            </MediaQuery>
          }
        </Route>
        <Route exact path="/car-rental" >
          {!hasSlideShow &&
            <MediaQuery minDeviceWidth={960}>
              <Thumbnail
                imageUrl={CarRentalBackground}
                alt="Gorental"
              />
            </MediaQuery>
          }
          {!hasSlideShow &&
            <MediaQuery maxDeviceWidth={959}>
              <Thumbnail
                imageUrl={CarRentalBackgroundSmall}
                alt="Gorental"
              />
            </MediaQuery>
          }
        </Route>
      </div>
      {hasSlideShow &&
      <Card
        useWrapper
        colorWhite
      >
        <Heading tag="h2">
          Make Your Trip Less of a Hassle
        </Heading>
        <Text
          descHero
          size="medium"
        >
          Book your trip from-and-to Medan KNO International Airport or rent a car in Medan city at your fingertips. With comfort, safety, and punctuality as our top priority. Don’t wait! Book with Go Rental today, hassle-free later.
        </Text>
        <Flexbox className="o-grid--bleed">
          <FlexboxItem
            columnClass="col-xs-12 col-sm-6"
            className="button-wrapper"
          >
            <Button
              useIcon={ButtonIcon}
              text="Book Airport Transfer"
              isGhost
              hasBorder
              onClick={() => gotoPage('/airport-shuttle')}
            />
          </FlexboxItem>
          <FlexboxItem
            columnClass="col-xs-12 col-sm-6"
            className="button-wrapper"
          >
            <Button
              useIcon={ButtonIcon}
              text="Rent a Car"
              isGhost
              hasBorder
              onClick={() => gotoPage('/car-rental')}
            />
          </FlexboxItem>
        </Flexbox>
      </Card>
      }

      <Modal
        className={`${modalActive}`}
        handleClose={toggleModal}
        forVideo
      >
        {modalActive &&
        <div className="iframe-container">
          <iframe
            title="Youtube"
            src="https://www.youtube.com/embed/An5wsTp3Odc"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen>
          </iframe>
        </div>
        }
      </Modal>
    </div>
  )
};

export default Hero
