import React from 'react'
import IconChevronRight from './img/icon-chevron-right.svg'
import IconEye from './img/icon-eye.svg'
import IconTriangle from './img/icon-triangle.svg'
import './style.scss'

const Button = props => {
  let calloutClass = 'c-callout';
  const { children, onClick, isInfo} = props;

  if (isInfo) {
    calloutClass += ' c-callout--info'
  }

  return (
    <div className={calloutClass} onClick={onClick}>
      <div className="c-callout__body">
        {isInfo &&
          <div className="c-callout__icon-left">
            <img src={IconTriangle} alt="Go Rental" />
          </div>
        }
        <div className="c-callout__text">
          {children}
        </div>
        <div className="c-callout__icon">
          {isInfo &&
            <img src={IconEye} alt="Go Rental" />
          }
          {!isInfo &&
            <img src={IconChevronRight} alt="Go Rental" />
          }
        </div>
      </div>
    </div>
  )
};

export default Button
