import React from 'react'
import Section from "../../molecules/Section/Section";
import Heading from "../../atoms/Heading/Heading";
import Flexbox from "../../molecules/Flexbox/Flexbox";
import FlexboxItem from "../../atoms/FlexboxItem/FlexboxItem";
import Thumbnail from "../../atoms/Thumbnail/Thumbnail";
import Text from "../../atoms/Text/Text";
import Cancelation from "./img/freecancel.png";
import Manage from "./img/manage.png";
import DropLocation from "./img/2droplocation.png";
import Safe from "./img/safe.png";
import './style.scss'

const Benefit = () => {

  return (
    <Section className="g-benefit">

      <Heading tag="h2" className="u-align-center u-mb-25">
        Benefits of Using GoRental
      </Heading>

      <Section>
        <Flexbox position="o-grid--center">
          <FlexboxItem className="g-benefit__item" columnClass="col-xs-6 col-sm-3">
            <div className="g-benefit__thumb">
              <Thumbnail
                background="grey"
                imageUrl={Cancelation}
                alt="GoRental"
              />
            </div>
            <div className="u-align-center">
              <Text size="medium">
              Easy Reschedule and Cancellation
              </Text>
            </div>
          </FlexboxItem>
          <FlexboxItem className="g-benefit__item" columnClass="col-xs-6 col-sm-3">
            <div className="g-benefit__thumb">
              <Thumbnail
                background="grey"
                imageUrl={Manage}
                alt="GoRental"
              />
            </div>
            <div className="u-align-center">
              <Text size="medium">
                Manage your booking online
              </Text>
            </div>
          </FlexboxItem>
          <FlexboxItem className="g-benefit__item" columnClass="col-xs-6 col-sm-3">
            <div className="g-benefit__thumb">
              <Thumbnail
                background="grey"
                imageUrl={DropLocation}
                alt="GoRental"
              />
            </div>
            <div className="u-align-center">
              <Text size="medium">
                2 drop off locations
              </Text>
            </div>
          </FlexboxItem>
          <FlexboxItem className="g-benefit__item" columnClass="col-xs-6 col-sm-3">
            <div className="g-benefit__thumb">
              <Thumbnail
                background="grey"
                imageUrl={Safe}
                alt="GoRental"
              />
            </div>
            <div className="u-align-center">
              <Text size="medium">
                Safe & secure trip
              </Text>
            </div>
          </FlexboxItem>
        </Flexbox>
      </Section>
    </Section>
  )

}

export default Benefit
