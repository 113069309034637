import React, { Component, Fragment } from 'react'
import Text from '../../atoms/Text/Text';
import Flexbox from '../../molecules/Flexbox/Flexbox';
import FlexboxItem from '../../atoms/FlexboxItem/FlexboxItem';
import Button from '../../atoms/Button/Button';
import Separator from '../../atoms/Separator/Separator';
import TextField from '../../atoms/TextField/TextField';
import SelectBox from '../../atoms/SelectBox/SelectBox';
import CheckBox from '../../atoms/CheckBox/CheckBox';
import RadioButton from '../../atoms/RadioButton/RadioButton';
import RadioImage from '../../atoms/RadioImage/RadioImage';
import Counter from '../../atoms/Counter/Counter';
import DateTime from '../../molecules/DateTime/DateTime';
import DateTimeNative from '../../molecules/DateTimeNative/DateTimeNative';
import Outline from '../../molecules/Outline/Outline';
import Shimmer from '../../atoms/Shimmer/Shimmer';
import Preloader from '../../atoms/Preloader/Preloader';
import StandardCar from './img/standard.png'
import PremiumCar from './img/premium.png'
import LuxuryCar from './img/luxury.png'
import './style.scss';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../store/actions';
import isEqual from 'lodash.isequal';
import { Redirect } from 'react-router';
import { withRouter } from 'react-router';
import Formsy from 'formsy-react';
import FormsyTextField from '../../atoms/FormsyTextField';
import { idr, isWorkingHour } from 'api/helpers/common';
import moment from 'moment';

const {
  setFullName,
  setEmail,
  setPhoneNumber,
  setPickupType,
  setAirportId,
  setFlightNumber,
  setPickupDate,
  setPickupTime,
  setNumberOfPassenger,
  setNumberOfLuggage,
  setNumberOfCar,
  setRoundTrip,
  setAddress,
  setAreaId,
  setAdditionalAddress,
  setAdditionalAreaId,
  setReturnDate,
  setReturnTime,
  setReturnFlightNumber,
  setProductId,
  setPromoCode,
  setPaymentMethod,
  calculatePrice,
  clearBookingErrorMessage,
} = actions.airportShuttleBookingAction;

class AirportForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      closeDropLocation: true,
      closeReturnShuttle: true,
    };
  }

  componentDidMount() {
    const { payload } = this.props.airportShuttleBooking;
    const {
      additionalAreaID,
      additionalAddress,
      returnDatetime,
      returnFlightNumber,
    } = payload;

    if (additionalAreaID && additionalAddress) {
      this.toggleDropLocation();
    }

    if (returnDatetime && returnFlightNumber) {
      this.toggleReturnShuttle();
    }
  }

  toggleDropLocation = () => {
    this.setState({
      closeDropLocation: !this.state.closeDropLocation,
    }, () => {
      if (this.state.closeDropLocation) {
        this.props.setAdditionalAreaId(null);
        this.props.setAdditionalAddress(null);
      } else {
        this.props.setAdditionalAreaId(this.props.airportShuttleBooking.payload.areaID)
      }
    });
  };

  toggleReturnShuttle = () => {
    this.setState({
      closeReturnShuttle: !this.state.closeReturnShuttle,
    }, () => {
      this.props.setRoundTrip(!this.state.closeReturnShuttle);
    });
  };

  setArea(areaId) {
    const { closeDropLocation } = this.state;
    this.props.setAreaId(areaId);
    if (!closeDropLocation) {
      this.props.setAdditionalAreaId(areaId);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const pricePayload = ({
      toAirport,
      airportID,
      pickupDatetime,
      numberOfPassanger,
      numberOfLuggage,
      numberOfCars,
      roundtrip,
      areaID,
      additionalAreaID,
      returnDatetime,
      productID,
    }) => ({
      toAirport,
      airportID,
      flightNumber: null,
      pickupDatetime,
      numberOfPassanger,
      numberOfLuggage,
      numberOfCars,
      roundtrip,
      address: null,
      areaID,
      additionalAddress: null,
      additionalAreaID,
      returnDatetime,
      productID,
      promoCode: null,
      paymentMethod: null,
    });

    const prevPayload = pricePayload(prevProps.airportShuttleBooking.payload);
    const payload = pricePayload(this.props.airportShuttleBooking.payload);
    const payloadChanged = !isEqual(prevPayload, payload);
    if (payloadChanged) {
      this.props.calculatePrice(payload);
    }
  }

  render() {
    const { closeDropLocation, closeReturnShuttle } = this.state;
    const {
      area,
      areaMap,
      products,
      airportShuttleBooking,
      setFullName,
      setEmail,
      setPhoneNumber,
      setPickupType,
      setFlightNumber,
      setPickupDate,
      setNumberOfCar,
      setAddress,
      setAdditionalAddress,
      setAdditionalAreaId,
      setReturnDate,
      setReturnFlightNumber,
      setProductId,
      clearBookingErrorMessage,
    } = this.props;
    const {
      payload,
      pickupDate,
      returnDate,
      isCalculating,
      totalPrice,
      isBooking,
      bookingData,
      bookingErrorMessage,
      isValidPayload,
    } = airportShuttleBooking;
    const productImages = [StandardCar, PremiumCar, LuxuryCar];
    const requiredFormat = /^08\d{7,11}$/;
    const isPhoneNumberValid = requiredFormat.test(payload.phoneNumber);

    return (
      <div className="g-airportform">
        { Object.keys(bookingData).length  > 0 && <Redirect to="/transfer" /> }
        <Preloader isActive={!!isBooking} />
        <Preloader
          isActive={!!bookingErrorMessage}
          displayImage={false}
          title={bookingErrorMessage}
          onClick={clearBookingErrorMessage}
        />
        <Formsy>
          <div className="g-airportform__body">
          <div className="u-mb-30">
            <Text size="medium">Fill up this form correctly to book airport transfer</Text>
          </div>
          <div className="u-mb-30">
            <TextField
              name="name"
              type="text"
              placeholder="Name"
              description=""
              value={payload.fullname}
              onChange={({ target }) => setFullName(target.value)}
              required
            />
          </div>
          <div className="u-mb-30">
            <FormsyTextField
              name="email"
              type="email"
              placeholder="Email"
              description=""
              value={payload.email}
              onChange={({ target }) => setEmail(target.value)}
              validations="isEmail"
              validationError="Input valid email"
              required
            />
          </div>
          <div className="u-mb-30">
            <FormsyTextField
              type="number"
              name="mobilePhoneNumber"
              placeholder="Mobile Phone Number"
              description=""
              value={payload.phoneNumber}
              onChange={({ target }) => setPhoneNumber(target.value)}
              validations={{
                matchRegexp: /^08\d{7,11}$/
              }}
              validationErrors={{
                matchRegexp: 'Mohon mengisi nomor telepon dengan format 08xxx'
              }}
              required
            />
          </div>
          <div className="u-mb-30">
            <Separator />
          </div>
          <div className="u-mb-30">
            <Flexbox>
              <FlexboxItem>
                <RadioButton
                  id="formAirport"
                  title="From Airport"
                  name="airport"
                  description=""
                  checked={!payload.toAirport}
                  onChange={() => setPickupType(false)}
                />
              </FlexboxItem>
              <FlexboxItem>
                <RadioButton
                  id="toAirport"
                  title="To Airport"
                  name="airport"
                  description=""
                  checked={payload.toAirport}
                  onChange={() => setPickupType(true)}
                />
              </FlexboxItem>
            </Flexbox>
          </div>
          <div className="u-mb-30">
            <SelectBox
              value={payload.areaID || '0'}
              description={`District surcharge: ${idr(payload.areaID ? areaMap[payload.areaID].additionalPrice : 0)}`}
              onChange={({ target }) => this.setArea(Number(target.value))}
            >
              <option value="0" disabled>Choose District</option>
              {area.map(({ id, name }) => (
                <option key={id} value={id}>{name}</option>
              ))}
            </SelectBox>
          </div>
          <div className="u-mb-30">
            <TextField
              type="text"
              name="address"
              placeholder="Address"
              description=""
              value={payload.address}
              onChange={({ target }) => setAddress(target.value)}
              required
            />
          </div>
          <div className="u-mb-30">
            <DateTime
              date={pickupDate}
              onChange={date => setPickupDate(date)}
            />
            <DateTimeNative
              date={pickupDate}
              setDate={date => setPickupDate(date)}
            />
            {!isWorkingHour(pickupDate) && (
              <div className="c-textfield__desc">
                <span>Outside hours 08:00-19:59, surcharge: {idr(30000)}</span>
              </div>
            )}
          </div>
          <div className="u-mb-30">
            <TextField
              type="text"
              name="flightNumber"
              placeholder="Flight Number"
              description=""
              value={payload.flightNumber}
              onChange={({ target }) => setFlightNumber(target.value)}
              required
            />
          </div>
          <div className="u-mb-30">
            <Separator />
          </div>
          <div className="u-mb-30">
            <CheckBox
              title="I need 2 pick-up/drop-off locations"
              description="Surcharge Rp. 25.000"
              name="additional-drop"
              checked={!closeDropLocation}
              onChange={() => this.toggleDropLocation()}
            />
          </div>

          {!closeDropLocation && (
            <Fragment>
              <div className="u-mb-30">
                <SelectBox
                  value={payload.additionalAreaID || '0'}
                  description={`Second address must within the same district`}
                  onChange={({ target }) => setAdditionalAreaId(Number(target.value))}
                  disabled={true}
                >
                  <option value="0" disabled>Choose District</option>
                  {area.map(({ id, name }) => (
                    <option key={id} value={id}>{name}</option>
                  ))}
                </SelectBox>
              </div>
              <div className="u-mb-30">
                <TextField
                  type="text"
                  name="additionalAddress"
                  placeholder="Address"
                  description="Maximum distance is 5 km from Address 1"
                  markError={!payload.additionalAddress}
                  value={payload.additionalAddress || ''}
                  onChange={({ target }) => setAdditionalAddress(target.value)}
                  required
                />
              </div>
            </Fragment>
          )}

          <div className="u-mb-30">
            <CheckBox
              title="I want to book return trip"
              description="Drop-off and pick-up address must be the same"
              name="return-shuttle"
              checked={!closeReturnShuttle}
              onChange={() => this.toggleReturnShuttle()}
            />
          </div>
          {!closeReturnShuttle &&
            <Fragment>
              <div className="u-mb-30">
                <DateTime
                  date={returnDate}
                  minDate={moment(pickupDate).toDate()}
                  onChange={date => setReturnDate(date)}
                />
                <DateTimeNative
                  date={returnDate}
                  minDate={moment(pickupDate).toDate()}
                  setDate={date => setReturnDate(date)}
                />
                {!isWorkingHour(returnDate) && (
                  <div className="c-textfield__desc">
                    <span>Outside hours 08:00-19:59, surcharge: {idr(30000)}</span>
                  </div>
                )}
              </div>
              <div className="u-mb-30">
                <TextField
                  type="text"
                  name="returnFlightNumber"
                  placeholder="Return Flight Number"
                  markError={!payload.returnFlightNumber}
                  description=""
                  value={payload.returnFlightNumber || ''}
                  onChange={({ target }) => setReturnFlightNumber(target.value)}
                  required
                />
              </div>
            </Fragment>
          }
          <div className="u-mb-30">
            <Separator />
          </div>
          <div className="u-mb-30">
            <div className="u-mb-8">
              <Outline>
                {products.slice(0,3).map(({ id, productType, airportShuttlePrice }, index) => (
                  <RadioImage
                    key={id}
                    name="choose-car"
                    id={`${id}`}
                    value={id}
                    checked={id === payload.productID}
                    onChange={({ target }) => setProductId(Number(target.value))}
                    imageUrl={productImages[index]}
                    title={`${productType}`}
                    description={idr(airportShuttlePrice)}
                  />
                ))}
              </Outline>
            </div>
              <Text grey>
                <strong>Capacity</strong>: Max 4 passengers and 4 baggages/ car
              </Text>
          </div>
          <div className="u-mb-30">
            <Flexbox className="middle-xs middle-sm">
              <FlexboxItem columnClass="col-xs-6 col-sm-6">
                <Text size="medium">Number of Car(s)</Text>
              </FlexboxItem>
              <FlexboxItem columnClass="col-xs-6 col-sm-6" className="end-xs end-sm">
                <Counter
                  min="1"
                  max="5"
                  total={payload.numberOfCars}
                  onChange={value => setNumberOfCar(value)}
                />
              </FlexboxItem>
            </Flexbox>
          </div>
          <div className="u-mb-30">
            <Separator />
          </div>
          <div className="u-mb-30">
            <Flexbox>
              <FlexboxItem columnClass="col-xs-5 col-sm-6">
                <Text bold size="medium">Total price</Text>
              </FlexboxItem>
              <FlexboxItem columnClass="col-xs-7 col-sm-6">
                <div className="u-align-right">
                  { !!isCalculating && (
                    <Shimmer style={{ width: '120px', height: '26px' }} isActive={true} />
                  )}
                  { !isCalculating && (
                    <Text size="medium" bold>{idr(totalPrice.amount)}</Text>
                  )}
                  <Text>excludes toll & parking</Text>
                </div>
              </FlexboxItem>
              {!isCalculating && totalPrice.details && !!totalPrice.details.length && (
                <FlexboxItem columnClass="col-xs-12 col-sm-12">
                  <Separator className="u-mt-30" />
                  <div className="u-mt-30" style={{ textAlign: 'right' }}>
                    {totalPrice.details.map((dt, index) =>(
                      <div key={index}>{dt.text}</div>
                    ))}
                  </div>
                </FlexboxItem>
              )}
              {!!isCalculating && (
                <FlexboxItem columnClass="col-xs-12 col-sm-12">
                  <Separator className="u-mt-30" />
                  <div className="u-mt-30" style={{ textAlign: 'right' }}>
                    <Shimmer style={{ width: '70%', height: '18px' }} isActive={true} />
                    <Shimmer style={{ width: '50%', height: '18px', marginTop: '2px' }} isActive={true} />
                  </div>
                </FlexboxItem>
              )}
            </Flexbox>
          </div>
          <div className="u-mb-30">
            {!isCalculating && isValidPayload && isPhoneNumberValid && (
              <Button
                text="Proceed to payment"
                onClick={() => this.props.history.push({
                  pathname: '/payment-method',
                  from: 'airport-shuttle',
                })}
              />
            )}
            {(!!isCalculating || !isValidPayload || !isPhoneNumberValid) && (
              <Button
                text={isCalculating ? 'Loading, please wait...' : 'Proceed to payment' }
                disabled={true}
              />
            )}
          </div>
        </div>
        </Formsy>
      </div>
    )
  }
}

const mapStateToProps = ({ area, products, airportShuttleBooking }) => ({
  area: area.data,
  areaMap: area.dataMap,
  products: products.data,
  airportShuttleBooking,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setFullName,
  setEmail,
  setPhoneNumber,
  setPickupType,
  setAirportId,
  setFlightNumber,
  setPickupDate,
  setPickupTime,
  setNumberOfPassenger,
  setNumberOfLuggage,
  setNumberOfCar,
  setRoundTrip,
  setAddress,
  setAreaId,
  setAdditionalAddress,
  setAdditionalAreaId,
  setReturnDate,
  setReturnTime,
  setReturnFlightNumber,
  setProductId,
  setPromoCode,
  setPaymentMethod,
  calculatePrice,
  clearBookingErrorMessage,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AirportForm));
