import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Hero from '../../organism/Hero/Hero';
import Card from '../../atoms/Card/Card';
import Tabs from '../../atoms/Tabs/Tabs'
import Heading from '../../atoms/Heading/Heading';
import Content from '../../molecules/Content/Content';
import Section from '../../molecules/Section/Section';
import Flexbox from '../../molecules/Flexbox/Flexbox';
import FlexboxItem from '../../atoms/FlexboxItem/FlexboxItem';
import CarRentalForm from '../../organism/CarRentalForm';
import Faq from '../../organism/Faq/Faq';
import MediaQuery from 'react-responsive'
import { Helmet } from 'react-helmet';

import * as actions from '../../../store/actions';
import { bindActionCreators } from 'redux';

class CarRental extends React.Component {
  componentDidMount() {
    const {
      getDistrict,
      getProducts,
      setState,
      resetState,
      location,
    } = this.props;

    if (!(location.state && location.state.back)) {
      resetState();
    }

    getDistrict();
    getProducts().then(data => setState(['productID', data[0].id]));
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Car Rental - Go Rental</title>
          <meta name="description" content="The best car rental in Medan | Book as short as 5 or 8 hours | Any occasion | Safe & comfortable ride| Pickup Truck, Standard, Premium, Luxury!"/>
        </Helmet>
        <Hero/>
        <Content
          background="grey"
          isOverlapping
        >
          <Section>
            {/* DESKTOP VERSION */}
            <MediaQuery minDeviceWidth={960}>
              <Flexbox>
                <FlexboxItem columnClass="col-sm-6">
                  <Card colorWhite>
                    <CarRentalForm/>
                  </Card>
                </FlexboxItem>
                <FlexboxItem columnClass="col-sm-6">
                  <Faq/>
                </FlexboxItem>
              </Flexbox>
            </MediaQuery>
            {/* END DESKTOP VERSION */}

            {/* MOBILE VERSION */}
            <MediaQuery maxDeviceWidth={959}>
              <div className="u-mb-20 u-align-center">
                <Heading tag="h2" className="c-heading--white">
                  Rent a Car
                </Heading>
              </div>
              <Card colorWhite isBleed>
                <Tabs color="white">
                  <div labelName="Booking">
                    <CarRentalForm/>
                  </div>
                  <div labelName="FAQ">
                    <Faq/>
                  </div>
                </Tabs>
              </Card>
            </MediaQuery>
            {/* END MOBILE VERSION */}

          </Section>
        </Content>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ area, products, carRentalBooking }) => ({
  area: area.data,
  products: products.data,
  payload: carRentalBooking.payload,
});

const { getDistrict } = actions.districtAction;
const { getProducts } = actions.productsAction;
const { setState, resetState } = actions.carRentalBookingAction;

const mapDispatchToProps = dispatch => bindActionCreators({
  getProducts,
  getDistrict,
  resetState,
  setState,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CarRental)
