import React from 'react';
import Hero from '../../organism/Hero/Hero';
import Card from '../../atoms/Card/Card';
import Tabs from '../../atoms/Tabs/Tabs'
import Heading from '../../atoms/Heading/Heading';
import Content from '../../molecules/Content/Content';
import Section from '../../molecules/Section/Section';
import Flexbox from '../../molecules/Flexbox/Flexbox';
import FlexboxItem from '../../atoms/FlexboxItem/FlexboxItem';
import Faq from '../../organism/Faq/Faq';
import ModifyForm from '../../organism/ModifyForm';
import MediaQuery from 'react-responsive'
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect, withRouter } from 'react-router';

import { orderAction } from '../../../store/actions';
import Preloader from '../../atoms/Preloader/Preloader';
import ModifyRentForm from "../../organism/ModifyRentForm";

const { getOrder } = orderAction;

class ModifyOrder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      idHash: null,
      isValidOrder: true,
    };
  }
  componentDidMount() {
    const { history, match } = this.props;
    const { idHash } = match.params;

    if (!idHash) return history.push('/');

    this.setState({ idHash });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.idHash !== this.state.idHash) {
      this.props.getOrder(this.state.idHash);
    }

    if (this.props.order.fetching !== prevProps.order.fetching && !this.props.order.fetching && this.state.idHash) {
      this.setState({
        isValidOrder: Object.keys(this.props.order.data).length > 0,
      });
    }
  }

  render() {
    const { isValidOrder } = this.state;
    const { order } = this.props;
    const { fetching, data } = order;
    let orderType = 'airport-shuttle';

    if (!isValidOrder) {
      return <Redirect to="/" />
    }

    if (fetching) {
      return <Preloader isActive={fetching} />;
    }

    if (data.orderID && !['paid', 'pending', 'assigned'].includes((data.status || '').toLowerCase())) {
      return <Redirect to="/" />
    }

    if (typeof data.rentalHours !== 'undefined' && typeof data.allInPackage !== 'undefined') {
      orderType = 'car-rental';
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Modify Order - Go Rental</title>
          <meta name="description" content="24/7 service Medan KNO airport transfer and car rental (with driver) at competitive prices. With safety as our priority, our experienced driver will make sure you reach your destination safely & on time. Book with us today, hassle-free later!" />
        </Helmet>
        <Hero carRental={orderType === 'car-rental'}/>
        <Content
          background="grey"
          isOverlapping
        >
          <Section>

            {/* DESKTOP VERSION */}
            <MediaQuery minDeviceWidth={960}>
              <Flexbox>
                <FlexboxItem columnClass="col-sm-6">
                  <Card colorWhite>
                    {orderType === 'airport-shuttle' && <ModifyForm data={data} />}
                    {orderType === 'car-rental' && <ModifyRentForm data={data} />}
                  </Card>
                </FlexboxItem>
                <FlexboxItem columnClass="col-sm-6">
                  <Faq from={orderType}/>
                </FlexboxItem>
              </Flexbox>
            </MediaQuery>
            {/* END DESKTOP VERSION */}

            {/* MOBILE VERSION */}
            <MediaQuery maxDeviceWidth={959}>
              <div className="u-mb-20 u-align-center">
                <Heading tag="h2">
                  Book Airport Transfer
                </Heading>
              </div>
              <Card colorWhite isBleed>
                <Tabs color="white">
                  <div labelName="Booking">
                    <ModifyForm data={data} />
                  </div>
                  <div labelName="FAQ">
                    <Faq from={orderType}/>
                  </div>
                </Tabs>
              </Card>
            </MediaQuery>
            {/* END MOBILE VERSION */}

          </Section>
        </Content>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ order }) => ({ order });

const mapDispatchToProps = dispatch => bindActionCreators({
  getOrder,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModifyOrder));
