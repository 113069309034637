import React from 'react'
import './style.scss'

const Blocklist = props => {

  let blocklistClass = 'c-blocklist'

  if (props.alignRight) {
    blocklistClass += ' c-blocklist--right'
  }

  if (props.forDrawer) {
    blocklistClass += ' c-blocklist--drawer'
  }


  return (
    <div className={ blocklistClass }>
      <div className="c-blocklist__body">
        {props.children}
      </div>
    </div>
  )
}

export default Blocklist
