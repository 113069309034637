import React from 'react'
import './style.scss'

const Outline = props => {
  let baseClass = 'c-outline';
  const {children} = props;

  return (
    <div className={baseClass}>
      {children}
    </div>
  )
};

export default Outline
