import React from 'react'
import './style.scss'

const CircleCheckBox = props => {
  let baseClass = 'c-circle-checkbox';

  const {
    number,
    name,
    onChange,
    checked,
    id,
    inactive,
  } = props;

  let {disabled} = props;

  if (inactive) {
    baseClass += ' c-circle-checkbox--not-available';
    disabled = true;
  }

  return (
    <div className={baseClass}>
      <label className="c-circle-checkbox__label" htmlFor={id}>
        <input
          type="checkbox"
          id={id}
          checked={checked}
          name={name}
          onChange={onChange}
          disabled={disabled}
          inactive={inactive}
        />
        <span className="c-circle-checkbox__mark" />
        <div className="c-circle-checkbox__title">
          {number}
        </div>
      </label>
    </div>
  )
};

CircleCheckBox.defaultProps = {
  number: '1',
};

export default CircleCheckBox
