import React from 'react'
import './style.scss'

const Thumbnail = props => {

  let baseClass = 'c-thumbnail';
  let modifierClass = 'c-thumbnail--'

  const {className, alt, imageUrl, style, background} = props;

  if (background === 'grey') {
    baseClass += ' '+ modifierClass
  }

  return (
    <div
      className={
        baseClass + (background || '') + ' ' + (className || '')
      }
      style={style}
    >
      <img className="c-thumbnail__img" src={imageUrl} alt={alt}/>
    </div>
  )
};

Thumbnail.defaultProps = {
  imageUrl: 'https://i.picsum.photos/id/520/500/500.jpg'
};

export default Thumbnail
