import React from 'react'
import Post from "../../molecules/Post/Post";

const TncContent = props => {

  return (
    <Post>
      <h3>Syarat Penggunaan</h3>

      <p>SYARAT
        PENGGUNAAN DI BAWAH INI HARUS DIBACA SEBELUM MENGGUNAKAN SITUS INI.
        PENGGUNAAN SITUS INI MENUNJUKKAN PENERIMAAN DAN KEPATUHAN TERHADAP
        SYARAT DAN KETENTUAN DI BAWAH INI.</p>

      <p>Gorental-indonesia.com&nbsp;situs
        (&quot;Situs&quot;) dikelola oleh Go Rental(&quot;kami&quot;). Dengan
        mengakses dan/atau menggunakan Situs, Anda mengakui bahwa Anda telah
        membaca dan memahami, dan menyetujui untuk terikat oleh Syarat
        Penggunaan yang ditetapkan di bawah ini dan syarat dan ketentuan
        lainnya dalam kaitannya dengan Situs, termasuk namun tidak terbatas
        pada kerahasiaan dan FAQ, yang merupakan bagian yang tidak
        terpisahkan dari Syarat Penggunaan (&quot;Syarat&quot;). Anda harus
        setidaknya berusia delapan belas (18) tahun untuk menggunakan Situs
        ini.</p>

      <p>Harap
        diketahui bahwa kami dapat mengubah, memodifikasi, menambah dan
        menghapus Syarat ini sewaktu-waktu tanpa pemberitahuan sebelumnya.
        Syarat harus dibaca secara berkala. Dengan terus menggunakan Situs
        ini setelah perubahan tersebut terhadap Syarat, pengunjung, pengguna
        atau Pengguna Terdaftar (&quot;Anda&quot; atau &quot;pengguna&quot;)
        sepakat dan menyetujui perubahan. Jika Anda menggunakan salah satu
        layanan kami yang lain, maka penggunaan Anda didasarkan pada
        kepatuhan dan penerimaan terhadap syarat dan ketentuan yang berlaku</p>


      <h4>LINGKUP LAYANAN KAMI</h4>
      <ol>
        <li>Melalui
          Situs, Go Rental menyediakan platform online sehingga Anda dapat
          menelusuri berbagai jenis maskapai penerbangan, akomodasi sementara
          dan penginapan (sebagaimana berlaku), serta membuat reservasi,
          pemesanan dan/atau pembelian (&quot;Layanan&quot;). Pengguna dapat
          membuat pemesanan layanan yang disediakan oleh hotel, maskapai
          penerbangan, dan/atau penyedia layanan lain yang bekerjasama dengan
          Go Rental (&quot;Vendor&quot;) di Situs. Dengan meletakkan pesanan
          melalui Situs, Anda akan dapat memesan dan/atau membeli airport
          transfer, sewa mobil atau layanan lainnya pada Situs kami. Kami akan
          memberikan konfirmasi pemesanan atau pembelian melalui konfirmasi
          email. Kami berhak untuk menolak pemesanan sebagaimana diatur di
          bawah ini.</li>
        <li>Meskipun
          kami akan menggunakan keahlian kami dengan hati-hati dalam melakukan
          layanan, kami tidak memverifikasi, dan tidak menjamin, bahwa semua
          informasi yang diberikan akurat, lengkap, benar atau terbaru yang
          tersedia, dan kami tidak bertanggung jawab atas setiap kesalahan
          (termasuk kesalahan penempatan dan pengetikan), gangguan (baik
          sementara dan/atau parsial, kerusakan, perbaikan atau peningkatan ke
          situs atau sebaliknya), informasi yang tidak akurat, menyesatkan
          atau palsu atau informasi yang tidak tersampaikan. Hal ini mencakup
          setiap informasi yang ditampilkan pada Situs atau platform kami
          lainnya sehubungan dengan jenis kenderaan, warna, dan kapasitas.</li>
        <li>Perubahan
          dalam kondisi pasar atau keadaan yang terjadi dapat menyebabkan
          perubahan dalam waktu singkat, yang menyebabkan informasi tidak
          akurat atau tidak berlaku. Dalam hal terjadi masalah, layanan
          pelanggan dapat berusaha untuk membantu Anda dan memenuhi permintaan
          Anda.</li>
        <li>Kami
          memiliki hak untuk tidak menerima setiap pengguna atau pemesanan
          (atau dalam kasus-kasus tertentu membatalkan konfirmasi pemesanan)
          berdasarkan kebijaksanaan tunggal kami dan untuk alasan apa pun
          serta tanpa memberikan alasan penolakan/penampikan/pembatalan.
          Alasan untuk menolak pengguna atau pemesanan atau membatalkan
          konfirmasi pemesanan dapat termasuk namun tidak terbatas pada:
          pelanggaran Syarat ini, perdagangan atau sanksi ekonomi oleh
          otoritas global atau nasional, embargo, larangan dalam peraturan,
          penipuan atau pencurian (atau indikasi atau dugaan penipuan atau
          pencurian), dugaan kegiatan kriminal, pemesanan yang mencurigakan,
          layanan yang tidak tersedia atau tidak lagi disediakan oleh Vendor,
          pengguna memberikan informasi yang tidak akurat, keliru atau
          menyesatkan, masalah dengan komunikasi kartu kredit elektronik,
          informasi atau transaksi, perilaku yang tidak pantas, ancaman,
          hinaan, penolakan untuk memberikan informasi, hambatan praktis,
          kesulitan atau kemacetan komunikasi, Kesalahan Nyata (selanjutnya
          dijelaskan di bawah ini), sejarah pelanggaran Syarat atau penolakan
          tersebut, atau dimasukkan pada setiap &quot;daftar hitam&quot; atau
          &quot;daftar pengamatan&quot; oleh pemerintah atau organisasi
          internasional. Dalam kasus pemesanan ditolak atau dibatalkan oleh Go
          Rental dan pembayaran telah dilakukan, Go Rental akan mengganti
          jumlah yang telah dibebankan tanpa biaya lebih lanjut. Kami dapat
          menghilangkan atau menghapus (&quot;Dihapus&quot;) keanggotaan
          pengguna Situs ini sewaktu-waktu, baik sementara atau permanen.
          Pengguna yang Dihapus dilarang untuk mencoba menggunakan Situs
          dengan nama lain atau melalui pengguna lainnya (selanjutnya
          dijelaskan dalam ketentuan Mekanisme Pencegahan Penipuan).</li>
        <li>Dalam
          kasus tertentu, kami dapat membatalkan atau menolak pemesanan
          berkenaan dengan &quot;Kesalahan Nyata&quot;, yang tidak tergantung
          pada letak kesalahan berasal. Untuk menghindari keraguan, Kesalahan
          Nyata adalah kesalahan pada Situs (misalnya, dalam hal harga) yang
          tidak ada satu orang yang rasional pun akan menganggap layak atau
          membuat bisnis dimengerti. Jumlah yang dibebankan akan diganti tanpa
          biaya lebih lanjut dalam kasus seperti itu.</li>
      </ol>
      <h4>PEMBATALAN</h4>
      <ol>
        <li>Dengan
          membuat pemesanan, pesanan atau reservasi melalui Situs, Anda
          menerima dan setuju dengan syarat dan ketentuan dari Go Rental yang
          berlaku, termasuk yang berkaitan dengan kebijakan tentang pembatalan
          dan/atau ketiadaan, atau sehubungan dengan permintaan khusus Anda
          yang mungkin diberikan kepada Go Rental.</li>
        <li>Sehubungan
          dengan pembatalan pengguna yang telah dilakukan, Go Rental mungkin
          dapat menahan atau mengambil bagian dari jumlah yang dibayarkan
          untuk mengganti biaya yang telah dikeluarkan sehubungan dengan
          pembatalan.</li>
      </ol>
      <h4>SARAN PERJALANAN</h4>
      <ol>
        <li>Dengan
          menampilkan destinasi tertentu, Go Rental tidak mengemukakan atau
          menjamin bahwa perjalanan ke tempat-tempat tersebut bermanfaat atau
          bebas risiko dan Go Rental tidak akan dimintai pertanggungjawaban
          atas kerusakan atau kerugian yang mungkin diakibatkan dari
          perjalanan ke destinasi tersebut. Dalam situasi apa pun, Go Rental
          tidak akan bertanggung jawab atas insiden merugikan yang terjadi
          selama perjalanan. Anda secara pribadi bertanggung jawab untuk
          pemilihan perjalanan, rute dan destinasi perjalanan, untuk sepanjang
          perjalanan Anda. Go Rental tidak bertanggung jawab atas kerugian
          yang terjadi ketika Anda gagal membawa dokumen perjalanan yang
          diperlukan, seperti paspor Anda, e-tiket, e-voucher, dan hal-hal
          lainnya yang disebabkan oleh kesalahan dan/atau kelalaian Anda. Go
          Rental juga tidak bertanggung jawab atas segala kesalahan dan/atau
          kelalaian yang disebabkan oleh Vendor.</li>
        <li>Anda
          bertanggungjawab sepenuhnya untuk mendapatkan, menjaga dan
          menyediakan untuk ditunjukkan, izin-izin perjalanan atau
          persyaratan-persyaratan izin masuk orang asing yang sesuai dan sah
          (termasuk, namun tidak terbatas pada, visa atau izin-izin perjalanan
          lainnya, baik untuk transit atau keperluan lainnya) yang berlaku
          untuk Anda sebelum Anda memfinalisasi pengaturan perjalanan Anda
          sesuai dengan hukum yang berlaku di negara asal, tujuan, lewati atau
          transit dalam perjalanan Anda. Go Rental tidak memiliki kewajiban
          dan tidak akan bertanggungjawab untuk memberitahukan Anda mengenai
          pengaturan perjalanan dan izin-izin yang dibutuhkan oleh Anda agar
          dapat melaksanakan rencana perjalanan anda. Go Rental memiliki
          haknya untuk membatasi pertanggungjawabannya dalam hal adanya
          kerugian atau kerusakan yang timbul dari atau sehubungan dengan
          izin-izin perjalanan Anda.</li>
      </ol>
      <h4>HARGA DAN PROMOSI</h4>
      <ol>
        <li>Kami
          dapat menawarkan harga yang lebih rendah dan/atau promosi dari waktu
          ke waktu. Harap diketahui bahwa hal tersebut mungkin memerlukan
          ketentuan dan persyaratan yang berbeda, misalnya, sehubungan dengan
          kebijakan pemesanan dan pengembalian dana.</li>
        <li>Apabila
          ada promosi yang diberikan oleh Vendor secara langsung, maka hak dan
          wewenang atas promosi tersebut sepenuhnya berada pada Vendor dan
          mungkin tidak berlaku untuk pemesanan yang dilakukan melalui Go
          Rental.</li>
      </ol>
      <h4>BIAYA TAMBAHAN &amp; PENGEMBALIAN DANA</h4>
      <ol>
        <li>Setiap
          harga yang tercantum pada Situs ini hanya tersedia dengan ketentuan
          tertentu dan harga tersebut dapat berubah tergantung pada
          ketersediaan pemesanan, lamanya pemesanan dan/atau kuota. Harga yang
          tersedia dapat mencakup pajak tambahan dan biaya lainnya lagi tapi
          dalam keadaan tertentu mungkin tidak termasuk pajak dan biaya jasa
          lainnya (seperti biaya tips untuk pemandu wisata, biaya fasilitas
          lainnya (jika ada), dan biaya-biaya lainnya yang timbul bukan dari
          penggunaan layanan kami); pengguna setuju bahwa mereka bertanggung
          jawab untuk memverifikasi total biaya yang harus dibayar dan
          persyaratan lainnya dan rincian ketika email konfirmasi dikirim ke
          pengguna. Pengguna harus memverifikasi pemesanan dalam lembar
          pemesanan; pengguna dapat membatalkan pemesanan setiap saat sebelum
          konfirmasi akhir dilakukan. Harga yang ditampilkan terperinci
          sehingga pengguna dapat melihat jumlah yang harus dibayar, biaya
          tambahan apa pun yang disebabkan penggunaan kartu kredit atau biaya
          antar bank yang dikenakan untuk biaya pengiriman akan dibebankan
          kepada pengguna dan jika terdapat kekurangan dalam jumlah yang
          dibayarkan, maka Go Rental dapat memberikan notifikasi email
          mengenai kekurangan dalam jumlah yang harus dibayarkan pengguna.
          Dalam hal pengguna membatalkan pemesanan sebelum membayar penuh
          segala kekurangan yang ada, Go Rental memiliki kebijaksanaan tunggal
          apakah akan mengembalikan jumlah yang dibayarkan atau mengembalikan
          jumlah yang telah dikurangi biaya yang dikeluarkan oleh Go Rental
          sebagai akibat dari biaya yang tidak dibayarkan atas kekurangan ini
          oleh pengguna sesuai dengan prosedur yang ditetapkan oleh Go Rental.
          Jika pengguna memiliki keraguan tentang layanan, pengguna dapat
          menghubungi layanan pelanggan Go Rental (selama waktu operasional
          layanan pelanggan yang ditentukan oleh Go Rental). Untuk setiap
          pembatalan lainnya, Go Rental akan bekerja dengan Anda untuk
          mengembalikan dana yang telah dibayar, setelah dikurangi biaya yang
          dikeluarkan oleh Go Rental, misalnya biaya transfer antar bank.
          Pengembalian dana mungkin tidak segera tergantung pada metode awal
          pembayaran. Anda dapat menghubungi layanan pelanggan Go Rental untuk
          rincian lebih lanjut mengenai perkiraaan durasi untuk menerima
          pengembalian dana Anda dan kami akan membantu Anda sebaik yang kami
          mampu.</li>
        <li>Go
          Rental tidak akan bertanggung jawab atau berkewajiban atas setiap
          penerbitan e-tiket, e-voucher, atau transaksi lainnya yang
          digugurkan akibat jumlah yang ditransfer tidak akurat oleh pengguna.
          Setiap transfer yang dilakukan melebihi batas waktu untuk transfer
          akan mengakibatkan pemesanan digugurkan. Untuk waktu nyata
          (real-time) transfer antar bank, Anda harus menggunakan ATM. Setelah
          menyelesaikan transfer antar bank, harap konfirmasi pembayaran Anda
          dengan mengisi formulir konfirmasi pembayaran.</li>
      </ol>
      <h4>BIAYA TAMBAHAN DARI HOTEL</h4>
      <ol>
        <li>Harap
          diketahui bahwa semua harga untuk reservasi kamar yang diberikan
          melalui Situs kami (&quot;Harga Kamar&quot;) berdasarkan pada
          pilihan Anda, seperti untuk periode tinggal yang diminta dan jumlah
          tamu, kecuali ditentukan lain. Tarif reservasi kamar dikutip per
          kamar dan per malam. Anda bertanggung jawab untuk memverifikasi
          apakah pilihan dan permintaan Anda atau layanan tambahan apa pun
          tercermin dalam harga yang ditampilkan di Situs (seperti Informasi
          Kamar disediakan sebelum konfirmasi pemesanan final) dan/atau dalam
          konfirmasi email kupon. Sarapan umumnya tidak termasuk, kecuali
          dinyatakan lain. Anda bertanggung jawab untuk memverifikasi setiap
          penjelasan atau kebijakan yang disediakan oleh hotel di Situs.
          Asuransi apa pun tidak termasuk dalam harga, kecuali dinyatakan
          lain. Standar reservasi kamar hotel adalah untuk satu hingga dua
          tamu; biaya tambahan umumnya akan diperlukan untuk tempat tidur
          tambahan. Hotel dapat menolak untuk menerima tamu tambahan jika
          tidak diberitahukan sebelumnya.</li>
      </ol>
      <h4>DETAIL DAN PROSEDUR PEMBAYARAN</h4>
      <ol>
        <li>Pembayaran
          dilakukan dalam jumlah dan mata uang sebagaimana dinyatakan tanpa
          biaya bank penjamin atau beban lain apa pun oleh Go Rental.</li>
        <li>Go
          Rental tidak memungut pajak atau retribusi. Pembebanan pajak apa pun
          akan ditanggung oleh pengguna.</li>
        <li>Untuk
          semua bentuk reservasi, Anda harus melakukan pembayaran sebelum
          batas waktu yang telah ditentukan. Jika pembayaran tidak dilakukan,
          maka Go Rental berhak untuk membatalkan semua reservasi atau
          mengabaikan proses pemesanan.</li>
        <li>Metode
          pembayaran termasuk namun tidak terbatas pada transfer bank dan
          kartu kredit. Untuk pembayaran melalui transfer bank, Anda harus
          memberikan tanda terima transfer bank sebagai bukti pembayaran
          reservasi yang tercantum dalam email konfirmasi Go Rental. Anda
          harus mengikuti prosedur yang terdapat dalam email konfirmasi.</li>
        <li>Untuk
          metode pembayaran lainnya seperti kartu kredit, Anda harus mengikuti
          prosedur yang dinyatakan oleh Go Rental dan/atau penyedia layanan
          pembayaran terkait.</li>
        <li>Setelah
          Anda melakukan pembayaran penuh, Go Rental akan menempatkan pesanan
          dan mengeluarkan kode reservasi, yang akan disertai dengan:</li>
        <ol>
          <li>Kode
            Pemesanan;</li>
          <li>Nama
            Pelanggan;</li>
          <li>Nomor
            Pemesanan;</li>
          <li>Rincian
            Pemesanan; dan</li>
          <li>Nomor
            Telepon/Extension.</li>
        </ol>
      </ol>
      <h4>PEMBAYARAN DENGAN KARTU KREDIT &amp; PENIPUAN</h4>
      <ol>
        <li>Untuk
          pembayaran pemesanan yang ditempatkan melalui Situs ini, kartu
          kredit Anda akan ditagih oleh Go Rental untuk harga penuh pada saat
          pemesanan dan konfirmasi dari pemesanan (setiap pengembalian yang
          mungkin diberikan akan tergantung pada ketentuan reservasi yang
          ada). Anda harus memeriksa rincian pemesanan secara menyeluruh
          setiap saat sebelum Anda membuat reservasi. Go Rental akan memproses
          setiap pengembalian, sebagaimana berlaku, dalam jangka waktu yang
          wajar. Untuk reservasi tertentu, Go Rental mungkin tidak membebani
          kartu kredit Anda dan meminta pembayaran kepada Vendor terkait
          secara langsung dan tidak membebani kartu kredit Anda, karena Anda
          wajib membayar vendor terkait secara langsung. Dalam rangka menjaga
          dan mengenkripsi informasi kartu kredit Anda ketika transit pada
          kami, kami menggunakan teknologi enkripsi seperti Transport Layer
          Security (&quot;TLS&quot;) atau Secure Sockets Layer (&quot;SSL&quot;)
          untuk layanan kami.</li>
        <li>Dalam
          hal adanya penipuan atau penyalahgunaan atas metode pembayaran
          pilihan anda oleh pihak ketiga, anda harus segera menghubungi bank
          atau penerbit kartu anda setelah anda mengetahui adanya
          penyalahgunaan tersebut. Dalam hal ini, Go
          Rental tidak
          akan bertanggungjawab atas setiap kejadian penipuan atau
          penyalahgunaan oleh pihak ketiga. Go Rentaltidak berkewajiban untuk
          melakukan pengembalian pembayaran atau membayarkan kembali kepada
          anda sebagai akibat dari penipuan tersebut. Untuk membuat reservasi,
          Anda harus berusia di atas delapan belas (18) tahun dan memiliki
          kapasitas hukum penuh untuk membuat transaksi (atau memiliki
          wewenang dari wali sah Anda). Anda harus menggunakan metode
          pembayaran yang Anda miliki, diterbitkan dengan nama Anda, dan
          pastikan bahwa ada dana yang memadai untuk menutupi biaya transaksi.
          Anda menerima tanggung jawab keuangan untuk semua transaksi yang
          dilakukan atas nama Anda atau akun Anda.</li>
        <li>Anda
          harus memastikan bahwa rincian yang Anda berikan kepada kami adalah
          sepenuhnya benar, tepat dan akurat. Go Rental berhak untuk tidak
          menerima metode pembayaran tertentu. Go Rental dapat menambah atau
          menghapus metode pembayaran lainnya atas kebijaksanaannya.</li>
        <li>Dalam
          kasus tertentu, kami mungkin memerlukan informasi tambahan atau
          verifikasi untuk memvalidasi dan mengkonfirmasi pemesanan, seperti
          yang dijelaskan secara lebih rinci dalam Situs. Pemesanan tidak
          dikonfirmasi sampai Anda telah menerima email konfirmasi dengan
          e-tiket atau voucer dan terdapat kemungkinan bahwa Go Rental dapat
          menerapkan mekanisme pemeriksaan penipuan selama proses pemesanan.
          Jika terjadi penipuan atau ditentukan akan terjadi, maka pemesanan
          tidak akan lagi tersedia atau secara otomatis menjadi tidak berlaku.
          Go Rental tidak akan memikul tanggung jawab apa pun. Jika Anda
          memilih untuk tidak mengirimkan informasi tambahan, reservasi tidak
          akan diselesaikan dan akan menjadi tidak berlaku.</li>
      </ol>
      <h4>HAK DAN KEWAJIBAN</h4>
      <ol>
        <li>Go
          Rental dengan ini memberikan pengguna hak terbatas tertentu
          (merupakan &quot;Lisensi Terbatas&quot;), yang tidak dapat
          dipindahkan atau didelegasikan, untuk mengakses dan menggunakan
          Situs sejauh mana dinyatakan diizinkan oleh Syarat ini. Sehubungan
          dengan Lisensi Terbatas ini, kami tidak akan memberikan Anda hak
          atau lisensi sehubungan dengan penggunaan Situs; hak atau lisensi
          yang tidak diberikan secara tersurat, sepenuhnya dimiliki oleh Go
          Rental atau pemilik pihak ketiga lainnya dari lisensi tersebut.
          Konten yang tersedia di Situs (termasuk infrastruktur perangkat
          lunak yang digunakan untuk menyediakan Konten) sepenuhnya dimiliki
          oleh Go Rental atau pemasok dan penyedia layanannya. Anda dapat
          menggunakan situs tersebut untuk menaruh pesanan dan Anda dengan ini
          menyatakan dan menjamin bahwa Anda tidak akan mengajukan permintaan
          reservasi palsu atau spekulatif untuk mengantisipasi pemesanan yang
          tersedia. Anda dengan ini menyatakan untuk memastikan bahwa
          informasi pembayaran yang Anda berikan adalah benar dan sepenuhnya
          akurat. Anda juga menjamin untuk memberikan alamat surat elektronik
          yang tepat dan akurat, alamat surat menyurat dan/atau rincian
          informasi kontak Anda.</li>
        <li>Berkaitan
          dengan Syarat Penggunaan dari Situs, Anda setuju untuk tidak
          menggunakan Situs atau Konten untuk tujuan komersial atau baik
          secara langsung maupun tidak langsung untuk tujuan selain penggunaan
          pribadi atau untuk yang melanggar hukum (dilarang oleh hukum) atau
          melakukan tindakan yang melanggar Syarat tersebut. Kecuali dengan
          kesepakatan tertulis dari Go Rental, Anda setuju untuk tidak
          memodifikasi, menyalin, mendistribusikan, mengirimkan, menampilkan,
          mereproduksi, mempublikasikan, melisensikan, menciptakan karya
          turunan dari, mentransfer, atau menjual atau menjual kembali setiap
          informasi, perangkat lunak, produk atau layanan yang diperoleh
          sehubungan dengan Situs. Selain itu, Anda setuju untuk tidak:</li>
        <ol>
          <li>menggunakan
            Situs ini atau Konten untuk tujuan komersial tanpa izin dari Go
            Rental;</li>
          <li>mengakses,
            memonitor atau menyalin Konten pada Situs menggunakan teknologi,
            perangkat lunak, atau program apa pun baik secara manual atau
            otomatis untuk tujuan apa pun tanpa izin tertulis dari Go Rental;</li>
          <li>melakukan
            tindakan apa pun yang membebani atau mungkin membebani, tanggungan
            yang tidak wajar pada Situs atau infrastruktur kami;</li>
          <li>membuat
            tautan utama ke Situs ini (termasuk namun tidak terbatas pada jalur
            pemesanan) untuk tujuan apa pun tanpa persetujuan tertulis dari Go
            Rental;</li>
          <li>menjual
            kembali, menggunakan, menyalin, melakukan pengawasan (seperti
            menggunakan atau memasang program spider atau scrape), menampilkan,
            mengunduh atau melakukan produksi Konten apa pun, perangkat lunak,
            produk, layanan yang tersedia melalui Situs untuk tujuan komersial
            atau tujuan/kegiatan kompetisi;</li>
          <li>mereproduksi
            Situs (melalui &quot;frame&quot; atau &quot;mirror&quot;) atau
            mengatur bagian dari Situs ini pada setiap situs web lain tanpa
            persetujuan tertulis sebelumnya;</li>
          <li>mengirim
            pengumuman ke atau melalui Situs yang melanggar hukum, peraturan,
            atau aturan, atau yang dapat mendukung kegiatan yang melanggar
            hukum atau kegiatan kriminal;</li>
          <li>mengirimkan
            atau menyediakan tautan ke atau mengirimkan pengumuman yang
            mengandung materi yang dapat dianggap berbahaya, cabul, pornografi,
            tidak senonoh, kekerasan, rasis, atau merupakan diskriminasi,
            hinaan, ancaman, pelecehan, penindasan, kebencian atau materi tidak
            menyenangkan lainnya, sesuai dengan kebijaksanaan Go Rental ini;</li>
          <li>mengirimkan
            atau menyediakan tautan ke setiap pengumuman yang mengandung bahasa
            pencemaran nama baik, fitnah, dan kebohongan;</li>
          <li>mengirimkan
            atau melakukan pengumuman yang mungkin melanggar kekayaan
            intelektual atau hak-hak lain dari entitas atau orang tertentu,
            termasuk namun tidak terbatas pada hak cipta, paten, merek dagang,
            rahasia dagang atau informasi rahasia atau publikasi;</li>
          <li>mengirimkan
            pengumuman ketika dilarang oleh hukum yang berlaku atau melanggar
            hak dan kewajiban yang ada berdasarkan hubungan kontraktual;</li>
          <li>meniru
            orang atau kelompok apa pun, atau menyatakan dengan keliru atau
            salah mengemukakan kepada seseorang atau entitas terhadap hubungan
            atau mengambil identitas palsu jika tujuannya untuk menyesatkan,
            menipu atau memperdaya;</li>
          <li>memanipulasi
            atau memalsukan informasi yang bertujuan untuk menyamarkan
            asal-usul pernyataan yang Anda berikan;</li>
          <li>menggunakan
            Situs dengan cara apa pun yang dapat merusak, menonaktifkan,
            menghambat, atau mengganggu penggunaan Situs atau pengguna lain
            dari peralatan komputer, atau menyebabkan kerusakan, gangguan atau
            membatasi fungsi dari perangkat lunak, perangkat keras atau
            peralatan telekomunikasi;</li>
          <li>mendapatkan
            akses tidak sah atau melakukan modifikasi yang tidak sah ke Situs
            atau situs web terkait lainnya, akun lainnya, sistem komputer,
            jaringan yang terhubung dengan Situs dengan cara meretas, pencurian
            kata sandi atau hal-hal lain yang sejenis;</li>
          <li>memperoleh
            atau berusaha memperoleh materi atau informasi apa pun dengan cara
            yang tidak sengaja disediakan oleh situs (termasuk namun tidak
            terbatas pada destinasi lain yang disediakan oleh situs ini). Hal
            ini termasuk namun tidak terbatas pada, memperoleh atau
            mengumpulkan informasi tentang orang lain seperti alamat email;</li>
          <li>terlibat
            dalam tindakan penipuan atau tindakan yang bertujuan untuk
            memanipulasi halaman hasil mesin pencari (&quot;SERP&quot;) atau
            melakukan optimasi mesin pencari (&quot;SEO&quot;). Praktik SEO
            dianggap tidak etis atau merupakan &quot;spamdexing&quot; termasuk
            namun tidak terbatas pada cloaking, metadata, tag judul, scraping
            konten, skema tautan, google bomb, pencarian kata kunci, teks
            tersembunyi atau tautan tersembunyi, skema tautan, komentar yang
            mengandung spam dan hal-hal lain; atau</li>
          <li>tindakan
            lain apa pun yang dapat memberi pengaruh negatif atau mengakibatkan
            kerusakan pada Situs, Go Rental atau afiliasinya dan karyawan, atau
            reputasi Go Rental ini.</li>
        </ol>
        <li>Kecuali
          ditentukan lain secara tegas, situs web tidak diizinkan untuk
          membuat tautan ke halaman lain selain halaman utama Situs atau frame
          atau halaman web atau materi yang terkandung di dalamnya, atau
          membuat tautan ke aspek Situs dalam bentuk email dengan tujuan
          komersial tanpa persetujuan tertulis yang dinyatakan oleh Go Rental.</li>
        <li>Dengan
          menempatkan pesanan melalui Situs ini, Anda dengan ini menyatakan
          dan menjamin bahwa Anda tidak dikenakan larangan atau pembatasan apa
          pun oleh program sanksi apa pun, atau dikenakan hukuman di bawah
          rezim antipencucian uang mana pun.</li>
      </ol>
      <h4>HAK UNTUK KONTEN PENGGUNA</h4>
      <ol>
        <li>Dengan
          menyelesaikan pemesanan, Anda dengan ini setuju untuk menerima surat
          elektronik yang berisi undangan untuk memberikan ulasan atau ulasan
          konten. Go Rental memiliki kebijaksanaan tunggal apakah
          korespondensi Anda akan muncul dalam ulasan. Go Rental dapat
          menampilkan korespondensi, yang mungkin berisi komentar, tingkat
          layanan dan nama Anda.</li>
          <li>Dalam
            memberikan ulasan, Anda setuju untuk memastikan bahwa:</li>
            <ol>
              <li>Anda
                memiliki dan mengendalikan semua hak atas ulasan yang Anda berikan
                ke Situs;</li>
                <li>konten
                  ulasan akurat dan tidak mengandung penggambaran yang keliru; dan</li>
                  <li>penggunaan
                    atau kinerja atau pengiriman konten ulasan tidak melanggar Syarat
                    atau undang-undang dan peraturan yang berlaku, Anda tidak melanggar
                    hak pihak ketiga, dan Anda tidak menyebabkan cedera pada pihak mana
                    pun.</li>
            </ol>
            <li>Anda harus memikul semua tanggung jawab atas konten dari ulasan yang Anda
              berikan atau kirimkan. Anda memperkenankan Go Rental untuk bertindak
              ketika ada pihak yang melanggar hak-hak Anda atau hak Go Rental.</li>
            <li>Ulasan konten yang diberikan akan dianggap tidak terdiri atas informasi
                rahasia dan Go Rental tidak akan memiliki kewajiban untuk
                memperlakukan ulasan konten sebagai informasi rahasia. Tanpa
                membatasi ketentuan yang terdapat dalam Syarat ini, Go Rental
                memiliki kebijaksanaan tunggal untuk menggunakan ulasan konten yang
                dianggap sesuai, termasuk namun tidak terbatas pada penghapusan,
                pemotongan, modifikasi, atau menyembunyikannya dari layar. Go Rental
                tidak memiliki kewajiban untuk membayar konten yang Anda kirimkan
                dalam ulasan, termasuk namun tidak terbatas pada ulasan konten yang
                telah diubah, dihilangkan atau dipotong. Go Rental tidak akan
                memiliki kewajiban untuk menyediakan atau mencantumkan atau
                menyebutkan penulis atau pihak ketiga lainnya.</li>
                <li>Dalam hal apa pun, Anda dengan ini setuju bahwa sehubungan dengan konten dari ulasan:</li>
                  <ol>
                  <li>Anda tidak memerlukan atribusi atau identifikasi dalam setiap karya turunan;</li>
                  <li>Anda memperkenankan penerbitan, penggunaan, penghapusan, modifikasi,
                    reproduksi atau eksploitasi dari ulasan konten akan dilakukan oleh
                    Go Rental dan karyawannya, penerus dan penerima pengalihan mereka;</li>
                  <li>Anda dengan ini melepaskan hak dan setuju untuk tidak mengklaim hak apa
                      pun dalam ulasan konten; dan</li>
                  <li>Anda dengan ini mengganti kerugian dan tidak menyalahkan Go Rental dan karyawannya, afiliasi, pemegang hak, penggantian, dan menunjuk dari setiap klaim sehubungan dengan konten dari ulasan.</li>
                  </ol>
      </ol>
      <h4>PEMBATASAN PENGGUNAAN</h4>
      <ol>
        <li>Anda
          dengan ini setuju untuk tidak menggunakan Situs atau Konten untuk
          kegiatan yang tidak sah atau melanggar hukum. Anda setuju bahwa Anda
          tidak akan menggunakan peralatan apa pun, perangkat lunak, atau
          teknologi lainnya yang mungkin menghambat atau berusaha menghalangi
          pengoperasian Situs ini. Anda setuju untuk tidak menggunakan Situs
          ini atau Kontennya untuk tujuan komersial. Anda setuju untuk tidak
          berupaya, membuat, mencari, menggunakan atau mengirim sarana
          otomatis atau bentuk lain dari teknologi untuk mengumpulkan atau
          mendapatkan informasi dari Situs ini, atau cara lain untuk
          berinteraksi dengan Situs ini.</li>
      </ol>
      <h4>HAK KEKAYAAN INTELEKTUAL</h4>
      <ol>
        <li>Semua
          Hak Kekayaan Intelektual dalam Situs ini dimiliki oleh Go Rental.
          Semua informasi dan bahan, termasuk namun tidak terbatas pada,
          perangkat lunak, teks, data, grafik, citra, suara, video, merek
          dagang, logo, ikon, kode html dan kode lainnya dalam situs web ini
          dilarang untuk dipublikasikan, dimodifikasi, disalin, direproduksi,
          digandakan atau diubah dengan cara apa pun di luar wilayah Situs ini
          tanpa izin yang dinyatakan oleh Go Rental. Jika Anda melanggar
          hak-hak ini, Go Rental berhak untuk membuat gugatan perdata untuk
          jumlah keseluruhan kerusakan atau kerugian yang diderita.
          Pelanggaran-pelanggaran ini juga bisa merupakan tindak pidana.</li>
        <li>Go
          Rental dan/atau perusahaan afiliasinya adalah pemilik hak kekayaan
          intelektual tertentu (&quot;Hak Kekayaan Intelektual&quot;),
          termasuk namun tidak terbatas pada nama domain, Situs, merek dagang,
          konten, hak cipta, merek layanan, logo, simbol atau desain lainnya,
          dll. Tidak ada di dalam Persetujuan ini yang akan ditafsirkan
          sebagai memberikan Anda lisensi atau hak apa pun, tersirat atau
          sebaliknya, untuk menggunakan, memiliki, mendistribusikan atau
          memodifikasi Kekayaan Intelektual Go Rental mana pun. Produk lain
          dan nama perusahaan yang terkandung dalam Situs, termasuk nama,
          merek dagang, tanda, merek layanan, logo, simbol atau desain lainnya
          dapat dimiliki atau dilisensikan untuk digunakan oleh pihak ketiga.
          Penggunaan hak kekayaan intelektual oleh pihak ketiga di Situs ini
          tidak dianggap sebagai suatu rekomendasi atau pemberian sponsor
          terhadap situs oleh pihak ketiga. Go Rental memiliki hak cipta pada
          Situs, dan Anda dilarang untuk menggunakan, memiliki,
          mendistribusikan atau memodifikasi Kekayaan Intelektual mana pun
          tanpa persetujuan yang dinyatakan oleh Go Rental.</li>
        <li>Situs
          ini berisi Kekayaan Intelektual Go Rental, termasuk namun tidak
          terbatas pada teks, perangkat lunak, foto, grafik, video, musik dan
          suara. Seluruh Konten Situs ini dilindungi oleh undang-undang hak
          cipta. Kami dan pemberi lisensi kami memiliki hak cipta dan/atau
          hak-hak lainnya atas pemilihan, koordinasi, pengaturan, dan
          perbaikan Konten dan konten asli. Anda tidak boleh memodifikasi,
          mereproduksi, menyalin, melakukan, menampilkan, mempublikasikan,
          atau mengeksploitasi Konten, secara keseluruhan atau sebagian,
          kecuali dinyatakan lain secara tegas dalam Syarat ini.</li>
        <li>Anda
          dapat menggunakan informasi di Situs hanya untuk penggunaan pribadi,
          non-komersial. Kecuali ditentukan lain, dan secara tegas diizinkan
          oleh hukum hak cipta, Anda tidak dapat menyalin, mereproduksi,
          mendistribusikan, mengirimkan ulang, mempublikasikan atau sebaliknya
          mengeksploitasi secara komersial setiap unduhan yang Anda buat dari
          Situs tanpa izin dari pemilik hak kekayaan intelektual. Bahkan jika
          Anda mendapatkan izin yang diperlukan, Anda dilarang untuk melakukan
          perubahan atau penghapusan. Anda dengan ini menerima dan setuju
          bahwa mengunduh setiap Kekayaan Intelektual tidak memberikan Anda
          hak atas mereka.</li>
        <li>Kami
          dapat menyediakan tautan ke situs-situs lainnya yang dikelola oleh
          pihak lain, dengan mengeklik pada tautan, Anda dengan ini
          mengemukakan, mengakui dan setuju bahwa tindakan tersebut merupakan
          tindakan sukarela Anda untuk melihat atau mengunjungi situs lain
          yang Go Rental tidak memiliki pengawasan atau bertanggung jawab
          terhadapnya.</li>
        <li>Go
          Rental dalam membuat Situs, menyiapkan kode sumber dan melakukan
          dukungan perangkat lunak, dapat melisensikan penggunaan perangkat
          lunak sumber terbuka dari pihak ketiga melalui GNU General Public
          Licence (&quot;GPL&quot;), Setiap penggunaan Go Rental terhadap
          perangkat lunak sumber terbuka Go Rental dan hak kekayaan
          intelektual pihak ketiga adalah dengan lisensi atau izin yang
          diperlukan.</li>
      </ol>
      <h4>KLAIM PELANGGARAN HAK KEKAYAAN INTELEKTUAL</h4>
      <ol>
        <li>Jika
          terdapat bukti pelanggaran Hak Kekayaan Intelektual Anda, maka Anda
          atau perwakilan Anda (secara bersamaan, &quot;Pengirim&quot;) dapat
          mengirimkan pemberitahuan yang berisi rincian sebagai berikut yang
          terdata di bawah ini:</li>
          <ol>
            <li>Nama
              dan alamat Pengirim;</li>
            <li>Dalam
              hal Pengirim bukan pemilik Hak Kekayaan Intelektual atau lisensi
              eksklusif, maka harus mencantumkan nama dan alamat pemilik Hak
              Kekayaan Intelektual;</li>
            <li>Dalam
              hal alamat Pengirim tidak di Indonesia, Pengirim harus memberikan
              alamat sementara di Indonesia;</li>
            <li>Nomor
              telepon, faksimili (jika ada) dan alamat surat elektronik Pengirim;</li>
            <li>Detail
              yang cukup sehingga dapat membuktikan adanya pelanggaran hak
              kekayaan intelektual, termasuk alamat online dari salinan
              elektronik;</li>
            <li>Permintaan
              Pengirim untuk menghapus atau menonaktifkan akses ke salinan
              elektronik atau versi yang dilanggar;</li>
            <li>Pernyataan
              bahwa Pengirim dengan itikad baik mengenali adanya pelanggaran
              sebagaimana diidentifikasi dalam ayat (5) pada Pasal ini;</li>
            <li>Pernyataan
              bahwa informasi yang diberikan dalam pemberitahuan akurat;</li>
            <li>Pernyataan
              bahwa Pengirim (a) adalah pemilik atau pemegang eksklusif hak
              kekayaan intelektual yang dilanggar; atau (b) memiliki kewenangan
              hukum untuk bertindak atas pemilik atau pemegang eksklusif; dan</li>
            <li>Pernyataan
              bahwa Pengirim tunduk pada hukum Indonesia dan bahwa semua klaim,
              perselisihan, kontroversi atau perbedaan yang timbul dari
              pelanggaran hak kekayaan intelektual harus terlebih dahulu diajukan
              ke Indonesia Mediation Centre untuk penyelesaian melalui mediasi
              sesuai dengan Prosedur Mediasi yang berlaku untuk saat ini.
              Pengirim harus menyatakan bahwa mereka setuju untuk berpartisipasi
              dalam mediasi dengan itikad baik dan berusaha untuk mematuhi
              persyaratan dari penyelesaian yang dicapai.</li>
          </ol>
          <li>Surat
            tersebut harus ditandatangani oleh pengirim dan disampaikan secara fisik dan melalui email ke alamat berikut: <strong>Yos Sudarso Kom L No.5 Medan</strong></li>
          <li>Kami
            akan meninjau dan menangani pemberitahuan sesuai dengan persyaratan
            di atas dan sesuai dengan hukum Indonesia.</li>
          <li>Anda
            setuju untuk mengganti kerugian dan tidak menyalahkan kami atas
            setiap kerugian atau kerusakan yang mungkin terjadi sebagai akibat
            dari penipuan, pemalsuan atau tuduhan palsu dari pelanggaran hak
            kekayaan intelektual.</li>
      </ol>
      <h4>DISCLAIMER</h4>
      <ol>
        <li>ANDA
          DENGAN INI MENYATAKAN UNTUK DAN SETUJU BAHWA SEJAUH MANA DIIZINKAN
          OLEH HUKUM YANG BERLAKU:</li>
        <ol>
          <li>LAYANAN
            DAN KONTEN SITUS INI DIBERIKAN BERDASARKAN &quot;SEBAGAIMANA
            ADANYA&quot; DAN &quot;SEBAGAIMANA TERSEDIA&quot;. KAMI DENGAN INI
            MENYATAKAN SECARA TEGAS BAHWA KAMI TIDAK MEMBUAT GARANSI ATAU
            JAMINAN APA PUN, BAIK TERSURAT MAUPUN TERSIRAT, SEHUBUNGAN DENGAN
            KELAYAKAN USAHA DARI PRODUK ATAU LAYANAN, ATAU KESESUAIAN PRODUK
            DAN LAYANAN YANG KAMI SEDIAKAN UNTUK TUJUAN TERTENTU DAN MENENTANG
            PENYALAHGUNAAN HAK-HAK MANA YANG BERLAKU;</li>
          <li>KAMI
            TIDAK MENJAMIN BAHWA: (i) FUNGSI, LAYANAN DAN FITUR KEAMANAN YANG
            DISEDIAKAN DALAM SITUS INI TIDAK AKAN TERGANGGU ATAU BEBAS DARI
            KESALAHAN; (Ii) KESALAHAN ATAU KEGAGALAN AKAN DIPERBAIKI; ATAU
            (iii) SITUS ATAU SERVER MEMBERIKAN LAYANAN YANG BEBAS DARI VIRUS,
            MALWARE ATAU KOMPONEN BERBAHAYA;</li>
          <li>KAMI
            TIDAK MENJAMIN AKURASI, KEASLIAN, INTEGRITAS ATAU KUALITAS KONTEN,
            SITUS ATAU SUMBER DAYA YANG TERSEDIA UNTUK DAN DARI SITUS TERMASUK,
            NAMUN TIDAK TERBATAS PADA, SETIAP GARANSI BAHWA KONTEN, SITUS ATAU
            SUMBER BEBAS DARI SUMBER MATERI YANG JAHAT, TIDAK SENONOH ATAU
            KONTROVERSIAL;</li>
          <li>SETIAP
            MATERI UNDUHAN ATAU LAINNYA YANG DIPEROLEH MELALUI PENGGUNAAN
            LAYANAN INI MERUPAKAN TANGGUNG JAWAB DAN RISIKO ANDA SENDIRI. ANDA
            SECARA SUKARELA MENANGGUNG RISIKO TERSEBUT, DAN ANDA AKAN
            SEPENUHNYA BERTANGGUNG JAWAB ATAS KERUSAKAN PADA SISTEM KOMPUTER
            ANDA ATAU HILANGNYA DATA YANG TERJADI AKIBAT MENGUNDUH MATERI YANG
            DISEDIAKAN.</li>
        </ol>
      </ol>
      <h4>BATASAN TANGGUNG JAWAB</h4>
      <ol>
        <li>TANPA
          BERPRASANGKA TERHADAP APA YANG DIATUR DALAM SYARAT, KEWAJIBAN
          MASING-MASING PIHAK TERBATAS DAN DIKECUALIKAN SEMAKSIMALNYA YANG
          DIIZINKAN OLEH HUKUM, SEPERTI UNTUK CEDERA PRIBADI DAN KEMATIAN.
          KAMI TIDAK AKAN BERTANGGUNG JAWAB ATAS SETIAP KERUGIAN ATAU
          KERUSAKAN, BAIK LANGSUNG ATAU TIDAK LANGSUNG, AKIBAT DARI ATAU
          TERHUBUNG KE PENGGUNAAN ANDA TERHADAP SITUS INI ATAU PENGGUNAAN
          TAUTAN PADA SITUS, TERMASUK NAMUN TIDAK TERBATAS PADA KERUSAKAN
          KHUSUS, INSIDENTAL, HUKUMAN ATAU KERUSAKAN AKIBAT ATAU KERUGIAN
          EKONOMI LAINNYA, MESKIPUN KAMI TELAH DIBERITAHU TENTANG KEMUNGKINAN
          KERUGIAN ATAU KERUSAKAN YANG MUNGKIN TERJADI. PERBAIKAN SATU-SATUNYA
          YANG TERSEDIA BAGI ANDA ADALAH PENGHENTIAN PENGGUNAAN SITUS INI.</li>
      </ol>
      <h4>GANTI RUGI</h4>
      <ol>
        <li>Anda
          dengan ini setuju untuk mengganti kerugian dan tidak menyalahkan Go
          Rental dan karyawannya, afiliasi, staf dan mitra, dari dan terhadap
          setiap dan semua klaim, tuntutan, kewajiban, kerusakan atau kerugian
          termasuk biaya hukum, yang timbul sebagai akibat dari klaim pihak
          ketiga sehubungan dengan: (a) penggunaan Anda terhadap Situs; (B)
          Konten yang diberikan, disediakan atau diakses melalui Situs ini;
          (C) pelanggaran Anda terhadap Syarat tersebut; (D) pelanggaran hak
          atau kewajiban lainnya; dan/atau (e) setiap tindakan atau kelalaian
          oleh Anda, baik lalai, melanggar hukum atau lainnya.</li>
      </ol>
      <h4>PENGHENTIAN</h4>
      <ol>
        <li>Go
          Rental memiliki kebijaksanaan tunggal dan penuh untuk mengubah,
          menunda, tidak melanjutkan atau menghentikan Situs ini dan/atau
          bagian Situs, termasuk layanan atau produk yang disediakan dalam
          Situs ini, dan/atau penggunaan Situs ini, atau bagiannya, kapan saja
          dengan alasan apa pun tanpa pemberitahuan sebelumnya kepada Anda.</li>
        <li>Dalam
          hal terjadi penghentian, Anda tetap terikat oleh kewajiban dalam
          Syarat ini, termasuk namun tidak terbatas pada jaminan, ganti rugi,
          pelepasan dan pembatasan kewajiban yang telah Anda sepakati.</li>
        <li>Go
          Rental tidak akan bertanggung jawab kepada Anda atau pihak ketiga
          mana pun atas penghentian atau penangguhan akses Anda ke Situs.</li>
      </ol>
      <h4>PRIVASI</h4>
      <ol>
        <li>Go
        Rental menghargai kerahasiaan Pengguna. Go Rental akan berusaha
        keras untuk mematuhi persyaratan perundangan perlindungan data yang
        relevan saat melakukan kewajibannya menurut Syarat ini. Sebuah
        dokumen Kebijakan Privasi yang terpisah mengatur hak dan kewajiban
        Go Rental dan pengguna dalam kaitannya dengan privasi, tersedia
          di <a href="https://gorental-indonesia.com/privacy" target="_blank" rel="noopener noreferrer">sini</a></li>
      </ol>
      <h4>HUKUM YANG MENGATUR</h4>
      <ol>
        <li>Syarat
          ini akan diatur oleh dan ditafsirkan sesuai dengan dengan Hukum
          Indonesia semata.</li>
      </ol>
      <h4>PENYELESAIAN PERSELISIHAN</h4>
      <ol>
        <li>Dalam
          hal terjadi perselisihan yang timbul dari atau sehubungan dengan
          Syarat ini, kedua Pihak akan pertama-tama membahas dengan itikad
          baik untuk mencapai penyelesaian damai dalam waktu enam puluh (60)
          hari sejak tanggal pemberitahuan perselisihan. Namun, jika
          perselisihan tersebut tidak dapat diselesaikan melalui musyawarah
          dalam waktu enam puluh (60) hari, maka harus dirujuk ke mediasi.
          Jika perselisihan tetap belum terselesaikan, hal itu akan diajukan
          ke yurisdiksi eksklusif dari pengadilan Republik Indonesia.</li>
      </ol>
      <h4>UMUM</h4>
      <ol>
        <li>Syarat
          ini akan mengatur seluruh perjanjian dan pemahaman antara Anda dan
          Go Rental pada pokok daripadanya, dan akan menggantikan semua
          perjanjian sebelumnya, baik tertulis atau lisan, antara Anda dan Go
          Rental tentang pokok daripadanya.</li>
        <li>Jika
          Anda menggunakan Situs untuk atau atas nama pihak ketiga (&quot;Pihak
          ketiga&quot;), seperti anggota keluarga atau teman perjalanan, Anda
          bertanggung jawab atas setiap kesalahan dalam keakuratan informasi
          yang diberikan sehubungan dengan penggunaan tersebut. Selain itu,
          Anda harus menginformasikan Pihak ketiga dari semua syarat dan
          ketentuan yang berlaku. Bila menggunakan Situs untuk atau atas nama
          pihak ketiga, Anda setuju untuk mengganti kerugian dan tidak
          menyalahkan Go Rental dari dan terhadap setiap dan semua kewajiban,
          kerugian, kerusakan, gugatan dan klaim termasuk biaya hukum, yang
          timbul dari atau terhubung dengan pelanggaran Syarat tersebut atau
          kelalaian oleh Anda dan/atau Pihak ketiga.</li>
        <li>Dalam
          hal bahwa ketentuan apa pun ditetapkan untuk tidak dapat
          dilaksanakan atau tidak sah, oleh pengadilan dari yurisdiksi yang
          kompeten, maka ketentuan tersebut akan tetap diberlakukan sejauh
          diizinkan oleh hukum yang berlaku dan ketetapan tersebut tidak akan
          mempengaruhi validitas dan keberlakuan dari setiap ketentuan lainnya
          yang tersisa. Ketentuan yang terputus harus digantikan dengan
          ketentuan yang mendekati kata-kata asli dan maksud sebanyak mungkin.</li>
        <li>Kegagalan
          salah satu Pihak untuk melaksanakan setiap ketentuan dari Syarat ini
          kapan saja tidak akan ditafsirkan sebagai pelepasan hak
          masing-masing Pihak untuk melaksanakan pelanggaran ketentuan
          tersebut atau setiap ketentuan lainnya dalam Syarat ini atau sebagai
          pelepasan dari pelanggaran yang terus-menerus, yang berikut atau
          yang menyusul atas setiap ketentuan atau ketentuan lainnya dari
          Syarat ini.</li>
        <li>Anda
          tidak dapat menyerahkan atau mengalihkan hak atau kewajiban Anda
          menurut Syarat ini, tanpa persetujuan tertulis kami sebelumnya.</li>
        <li>Kami
          berhak untuk mengubah atau mencabut Syarat (atau bagiannya) atas
          kebijaksanaan tunggal kami. Kami dapat mengubah Syarat kapan saja
          dengan memasang perbedaannya pada Situs. Versi terbaru dari Syarat
          akan menggantikan semua versi sebelumnya.</li>
        <li>Pemberitahuan
          diberikan menurut Syarat ini harus dianggap layak dengan
          pertimbangan jika Syarat tertulis dan diteruskan melalui pos
          terdaftar atau layanan pengiriman tercatat atau setara kepada salah
          satu Pihak (di alamat terdaftar kami, atau diberitahukan lain).</li>
      </ol>
      <h4>FORCE MAJEURE</h4>
      <ol>
        <li>Go
          Rental tidak akan bertanggung jawab atas performa yang tidak
          dilakukan atau pelanggaran Syarat ini, seperti kegagalan transaksi,
          akses terbatas ke Situs, atau setiap kerusakan atau bahaya kepada
          pengguna yang disebabkan oleh tindakan atau kondisi apa pun di luar
          kontrol yang wajar baik oleh Anda atau Kami (&quot;Peristiwa Force
          Majeure&quot;). Peristiwa Force Majeure termasuk namun tidak
          terbatas pada bencana alam (banjir, gempa bumi), epidemi, kerusuhan,
          pernyataan perang, perang, aksi militer, tindakan teroris, embargo,
          sanksi, perubahan undang-undang atau peraturan, petir,
          badai/topan/siklon, mogok kerja, demonstrasi, kebangkrutan atau
          kepailitan maskapai penerbangan atau hotel, dan sebagainya.</li>
        <li>Go Rental tidak akan bertanggung jawab atas setiap kerusakan atau
          kerugian yang disebabkan oleh cara apa pun kepada pihak mana pun
          jika Go Rental tidak dapat memproses pesanan Anda karena Peristiwa
          Force Majeure.</li>
      </ol>
      <h4>TENTANG GO RENTAL</h4>
      <ol>
        <li>Jika Anda memiliki pertanyaan lebih lanjut, harap merujuk pada&nbsp;
          <a href="https://gorental-indonesia.com/airport-shuttle" target="_blank" rel="noopener noreferrer">FAQs</a>
        </li>
      </ol>

    </Post>
  );
};

export default TncContent;
