import React from 'react';
import Accordion from '../../../atoms/Accordion/Accordion';

const Permata = () => (
  <Accordion>
    <div label="ATM Prima/Alto">
      &bull; On main menu, choose other transactions  
      <br /><br />
      &bull; Choose Payment Transfer. 
      <br /><br />
      &bull; Choose Others. 
      <br /><br />
      &bull; Choose Virtual Account  payment. 
      <br /><br />
      &bull; Insert your Virtual Account  number and proceed 
      <br /><br />
      &bull; On the confirmation page,  there will be the amount to  pay, account number and  merchant name. If the  information is already  correct, proceed 
      <br /><br />
      &bull; Choose your source of  payment and proceed 
      <br /><br />
      &bull; Your transaction is  completed 
      <br /><br />
      &bull; Once the payment  transaction is completed, this  invoice will be updated  automatically. This may take  up to 5 minutes.
    </div>
    <div label="Permata Mobile Banking">
      &bull; Open Permata Mobile and  login 
      <br /><br />
      &bull; Choose "Pay Bills"/  "Pembayaran Tagihan" 
      <br /><br />
      &bull; Choose "Virtual Account" 
      <br /><br />
      &bull; Choose your source of  payment. 
      <br /><br />
      &bull; Choose "daftar tagihan baru" 
      <br /><br />
      &bull; Enter your Virtual Account  number  
      <br /><br />
      &bull; Check your transaction  information and proceed 
      <br /><br />
      &bull; Confirm your transaction 
      <br /><br />
      &bull; Enter SMS token response  code 
      <br /><br />
      &bull; Your transaction is  completed  
      <br /><br />
      &bull; Once the payment  transaction is completed, this  invoice will be updated  automatically. This may take  up to 5 minutes. 
    </div>
    <div label="Permata Internet Banking">
      &bull; Open  https://new.permatanet.com  and login 
      <br /><br />
      &bull; Choose "Pembayaran" 
      <br /><br />
      &bull; Choose "Pembayaran  Tagihan" 
      <br /><br />
      &bull; Choose "Virtual Account" 
      <br /><br />
      &bull; Choose your source of  payment 
      <br /><br />
      &bull; Choose "Masukkan Daftar  Tagihan Baru" 
      <br /><br />
      &bull; Enter your Virtual Account number 11111111111111  
      <br /><br />
      &bull; Check your detail information  and proceed 
      <br /><br />
      &bull; Enter SMS token response  code 
      <br /><br />
      &bull; Your transaction is  completed  
      <br /><br />
      &bull; Once the payment  transaction is completed, this  invoice will be updated  automatically. This may take  up to 5 minutes. 
    </div>
    <div label="Permata Mobile X">
      &bull; Open Permata Mobile X and  login 
      <br /><br />
      &bull; Choose "Pay Bills"/  "Pembayaran Tagihan" 
      <br /><br />
      &bull; Choose "Virtual Account" 
      <br /><br />
      &bull; Enter your Virtual Account  number  
      <br /><br />
      &bull; Your billing detail will show  on the screen. 
      <br /><br />
      &bull; The amount billed will show  on the screen. Choose your  source of payment. 
      <br /><br />
      &bull; Confirm your transaction 
      <br /><br />
      &bull; Enter SMS token response code 
      <br /><br />
      &bull; Your transaction is  completed  
      <br /><br />
      &bull; Once the payment  transaction is completed, this  invoice will be updated  automatically. This may take  up to 5 minutes. 
    </div>
    <div label="ATM Prima">
      &bull; On the main menu, choose other transactions  
      <br /><br />
      &bull; Choose Transfer. 
      <br /><br />
      &bull; Choose Other bank Transfer. 
      <br /><br />
      &bull; Insert 013 (Bank Permata  Code) and proceed 
      <br /><br />
      &bull; Insert your amount. Must be  same with the  invoice  amount 
      <br /><br />
      &bull; Insert your Virtual Account  number  
      <br /><br />
      &bull; On the confirmation page,  there will be the amount to  pay, account number and  merchant name. If the  information is already  correct, proceed 
      <br /><br />
      &bull; Your transaction is  completed 
      <br /><br />
      &bull; Once the payment  transaction is completed, this  invoice will be updated  automatically. This may take  up to 5 minutes
    </div>
    <div label="ATM Bersama">
      &bull; On main menu, choose other  transactions  
      <br /><br />
      &bull; Choose Transfer. 
      <br /><br />
      &bull; Choose Interbank Online  Transfer. 
      <br /><br />
      &bull; Insert 013 (Bank Permata  Code) followed by Virtual  Account number  
      <br /><br />
      &bull; Insert your amount. Must be  same with invoice amount 
      <br /><br />
      &bull; Leave the reference number  to be empty and proceed 
      <br /><br />
      &bull; On confirmation page, there  will be the amount to pay, account number and  merchant name. If the  information is already  correct, proceed  8. Your transaction is  completed 
      <br /><br />
      &bull; Once the payment  transaction is completed, this  invoice will be updated  automatically. This may take  up to 5 minutes. 
    </div>
    <div label="ATM Link">
      &bull; On the main menu, choose  others. 
      <br /><br />
      &bull; Choose "Transfer" 
      <br /><br />
      &bull; Enter 013 (Bank Permata  code) followed by Virtual  Account numbers, and then  proceed. 
      <br /><br />
      &bull; Enter the amount that you  will pay, the amount must be  the same with the invoice.  Then proceed 
      <br /><br />
      &bull; Choose your source of  payment and proceed 
      <br /><br />
      &bull; Leave the reference number  to be empty and proceed 
      <br /><br />
      &bull; On confirmation page, there  will be the amount to pay,  account number and  merchant name. If the  information is already  correct, proceed 
      <br /><br />
      &bull; Your transaction is  completed 
      <br /><br />
      &bull; Once the payment  transaction is completed, this  invoice will be updated  automatically. This may take  up to 5 minutes. 
    </div>
  </Accordion>
);

export default Permata;
