import React, {Component} from 'react'
import TextField from "../TextField/TextField";
import Text from "../Text/Text";
import './style.scss'

class Counter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      min: 1,
      max: 5,
    };
  }

  componentDidMount() {
    const { min, max } = this.props;

    this.setState({
      min: min || 1,
      max: max || 5,
    });
  }

  handlePlus = () =>{
    if (this.props.total < this.state.max) {
      this.props.onChange(this.props.total + 1);
    }
  };

  handleMinus = () => {
    if (this.props.total > this.props.min) {
      this.props.onChange(this.props.total - 1);
    }
  };

  render() {
    const { total } = this.props;

    return(
      <div className="c-counter">
        <div className="c-counter__body">
          <div className="c-counter__button" onClick={this.handleMinus}>
            <Text size="medium" bold>-</Text>
          </div>
          <TextField value={total} disabled />
          <div className="c-counter__button" onClick={this.handlePlus}>
            <Text size="medium" bold>+</Text>
          </div>
        </div>
      </div>
    );
  }
}

export default Counter
