import React, {Fragment} from 'react'
import Text from '../Text/Text'
import './style.scss'

const RadioBlock = props => {
  let baseClass = 'c-radio-block';
  const {
    title,
    id,
    name,
    value,
    checked,
    onChange,
    onClick,
  } = props;

  return (
    <Fragment>
      <label className={baseClass} htmlFor={id}>
        <input
          type="radio"
          name={name}
          value={value}
          id={id}
          checked={checked}
          onChange={onChange}
          onClick={onClick}
        />
        <div className="c-radio-block__item">
          <div className="c-radio-block__text">
            <div className="c-radio-block__title">
              <Text size="medium">
                {title}
              </Text>
            </div>
          </div>
        </div>
      </label>
    </Fragment>
  )
};

RadioBlock.defaultProps = {
  title: 'Title here',
  id: 'id-here',
  name: 'Name here'
};

export default RadioBlock
