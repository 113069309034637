import React from 'react'
import './style.scss'

const Flag = (props) => {

  let baseClass = 'c-flag';
  const { children} = props;

  return (
    <div className={baseClass}>
      <div className="c-flag__body">
        {children}
      </div>
    </div>
  )
}

export default Flag
