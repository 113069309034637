import React, {Fragment} from 'react'
import Text from '../../atoms/Text/Text'
import Thumbnail from '../../atoms/Thumbnail/Thumbnail'
import './style.scss'

const RadioImage = props => {
  let baseClass = 'c-radioimage';
  const {
    title,
    imageUrl,
    description,
    id,
    name,
    value,
    checked,
    onChange
  } = props;

  return (
    <Fragment>
      <label className={baseClass} htmlFor={id}>
        <input
          type="radio"
          name={name}
          value={value}
          id={id}
          checked={checked}
          onChange={onChange}
        />
        <div className="c-radioimage__item">
          <div className="c-radioimage__img">
            <Thumbnail
              imageUrl={imageUrl}
              alt={title}
            />
          </div>
          <div className="c-radioimage__text">
            <div className="c-radioimage__title">
              <Text size="medium">
                {title}
              </Text>
            </div>
            <div className="c-radioimage__desc">
              <Text>
                {description}
              </Text>
            </div>
          </div>
        </div>
      </label>
    </Fragment>
  )
};

RadioImage.defaultProps = {
  title: 'Title here',
  description: 'Description here',
  imageUrl: 'https://i.picsum.photos/id/520/500/500.jpg',
  id: 'id-here',
  name: 'Name here'
};

export default RadioImage
