import React from 'react'
import Section from "../../molecules/Section/Section";
import Heading from "../../atoms/Heading/Heading";
import Flexbox from "../../molecules/Flexbox/Flexbox";
import FlexboxItem from "../../atoms/FlexboxItem/FlexboxItem";
import Thumbnail from "../../atoms/Thumbnail/Thumbnail";
import Pertamina from "./img/pertamina.png";
import Mandiri from "./img/mandiri.png";
import BankSumut from "./img/bank-sumut.png";
import Traveloka from "./img/traveloka.png";
import Btn from "./img/btn.png";
import DealJava from "./img/dealjava.png";
import Telkomsel from "./img/telkomsel.png";
import Btpn from "./img/btpn.png";
import Pos from "./img/pos.png";
import './style.scss'

const Client = () => {

  return (
    <Section
      className="g-client"
      variant="o-container--fluid o-container--bleed"
    >
      <Heading tag="h2" className="u-align-center u-mb-60">
        Our Clients
      </Heading>
      <Section
        className="g-client__body"
        variant="o-container--fluid o-container--bleed"
      >
        <Flexbox className="o-grid--bleed">
          <FlexboxItem
            columnClass="col-xs-6 col-sm-4"
            className="g-client__item"
          >
            <Thumbnail
              imageUrl={Pertamina}
              alt="Pertamina"
            />
          </FlexboxItem>
          <FlexboxItem
            columnClass="col-xs-6 col-sm-4"
            className="g-client__item"
          >
            <Thumbnail
              imageUrl={Mandiri}
              alt="Bank Mandiri"
            />
          </FlexboxItem>
          <FlexboxItem
            columnClass="col-xs-6 col-sm-4"
            className="g-client__item"
          >
            <Thumbnail
              imageUrl={BankSumut}
              alt="Bank Sumut"
            />
          </FlexboxItem>
          <FlexboxItem
            columnClass="col-xs-6 col-sm-4"
            className="g-client__item"
          >
            <Thumbnail
              imageUrl={Traveloka}
              alt="Traveloka"
            />
          </FlexboxItem>
          <FlexboxItem
            columnClass="col-xs-6 col-sm-4"
            className="g-client__item"
          >
            <Thumbnail
              imageUrl={Btn}
              alt="Bank BTN"
            />
          </FlexboxItem>
          <FlexboxItem
            columnClass="col-xs-6 col-sm-4"
            className="g-client__item"
          >
            <Thumbnail
              imageUrl={DealJava}
              alt="Deal Java"
            />
          </FlexboxItem>
          <FlexboxItem
            columnClass="col-xs-6 col-sm-4"
            className="g-client__item"
          >
            <Thumbnail
              imageUrl={Telkomsel}
              alt="Telkomsel"
            />
          </FlexboxItem>
          <FlexboxItem
            columnClass="col-xs-6 col-sm-4"
            className="g-client__item"
          >
            <Thumbnail
              imageUrl={Btpn}
              alt="Telkomsel"
            />
          </FlexboxItem>
          <FlexboxItem
            columnClass="col-xs-6 col-sm-4"
            className="g-client__item"
          >
            <Thumbnail
              imageUrl={Pos}
              alt="Telkomsel"
            />
          </FlexboxItem>
        </Flexbox>
      </Section>
    </Section>
  )

};

export default Client
