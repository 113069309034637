import React, { Fragment } from 'react';
import Hero from '../../organism/Hero/Hero';
import Card from '../../atoms/Card/Card';
import Tabs from '../../atoms/Tabs/Tabs'
import Heading from '../../atoms/Heading/Heading';
import Content from '../../molecules/Content/Content';
import Section from '../../molecules/Section/Section';
import Flexbox from '../../molecules/Flexbox/Flexbox';
import FlexboxItem from '../../atoms/FlexboxItem/FlexboxItem';
import Faq from '../../organism/Faq/Faq';
import MediaQuery from 'react-responsive'
import ManangeRentForm from '../../organism/ManageRentForm';
import { Helmet } from 'react-helmet';

const ManageRent = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Car Rental - Go Rental</title>
        <meta name="description" content="24/7 service Medan KNO airport transfer and car rental (with driver) at competitive prices. With safety as our priority, our experienced driver will make sure you reach your destination safely & on time. Book with us today, hassle-free later!" />
      </Helmet>
      <Hero />
      <Content
        background="grey"
        isOverlapping
      >
        <Section>
          {/* DESKTOP VERSION */}
          <MediaQuery minDeviceWidth={960}>
            <Flexbox>
              <FlexboxItem columnClass="col-sm-6">
                <Card colorWhite>
                  <ManangeRentForm />
                </Card>
              </FlexboxItem>
              <FlexboxItem columnClass="col-sm-6">
                <Faq />
              </FlexboxItem>
            </Flexbox>
          </MediaQuery>
          {/* END DESKTOP VERSION */}

          {/* MOBILE VERSION */}
          <MediaQuery maxDeviceWidth={959}>
            <div className="u-mb-20 u-align-center">
              <Heading tag="h2" className="c-heading--white">
                Rent a Car
              </Heading>
            </div>
            <Card colorWhite isBleed>
              <Tabs color="white">
                <div labelName="Booking">
                  <ManangeRentForm />
                </div>
                <div labelName="FAQ">
                  <Faq />
                </div>
              </Tabs>
            </Card>
          </MediaQuery>
          {/* END MOBILE VERSION */}

        </Section>
      </Content>
    </Fragment>
  )
}

export default ManageRent;
