import React, {Component} from 'react'
import Flexbox from '../../molecules/Flexbox/Flexbox';
import FlexboxItem from '../../atoms/FlexboxItem/FlexboxItem';
import Text from '../../atoms/Text/Text';
import TextField from '../../atoms/TextField/TextField';
import DateTime from '../../molecules/DateTime/DateTime';
import DateTimeNative from '../../molecules/DateTimeNative/DateTimeNative';
import Button from '../../atoms/Button/Button';
import Separator from '../../atoms/Separator/Separator';
import CheckBox from '../../atoms/CheckBox/CheckBox';
import './style.scss';
import propTypes from 'prop-types';
import { airportShuttleModifyAction } from '../../../store/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import Preloader from '../../atoms/Preloader/Preloader';
import moment from 'moment'
import Shimmer from '../../atoms/Shimmer/Shimmer';
import { idr } from 'api/helpers/common';
import isEqual from 'lodash.isequal';

class ModifyForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialized: false,
      upgradeCar: false,
      additionalPriceDetails: [],
    };
  }

  componentDidMount() {
    const { initialized } = this.state;
    const {
      data,
      airportShuttleModify,
      setPayload,
      setErrorMessage,
      setCancelled,
      setUpdateBookingData,
    } = this.props;
    const { payload } = airportShuttleModify;

    if (!initialized) {
      this.setState({
        initialized: true,
      }, () => {
        setErrorMessage(null);
        setCancelled(false);
        setUpdateBookingData(null);
        setPayload({
          ...payload,
          phoneNumber: data.phoneNumber || null,
          pickupDatetime: moment(data.pickupDatetime || undefined).toDate(),
          address: data.address || null,
          flightNumber: data.flightNumber || null,
          productID: data.productID || 1,
          toAirport: data.toAirport || false,
          returnDatetime: moment(data.returnDatetime || undefined).toDate(),
          returnFlightNumber: data.returnFlightNumber || null,
          additionalAddress: data.additionalAddress || null,
        });
      });
    }
  }

  calculatePrice(promoCode, paymentMethod) {
    const {
      toAirport,
      airportID,
      pickupDatetime,
      flightNumber,
      numberOfPassanger,
      address,
      numberOfLuggage,
      numberOfCars,
      roundtrip,
      areaID,
      additionalAreaID,
      returnDatetime,
      productID,
    } = this.props.airportShuttleModify.payload;

    this.props.calculatePrice({
      toAirport,
      airportID,
      pickupDatetime,
      flightNumber,
      numberOfPassanger,
      address,
      numberOfLuggage,
      numberOfCars,
      roundtrip,
      areaID,
      additionalAreaID,
      returnDatetime,
      productID,
      promoCode,
      paymentMethod
    });
  }

  calculatePricePayload(airportShuttleModifyPayload) {
    const { paymentMethod, numberOfCars, roundtrip, pickupDatetime, returnDatetime } = airportShuttleModifyPayload;
    return { paymentMethod, numberOfCars, roundtrip, pickupDatetime, returnDatetime };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const isPayloadChanged = !isEqual(
      this.calculatePricePayload(prevProps.airportShuttleModify.payload),
      this.calculatePricePayload(this.props.airportShuttleModify.payload),
    );

    if (isPayloadChanged) this.updateDifferencePrice();
  }

  updateDifferencePrice() {
    const { data, airportShuttleModify, setPayload } = this.props;
    const { orderHash } = data;
    const { payload } = airportShuttleModify;
    this.props.getDifferencePrice({
      pickupDatetime: airportShuttleModify.payload.pickupDatetime,
      returnDatetime: airportShuttleModify.payload.returnDatetime,
      upgrade: this.state.upgradeCar,
      idHash: orderHash,
      numberOfCars: data.numberOfCars,
      roundtrip: data.roundtrip,
    }).then(({ amount, details }) => {
      this.setState({ additionalPriceDetails: details });
      setPayload({
        ...payload,
        difference: amount,
      });
    });
  }

  cancelOrder() {
    if (global.confirm('Are you sure want to cancel this order?')) {
      const { orderHash } = this.props.data;
      this.props.cancelOrder(orderHash);
    }
  }

  upgrade() {
    this.setState({
      upgradeCar: !this.state.upgradeCar,
    }, async () => {
      const { airportShuttleModify, setPayload } = this.props;
      const { payload } = airportShuttleModify;
      if (this.state.upgradeCar) {
        this.updateDifferencePrice();
        this.props.airportShuttleModify.payload.productID = 2
      } else {
        this.updateDifferencePrice()
        this.props.airportShuttleModify.payload.productID = 1
        this.setState({ additionalPriceDetails: [] });
        setPayload({
          ...payload,
          difference: 0,
        });
      }
    })
  }

  render() {
    const {
      data,
      history,
      setPhoneNumber,
      setFlightNumber,
      setReturnFlightNumber,
      setPickupDate,
      setAddress,
      setAdditionalAddress,
      // setProductId,
      // setToAirport,
      setReturnDate,
      airportShuttleModify,
      setErrorMessage,
      updateBooking,
    } = this.props;

    const {
      errorMessage,
      cancelling,
      cancelled,
      payload,
      fetchingDifferencePrice,
      differencePrice,
      updatingBooking,
      updateBookingData,
    } = airportShuttleModify;

    const { additionalPriceDetails } = this.state;

    return (
      <React.Fragment>
        <Preloader isActive={!!cancelling || !!updatingBooking} />
        <Preloader
          isActive={!!cancelled}
          displayImage={false}
          title="Order cancelled!"
          onClick={() => history.push('/')}
        />
        <Preloader
          isActive={!!updateBookingData}
          displayImage={false}
          title="Booking Updated!"
          onClick={() => history.push('/')}
        />
        <Preloader
          isActive={!!errorMessage}
          displayImage={false}
          title={errorMessage}
          onClick={() => setErrorMessage(null)}
        />
        <div className="g-modify-form">
          <div className="g-modify-form__body">
            <div className="u-mb-30">
              <Text size="medium">
                Fill up this information to modify your order
              </Text>
            </div>
            <div className="u-mb-30">
              <TextField
                type="number"
                placeholder="Mobile Phone Number"
                description=""
                value={payload.phoneNumber || ''}
                onChange={({ target }) => setPhoneNumber(target.value)}
              />
            </div>
            <div className="u-mb-30">
              <DateTime
                date={payload.pickupDatetime}
                onChange={date => setPickupDate(date)}
              />
              <DateTimeNative
                date={payload.pickupDatetime}
                setDate={date => setPickupDate(date)}
              />
            </div>
            <div className="u-mb-30">
              <Flexbox className="g-modify-form__dest">
                <FlexboxItem columnClass="col-xs-12 col-md-6" className="g-modify-form__dest-item">
                  <Text size="medium">Destination Area</Text>
                </FlexboxItem>
                <FlexboxItem columnClass="col-xs-12 col-md-6" className="g-modify-form__dest-item">
                  <Text size="medium" bold>{data.areaName}</Text>
                </FlexboxItem>
              </Flexbox>
            </div>
            <div className="u-mb-30">
              <TextField
                type="text"
                placeholder=""
                description=""
                value={payload.address || ''}
                onChange={({ target }) => setAddress(target.value)}
              />
            </div>
            <div className="u-mb-30">
              <TextField
                type="text"
                placeholder="Flight Number"
                description=""
                value={payload.flightNumber || ''}
                onChange={({ target }) => setFlightNumber(target.value)}
              />
            </div>
            {data.additionalAreaName && data.additionalAddress && (
              <React.Fragment>
                <div className="u-mb-30">
                  <Separator/>
                </div>
                <div className="u-mb-30">
                  <Flexbox className="g-modify-form__dest">
                    <FlexboxItem columnClass="col-xs-12 col-md-6" className="g-modify-form__dest-item">
                      <Text size="medium">2nd Destination Area</Text>
                    </FlexboxItem>
                    <FlexboxItem columnClass="col-xs-12 col-md-6" className="g-modify-form__dest-item">
                      <Text size="medium" bold>{data.additionalAreaName}</Text>
                    </FlexboxItem>
                  </Flexbox>
                </div>
                <div className="u-mb-30">
                  <TextField
                    type="text"
                    placeholder=""
                    description=""
                    value={payload.additionalAddress || ''}
                    onChange={({ target }) => setAdditionalAddress(target.value)}
                  />
                </div>
              </React.Fragment>
            )}
            {data.roundtrip && payload.returnDatetime && (
              <React.Fragment>
                <div className="u-mb-30">
                  <Separator/>
                </div>
                <div className="u-mb-30">
                  <Text size="medium">
                    Return Information
                  </Text>
                </div>
                <div className="u-mb-30">
                  <DateTime
                    date={payload.returnDatetime}
                    onChange={date => setReturnDate(date)}
                  />
                  <DateTimeNative
                    date={payload.returnDatetime}
                    setDate={date => setReturnDate(date)}
                  />
                </div>
                <div className="u-mb-30">
                  <TextField
                    type="text"
                    placeholder="Return Flight Number"
                    description=""
                    value={payload.returnFlightNumber || ''}
                    onChange={({ target }) => setReturnFlightNumber(target.value)}
                  />
                </div>
              </React.Fragment>
            )}
            {data.productID && data.productID === 1 && (
              <div className="u-mb-30">
                <CheckBox
                  title="I want to upgrade my car"
                  description="Additional fee will be charged"
                  name="upgrade-car"
                  onChange={() => this.upgrade()}
                />
              </div>
            )}
            {data.productID && data.productID === 2 && (
              <div className="u-mb-30">
                <Flexbox className="g-modify-form__dest">
                  <FlexboxItem columnClass="col-xs-12 col-md-6" className="g-modify-form__dest-item">
                    <Text size="medium">Car Type</Text>
                  </FlexboxItem>
                  <FlexboxItem columnClass="col-xs-12 col-md-6" className="g-modify-form__dest-item">
                    <Text size="medium" bold>Premium</Text>
                  </FlexboxItem>
                </Flexbox>
              </div>
            )}
            <div className="u-mb-30">
              <Separator/>
            </div>
            <div className="u-mb-30">
              <Flexbox>
                <FlexboxItem columnClass="col-xs-5 col-sm-6">
                  <Text bold size="medium">Additional Price</Text>
                </FlexboxItem>
                <FlexboxItem columnClass="col-xs-7 col-sm-6">
                  {fetchingDifferencePrice && <Shimmer style={{ width: '100%', height: '50px' }} isActive={true} />}
                  {!fetchingDifferencePrice && (
                    <div className="u-align-right">
                      <Text size="medium" bold>{idr(differencePrice)}</Text>
                      <Text>Pay cash directly to driver</Text>
                    </div>
                  )}
                </FlexboxItem>
                {!fetchingDifferencePrice && !!additionalPriceDetails.length && (
                  <FlexboxItem columnClass="col-xs-12 col-sm-12">
                    <Separator className="u-mt-30" />
                    <div className="u-mt-30" style={{ textAlign: 'right' }}>
                      {additionalPriceDetails.map((dt, index) =>(
                        <div key={index}>{dt.text}</div>
                      ))}
                    </div>
                  </FlexboxItem>
                )}
                {!!fetchingDifferencePrice && (
                  <FlexboxItem columnClass="col-xs-12 col-sm-12">
                    <Separator className="u-mt-30" />
                    <div className="u-mt-30" style={{ textAlign: 'right' }}>
                      <Shimmer style={{ width: '70%', height: '18px' }} isActive={true} />
                      <Shimmer style={{ width: '50%', height: '18px', marginTop: '2px' }} isActive={true} />
                    </div>
                  </FlexboxItem>
                )}
              </Flexbox>
            </div>
            <div className="u-mb-20">
              <Button
                disabled={updatingBooking || fetchingDifferencePrice}
                text="Modify"
                onClick={() => updateBooking(data.orderHash, payload)}
              />
            </div>
            <div>
              <Button
                isGhost
                text="Cancel Order"
                disabled={updatingBooking || fetchingDifferencePrice}
                onClick={() => this.cancelOrder()}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

ModifyForm.propTypes = {
  data: propTypes.object.isRequired,
};

const mapStateToProps = ({ airportShuttleModify }) => ({ airportShuttleModify });

const mapDispatchToProps = dispatch => {
  const {
    setPayload,
    setPhoneNumber,
    setFlightNumber,
    setPickupDate,
    setAddress,
    setAdditionalAddress,
    setProductId,
    setToAirport,
    setReturnDate,
    setErrorMessage,
    cancelOrder,
    setCancelled,
    getDifferencePrice,
    setDifferencePrice,
    updateBooking,
    setUpdateBookingData,
    setReturnFlightNumber,
  } = airportShuttleModifyAction;

  return bindActionCreators({
    setPayload,
    setPhoneNumber,
    setFlightNumber,
    setPickupDate,
    setAddress,
    setAdditionalAddress,
    setProductId,
    setToAirport,
    setReturnDate,
    setErrorMessage,
    cancelOrder,
    setCancelled,
    getDifferencePrice,
    setDifferencePrice,
    updateBooking,
    setUpdateBookingData,
    setReturnFlightNumber,
  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModifyForm));
