import React from 'react'
import './style.scss'

const Shimmer = ({ isActive, style }) => {
  let shimmerClass = 'c-shimmer';

  if (isActive) {
    shimmerClass += ' is-active'
  }

  return (
    <div className={shimmerClass} style={style} />
  )
};

export default Shimmer

