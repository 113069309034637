import React from 'react'
import Logo from '../../atoms/Logo/Logo'
import FlexboxItem from '../../atoms/FlexboxItem/FlexboxItem'
import Hamburger from '../../atoms/Hamburger/Hamburger'
import Section from '../../molecules/Section/Section'
import Flexbox from '../../molecules/Flexbox/Flexbox'
import Blocklist from '../../molecules/Blocklist/Blocklist'
import LogoGoRental from './img/logo.png'
import {Link} from "react-router-dom";
import HeadRoom from "react-headroom";
import './style.scss'

const Header = (props) => {
  const { showMenu } = props;

  return (
    <HeadRoom
      className="c-header"
    >
      <div
        className="c-header__body"
        style={{
          backgroundColor: showMenu ? '#000000' : '',
        }}
      >
        <Section>
          <Flexbox position="middle-xs middle-sm">
            <FlexboxItem columnClass="col-xs-4 col-sm-4 u-relative u-full-height">
              <Link to="/">
                <Logo
                  src={LogoGoRental}
                  alt="Go Rental"
                />
              </Link>
            </FlexboxItem>
            {showMenu &&
              <FlexboxItem columnClass="col-xs-8 col-sm-8">
                <Blocklist alignRight>
                  <Link to="/airport-shuttle">Airport Shuttle</Link>
                  <Link to="/car-rental">Car Rental</Link>
                  <Link to="/payment-method">Payment Method</Link>
                  <Link to="/transfer">Transfer</Link>
                </Blocklist>
                <Hamburger>
                  <Blocklist forDrawer>
                    <Link to="/airport-shuttle">Airport Shuttle</Link>
                    <Link to="/car-rental">Car Rental</Link>
                    <Link to="/payment-method">Payment Method</Link>
                    <Link to="/transfer">Transfer</Link>
                  </Blocklist>
                </Hamburger>
              </FlexboxItem>
            }
          </Flexbox>
        </Section>
      </div>
    </HeadRoom>
  )
};

export default Header
