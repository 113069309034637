import React from 'react'
import './style.scss'

const Card = props => {

  let cardClass = 'c-card';
  let cardBodyClass = 'c-card__body';

  const {colorWhite, colorGrey, children, useWrapper, isBleed} = props;

  if (useWrapper) {
    cardClass += ' c-card--wrapper'
  }

  if (colorWhite) {
    cardBodyClass += ' c-card__body--white'

  }
  if (colorGrey) {
    cardBodyClass += ' c-card__body--grey'
  }

  if (isBleed) {
    cardBodyClass += ' c-card__body--bleed'
  }

  return (
    <div className={ cardClass }>
      <div className={ cardBodyClass }>
        {children}
      </div>
    </div>
  )
};

export default Card

