import React from 'react';
import Countdown from 'react-countdown'
import './style.scss'

// Complete component
const Complete = ({ message }) => <span>{message || 'Order expired!'}</span>;

// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed, message }) => {
  if (completed) {
    // Render a complete state
    return <Complete message={message} />;
  } else {
    // Render a countdown
    return (
      <div className="c-countdown-timer">
        <div className="c-countdown-timer__body">
          <div className="c-countdown-timer__item">
            <div className="c-countdown-timer__number">
              {hours}
            </div>
            <div className="c-countdown-timer__title">
              hours
            </div>
          </div>
          <div className="c-countdown-timer__item">
            <div className="c-countdown-timer__number">
              {minutes}
            </div>
            <div className="c-countdown-timer__title">
              min
            </div>
          </div>
          <div className="c-countdown-timer__item">
            <div className="c-countdown-timer__number">
              {seconds}
            </div>
            <div className="c-countdown-timer__title">
              sec
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const CountdownTimer = props => (
  <Countdown date={Date.now() + props.milliSecond } renderer={renderer} />
);

export default CountdownTimer
