import React, { Component, Fragment } from 'react';
import Hero from '../../organism/Hero/Hero';
import Card from '../../atoms/Card/Card';
import Tabs from '../../atoms/Tabs/Tabs'
import Heading from '../../atoms/Heading/Heading';
import Content from '../../molecules/Content/Content';
import Section from '../../molecules/Section/Section';
import Flexbox from '../../molecules/Flexbox/Flexbox';
import FlexboxItem from '../../atoms/FlexboxItem/FlexboxItem';
import PaymentForm from '../../organism/PaymentForm/index';
import Faq from '../../organism/Faq/Faq';
import MediaQuery from 'react-responsive'
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import * as actions from '../../../store/actions';

const {
  setPromoCode,
  setPaymentMethod,
} = actions.airportShuttleBookingAction;

const {
  setState,
} = actions.carRentalBookingAction;

class PaymentMethod extends Component {
  setPaymentMethod(value) {
    this.props.setPaymentMethod(value);
    this.props.setState(['promoCode', value]);
  }

  setPromoCode(value) {
    this.props.setPromoCode(value);
    this.props.setState(['promoCode', value]);
  }

  componentDidMount() {
    const { airportShuttleBooking, carRentalBooking, history } = this.props;
    const { isValidPayload: isValidAirportShuttlePayload } = airportShuttleBooking;
    const { isValidPayload: isValidCarRentalPayload } = carRentalBooking;

    if (!isValidAirportShuttlePayload && !isValidCarRentalPayload) {
      history.push('/');
    }

    this.setPaymentMethod(null);
    this.setPromoCode(null);
  }

  render() {
    const { from } = this.props.history.location;
    let title = 'Book Airport Transfer';
    if (from === 'car-rental') title = 'Rent a Car - With Driver';

    return (
      <Fragment>
        <Helmet>
          <title>Payment Method - Go Rental</title>
          <meta name="description" content="24/7 service Medan KNO airport transfer and car rental (with driver) at competitive prices. With safety as our priority, our experienced driver will make sure you reach your destination safely & on time. Book with us today, hassle-free later!" />
        </Helmet>
        <Hero carRental={from === 'car-rental'}/>
        <Content
          background="grey"
          isOverlapping
        >
          <Section>
            {/* DESKTOP VERSION */}
            <MediaQuery minDeviceWidth={960}>
              <Flexbox>
                <FlexboxItem columnClass="col-sm-6">
                  <Card colorWhite>
                    <PaymentForm from={from} />
                  </Card>
                </FlexboxItem>
                <FlexboxItem columnClass="col-sm-6">
                  <Faq
                    title={title}
                    from={from}
                  />
                </FlexboxItem>
              </Flexbox>
            </MediaQuery>
            {/* END DESKTOP VERSION */}

            {/* MOBILE VERSION */}
            <MediaQuery maxDeviceWidth={959}>
              <div className="u-mb-20 u-align-center">
                <Heading tag="h2">
                  Book { title }
                </Heading>
              </div>
              <Card colorWhite isBleed>
                <Tabs color="white">
                  <div labelName="Booking">
                    <PaymentForm from={from} />
                  </div>
                  <div labelName="FAQ">
                    <Faq
                      title={title}
                      from={from}
                    />
                  </div>
                </Tabs>
              </Card>
            </MediaQuery>
            {/* END MOBILE VERSION */}
          </Section>
        </Content>
      </Fragment>
    )
  }
}

const mapStateToProps = ({ airportShuttleBooking, carRentalBooking }) => ({
  airportShuttleBooking,
  carRentalBooking,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setState,
  setPromoCode,
  setPaymentMethod,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(PaymentMethod));
