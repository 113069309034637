import React from 'react'
import Section from "../../molecules/Section/Section"
import Heading from "../../atoms/Heading/Heading"
import Flexbox from "../../molecules/Flexbox/Flexbox"
import FlexboxItem from "../../atoms/FlexboxItem/FlexboxItem"
import Thumbnail from "../../atoms/Thumbnail/Thumbnail"
import Step1 from "./img/step-1.png"
import Step2 from "./img/step-2.png"
import Step3 from "./img/step-3.png"
import Step4 from "./img/step-4.png"
import Text from "../../atoms/Text/Text"
import "./style.scss"

const HowItWorks = () => {

  return (
    <Section
      className="g-howitworks"
    >
      <Heading tag="h2" className="u-align-center u-mb-30">
        How it Works
      </Heading>
      <Flexbox
        className="g-howitworks__grid"
      >
        <FlexboxItem
          columnClass="col-xs-6 col-sm-3"
        >
          <div className="u-mb-35">
            <Thumbnail
              imageUrl={Step1}
              alt="Gorental"
            />
          </div>
          <div className="u-mb-15 u-align-center">
            <Text
              bold
              size="medium"
            >
              Step 1
            </Text>
          </div>
          <div className="u-align-center">
            <Text size="medium">
              Personalize your booking and reserve online
            </Text>
          </div>
        </FlexboxItem>
        <FlexboxItem
          columnClass="col-xs-6 col-sm-3"
        >
          <div className="u-mb-35">
            <Thumbnail
              imageUrl={Step2}
              alt="Gorental"
            />
          </div>
          <div className="u-mb-15 u-align-center">
            <Text
              bold
              size="medium"
            >
              Step 2
            </Text>
          </div>
          <div className="u-align-center">
            <Text size="medium">
              Pay using eWallet or virtual account
            </Text>
          </div>
        </FlexboxItem>
        <FlexboxItem
          columnClass="col-xs-6 col-sm-3"
        >
          <div className="u-mb-35">
            <Thumbnail
              imageUrl={Step3}
              alt="Gorental"
            />
          </div>
          <div className="u-mb-15 u-align-center">
            <Text
              bold
              size="medium"
            >
              Step 3
            </Text>
          </div>
          <div className="u-align-center">
            <Text size="medium">
              Receive email information on day prior
            </Text>
          </div>
        </FlexboxItem>
        <FlexboxItem
          columnClass="col-xs-6 col-sm-3"
        >
          <div className="u-mb-35">
            <Thumbnail
              imageUrl={Step4}
              alt="Gorental"
            />
          </div>
          <div className="u-mb-15 u-align-center">
            <Text
              bold
              size="medium"
            >
              Step 4
            </Text>
          </div>
          <div className="u-align-center">
            <Text size="medium">
              Sit back and enjoy the journey
            </Text>
          </div>
        </FlexboxItem>
      </Flexbox>
    </Section>
    )
}

export default HowItWorks
