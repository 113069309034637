import React from 'react'
import Section from "../../molecules/Section/Section";
import Heading from "../../atoms/Heading/Heading";
import Flexbox from "../../molecules/Flexbox/Flexbox";
import FlexboxItem from "../../atoms/FlexboxItem/FlexboxItem";
import Thumbnail from "../../atoms/Thumbnail/Thumbnail";
import Bca from "./img/bca.png";
import Mandiri from "./img/mandiri.png";
import Bri from "./img/bri.png";
import Ovo from "./img/ovo.png";
// import Gopay from "./img/gopay.png";
import Bni from "./img/bni.svg";
import './style.scss'

const Payment = () => {

  return (
    <Section
      className="g-payment"
      variant="o-container--fluid"
    >
      <Heading tag="h2" className="u-align-center u-mb-60">
        We Accept Payments From
      </Heading>

      <Section>
        <Flexbox position="o-grid--center">
          <FlexboxItem className="g-payment__item">
            <Thumbnail
              imageUrl={Bca}
              alt="Gorental"
            />
          </FlexboxItem>
          <FlexboxItem className="g-payment__item">
            <Thumbnail
              imageUrl={Mandiri}
              alt="Gorental"
            />
          </FlexboxItem>
          <FlexboxItem className="g-payment__item">
            <Thumbnail
              imageUrl={Bri}
              alt="Gorental"
            />
          </FlexboxItem>
          <FlexboxItem className="g-payment__item">
            <Thumbnail
              imageUrl={Bni}
              alt="Gorental"
            />
          </FlexboxItem>
          <FlexboxItem className="g-payment__item">
            <Thumbnail
              imageUrl={Ovo}
              alt="Gorental"
            />
          </FlexboxItem>
        </Flexbox>
      </Section>
    </Section>
  )
}

export default Payment
