import React from 'react'

const FlexboxItem = props => {

  let baseClass = 'o-grid__item';
  const {className, columnClass, children, style} = props;

  return (
    <div
      className={baseClass+' '+ (columnClass || "") + ' ' + (className || "") }
      style={style}
    >
      {children}
    </div>
  )
}

export default FlexboxItem
