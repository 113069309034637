import React from 'react';
import Accordion from '../../../atoms/Accordion/Accordion';

const BCA = () => (
  <Accordion>
    <div label="BCA ATM">
       &bull; Insert your BCA ATM card and PIN 
       <br /><br />
       &bull; Select Menu "Other Transaction". Select "Transfer". Select "To BCA Virtual Account" 
       <br /><br />
       &bull; Enter Virtual Account Number 1076613256631. Press "Correct" to proceed 
       <br /><br />
       &bull; Verify Virtual Account details and then enter amount to be transferred and select "Correct" to confirm 
       <br /><br />
       &bull; Confirm your transaction details displayed. Select "Yes" if the details are correct or "No" if thedetails are not correct 
       <br /><br />
       &bull; You have completed your transaction. Select "No" to end the transaction 
       <br /><br />
       &bull; Once the payment transaction is completed, this invoice will be updated automatically. This may take up to 5 minutes. 
    </div>
    <div label="BCA Mobile Banking">
      &bull; Login to BCA Mobile Banking  
      <br /><br />
      &bull; Select "m-BCA", then choose "m-Transfer" 
      <br /><br />
      &bull; Select "BCA Virtual Account" 
      <br /><br />
      &bull; Enter the Virtual Account Number, lalu pilih "OK" 
      <br /><br />
      &bull; Click "Send" on the top right of BCA mobile banking application
      <br /><br />
      &bull; Click "OK" to continue the payment
      <br /><br />
      &bull; Enter your PIN Anda to verify the payment 
      <br /><br />
      &bull; Once the payment transaction is completed, this invoice will be updated automatically. This may take up to 5 minutes. 
    </div>
    <div label="BCA Internet Banking">
      &bull; Login to KlikBCA Individual 
      <br /><br />
      &bull; Select "Transfer", then select "Transfer to BCA Virtual Account" 
      <br /><br />
      &bull; Enter the Virtual Account Number
      <br /><br />
      &bull; Select "Continue" to proceed your payment 
      <br /><br />
      &bull; Enter "RESPON KEYBCA APPLI1" shown in your BCA Token, then click on the "Send" button 
      <br /><br />
      &bull; Your transaction is successful 
      <br /><br />
      &bull; Once the payment transaction is completed, this invoice will be updated automatically. This may take up to 5 minutes. 
    </div>
  </Accordion>
);

export default BCA;
