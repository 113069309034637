import React, { useState, useEffect } from 'react'
import './style.scss'

const Hamburger = props => {

  const [menuActive, setMenuState] = useState('');

  const toggle = () => {
    setMenuState(menuActive === '' ? 'is-active' : '')
  };

  useEffect(() => {
    document.body.className = menuActive;
    return () => {
      document.body.className = menuActive;
    }
  });

  return (
    <div className={`c-hamburger ${menuActive}`} onClick={ toggle }>
      <div className="c-hamburger__head">
        Menu
      </div>
      <div className="c-hamburger__body">
        { props.children }
      </div>
    </div>
  )
}

export default Hamburger
