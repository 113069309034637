import React, { Component, Fragment } from "react";
import './style.scss'

export default class CountdownDate extends Component {
  state = {
    remaining: {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    },
    isExpired: false
  };
  // used to set and clear interval
  timer;
  // used to calculate the distance between "current date and time" and the "target date and time"
  distance;

  componentDidMount() {
    this.setDate();
    this.counter();
  }

  setDate = () => {
    const { targetDate, targetTime } = this.props,
      // Get todays date and time
      now = new Date().getTime(),

      // Set the date we're counting down to
      countDownDate = new Date(targetDate + " " + targetTime).getTime();

    // Find the distance between now and the count down date
    this.distance = countDownDate - now;

    // console.log(Math.floor((this.distance / (1000 * 60 * 60))), '<<< hours')
    // console.log(Math.floor((this.distance % (1000 * 60 * 60) / (1000 * 60))), '<<< minutes')
    // console.log(Math.floor((this.distance % (1000 * 60) / 1000)), '<<< second')
    // console.log(Math.floor((this.distance / (1000))), '<<< milisecond')

    // target date and time is less than current date and time
    if (this.distance < 0) {
      clearInterval(this.timer);
      this.setState({ isExpired: true });
    } else {
      this.setState({
        remaining: {
          hours: Math.floor((this.distance / (1000 * 60 * 60))),
          min: Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60)),
          sec: Math.floor((this.distance % (1000 * 60)) / 1000)
        },
        isExpired: false
      });
    }
  };

  counter = () => {
    this.timer = setInterval(() => {
      this.setDate();
    }, 1000);
  };

  render() {
    const { remaining, isExpired } = this.state,
      { targetDate, targetTime } = this.props;

    return (
      <Fragment>
        {!isExpired && targetDate && targetTime ? (
          <div className="counter">
            {Object.entries(remaining).map((el, i) => (
              <div key={i} className="entry">
                <div key={el[1]} className="entry-value">
                  <span className="count top flipTop">{el[1] + 1}</span>
                  <span className="count top">{el[1]}</span>
                  <span className="count bottom flipBottom">{el[1]}</span>
                  <span className="count bottom">{el[1] + 1}</span>
                </div>
                <div className="entry-title">{el[0]}</div>
              </div>
            ))}
          </div>
        ) : (
            <p className="alert-danger">Expired</p>
          )}
      </Fragment>
    );
  }
}
