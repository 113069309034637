import React from 'react'
import './style.scss'

const Text = props => {
  let textClass = 'c-text';
  let modifierClass = 'c-text--';

  const {
    size,
    bold,
    italic,
    underline,
    uppercase,
    children,
    descHero,
    grey
  } = props;

  if (bold) {
    textClass += ' c-text--bold'
  }

  if (underline) {
    textClass += ' c-text--underline'
  }

  if (italic) {
    textClass += ' c-text--italic'
  }

  if (uppercase) {
    textClass += ' c-text--upcase'
  }

  if (descHero) {
    textClass += ' c-text--desc-hero'
  }

  if (grey) {
    textClass += ' c-text--grey'
  }

  return (
    <p className={
      textClass + ' ' + modifierClass +(size)
    }>
      {children}
    </p>
  )
};

export default Text
