import React from 'react';
import { withRouter } from 'react-router';
import './style.scss';
import Text from '../../atoms/Text/Text';
import TextField from '../../atoms/TextField/TextField';
import Button from '../../atoms/Button/Button';
import Preloader from '../../atoms/Preloader/Preloader';
import dayjs from 'dayjs';

import { createInstance } from 'api/service/airportShuttle/factory';
import apiClient from 'api/client/private/factory';
import DateTime from '../../molecules/DateTime/DateTime';
import DateTimeNative from "../../molecules/DateTimeNative/DateTimeNative";

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:00';
const service = createInstance(apiClient);

class ModifyRentForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      success: false,
      successMessage: '',
      error: false,
      errorMessage: '',
      phoneNumber: '',
      pickupAddress: '',
      startRentalDate: new Date(),
      dropoffAddress: '',
      updatingBooking: false,
      showDropoffAddress: false,
      bookingData: {},
    };
  }

  componentDidMount() {
    this.setState({
      phoneNumber: this.props.data.phoneNumber || '',
      pickupAddress: this.props.data.pickupAddress || '',
      dropoffAddress: this.props.data.dropoffAddress || '',
      startRentalDate: dayjs(this.props.data.startRentalDate || undefined).toDate(),
      showDropoffAddress: !!this.props.data.dropoffAddress,
      bookingData: this.props.data,
    });
  }

  async updateBooking() {
    if (global.confirm('Are you sure want to modify this order?')) {
      this.setState({ updatingBooking: true });
      const { orderHash } = this.props.data;

      try {
        await service.apiClient.put(`/car_rental/${orderHash}`, {
          data: {
            phoneNumber: this.state.phoneNumber,
            pickupAddress: this.state.pickupAddress,
            dropoffAddress: this.state.dropoffAddress,
            startRentalDate: dayjs(this.state.startRentalDate).format(DATE_FORMAT),
          },
        });
      } catch (e) {
        this.setState({
          error: true,
          errorMessage: e.toString(),
        });
      } finally {
        this.setState({ updatingBooking: false });
      }
    }
  }

  async cancelOrder() {
    if (global.confirm('Are you sure want to cancel this order?')) {
      this.setState({ updatingBooking: true });
      try {
        await service.cancel(this.props.data.orderHash);
      } catch (e) {
        this.setState({
          error: true,
          errorMessage: e.toString(),
        });
      } finally {
        this.setState({ updatingBooking: false });
      }
    }
  }

  setPickupDate(date) {
    this.setState({ startRentalDate: dayjs(date || undefined).toDate()})
  }

  render() {
    const {
      success,
      successMessage,
      error,
      errorMessage,
      phoneNumber,
      pickupAddress,
      dropoffAddress,
      startRentalDate,
      updatingBooking,
      showDropoffAddress,
    } = this.state;
    return (
      <React.Fragment>
        <Preloader isActive={updatingBooking} />
        <Preloader
          displayImage={false}
          isActive={success}
          title={successMessage}
          onClick={() => this.props.history.push('/')}
        />
        <Preloader
          displayImage={false}
          isActive={error}
          title={errorMessage}
          onClick={() => this.setState({ error: false, errorMessage: '' })}
        />
        <div className="g-modify-form">
          <div className="g-modify-form__body">
            <div className="u-mb-30">
              <Text size="medium">
                Fill up this information to modify your order
              </Text>
            </div>

            <p style={{ margin: '8px 0', fontSize: '16px' }}>Phone Number</p>
            <div className="u-mb-30">
              <TextField
                type="number"
                placeholder="Mobile Phone Number"
                description=""
                value={phoneNumber || ''}
                onChange={({ target }) => this.setState({ phoneNumber: target.value })}
              />
            </div>

            {startRentalDate && (
              <div className="u-mb-30">
                <DateTime
                  date={startRentalDate}
                  onChange={date => this.setPickupDate(date)}
                />
                <DateTimeNative
                  date={startRentalDate}
                  setDate={date => this.setPickupDate(date)}
                />
              </div>
            )}

            <p style={{ margin: '8px 0', fontSize: '16px' }}>Pickup Address</p>
            <div className="u-mb-30">
              <TextField
                type="text"
                placeholder="Pickup Address"
                description=""
                value={pickupAddress || ''}
                onChange={({ target }) => this.setState({ pickupAddress: target.value })}
              />
            </div>

            {showDropoffAddress && (
              <React.Fragment>
                <p style={{ margin: '8px 0', fontSize: '16px' }}>Drop off Address</p>
                <div className="u-mb-30">
                  <TextField
                    type="text"
                    placeholder="Drop Off Address"
                    description=""
                    value={dropoffAddress || ''}
                    onChange={({ target }) => this.setState({ dropoffAddress: target.value })}
                  />
                </div>
              </React.Fragment>
            )}

            <div className="u-mb-20">
              <Button
                disabled={updatingBooking}
                text="Modify"
                onClick={() => this.updateBooking()}
              />
            </div>
            <div>
              <Button
                isGhost
                text="Cancel Order"
                disabled={updatingBooking}
                onClick={() => this.cancelOrder()}
              />
            </div>

          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ModifyRentForm);
