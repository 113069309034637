import React from 'react'
import Post from "../../molecules/Post/Post";

const Privacy = props => {

  return (
    <Post>
      <h3>Kebijakan Privasi</h3>

      <p>Gorental-Indonesia.com dimiliki dan dioperasikan oleh PT. Go Rental
      ("Go Rental" atau "Kami"). Kebijakan Privasi ini menjelaskan bagaimana
      kami mengumpulkan, menggunakan, menyingkapkan, memproses dan melindungi
      informasi pribadi yang teridentifikasi ("Data Pribadi") yang Anda
      ("Pengguna") dapat berikan sehubungan dengan layanan Go Rental yang
      tersedia melalui situs web di www.gorental-indonesia.com ("Situs"). Data
      Pribadi berarti data, apakah benar atau tidak, tentang seorang individu
      yang dapat diidentifikasi dari data tersebut, atau dari data dan informasi
      lainnya yang aksesnya dimiliki atau mungkin dimiliki organisasi. Dengan
      mengunjungi atau menggunakan Situs, Anda sepakat dan menyetujui pengumpulan,
      penggunaan, penyingkapan dan pemrosesan Data Pribadi Anda sesuai dengan
      Kebijakan Privasi ini dan/atau Persyaratan Situs Web. Jika Anda tidak setuju
      dengan Kebijakan Privasi di bawah ini, silakan segera tinggalkan Situs.</p>

      <p>Dari waktu ke waktu, Go Rental dapat merevisi Kebijakan Privasi ini untuk
      mencerminkan perubahan dalam hukum, pengumpulan dan praktik penggunaan Data
      Pribadi kami, fitur Situs kami, atau kemajuan dalam teknologi. Jika kami
      membuat revisi yang mengubah cara kami mengumpulkan atau menggunakan Data
      Pribadi Anda, perubahan tersebut akan dimuat di dalam Kebijakan Privasi ini
      dan tanggal berlaku akan dicatat pada permulaan Kebijakan Privasi ini. Oleh
      karena itu, Anda harus meninjau Kebijakan Privasi ini secara berkala
      sehingga Anda selalu mendapatkan informasi terkini akan kebijakan dan
      praktik kami saat ini. Go Rental juga akan memasang dengan jelas perubahan
      materi tersebut sebelum mengimplementasikan perubahannya. Jika Anda tidak
      setuju dengan perubahan atau modifikasi terhadap Kebijakan Privasi, harap
      jangan melanjutkan menggunakan Situs. Anda akan dianggap telah menyetujui
      setiap modifikasi Kebijakan Privasi ketika Anda menggunakan Situs setelah
      tanggal berlaku modifikasi.</p>

      <h4>Informasi yang Kami Kumpulkan</h4>

      <p>Kami mengumpulkan Data Pribadi mengenai Anda yang diberikan kepada
      kami saat menggunakan Situs. Data Pribadi tersebut termasuk namun tidak
      terbatas pada nama Anda, identifikasi pengguna Go Rental dan kredensial
      login, alamat, nomor telepon, alamat e-mail, nama hotel, lokasi hotel
      dan/atau lama tinggal. Kami dapat meminta Anda untuk memberikan informasi
      tentang preferensi perjalanan Anda, dan masukan tentang pengalaman
      perjalanan melalui pemesanan Go Rental Anda. Kami juga mengumpulkan
      informasi non-pribadi yang teridentifikasi (yang tidak dapat digunakan
      untuk mengidentifikasi Anda), termasuk namun tidak terbatas pada alamat
      protokol internet ("IP") Anda, data lokasi geografis, jenis sistem
      pengoperasian, kebangsaan, preferensi pencarian, begitu pula data umum
      lainnya terkait penggunaan Internet.</p>

      <h4>Bagaimana Kami Menggunakan Informasi Anda</h4>

      <p>Kami dapat menggunakan Data Pribadi Anda dan informasi lainnya yang
      dikumpulkan melalui Situs, baik di Indonesia atau di luar negeri,
      untuk tujuan berikut:</p>

      <ol>
        <li>mendaftar, mengelola dan/atau mengurus penggunaan dan/atau akses Situs;</li>
        <li>mengelola, mengoperasikan, mengurus dan memberikan Anda layanan yang
         di Situs;</li>
        <li>menghubungi Anda mengenai hal-hal yang berkaitan dengan penggunaan
        Anda dan/atau akses Situs serta layanan di Situs, dan setiap pertanyaan
        dan/atau permintaan yang diajukan oleh Anda melalui Situs atau sebaliknya;</li>
        <li>menyesuaikan pengalaman Anda saat menggunakan Situs;</li>
        <li>mengukur dan meningkatkan pengalaman dan kepuasan pelanggan;</li>
        <li>mempublikasikan ulasan pelanggan, seperti Situs, Agen Perjalanan,
        Penerbangan atau Hotel, dalam format digital dan/atau cetak untuk akses
        publik;</li>
        <li>menegakkan Syarat dan Ketentuan;</li>
        <li>menyelesaikan sengketa atau pengaduan, mengumpulkan pembayaran atau
        biaya, atau memecahkan masalah; dan/atau</li>
        <li>untuk tujuan lain yang diberitahukan kepada Anda pada saat pengumpulan.</li>
      </ol>

      <p>Kami juga dapat menggunakan Data Pribadi Anda dan informasi lainnya yang
      dikumpulkan untuk pemasaran Media Sosial menggunakan teknik grafik langsung
      dan terbuka, dan untuk tujuan pemasaran digital dan konvensional seperti
      mengirimkan Anda surat elektronik langsung dan memberitahukan tentang produk
      baru, penawaran khusus atau informasi lain yang menurut kami akan menarik
      bagi Anda. Harap dicatat bahwa Anda dapat memilih untuk keluar dari setiap
      materi pemasaran yang mungkin kami kirimkan kepada Anda kapan pun. Harap ikuti
      instruksi untuk berhenti berlangganan yang diatur di dalam materi pemasaran
      kami jika Anda memilih untuk keluar dari setiap materi pemasaran, dan kami
      akan menghormati keinginan Anda.</p>

      <h4>Berbagi dan Mentransfer Data Pribadi Anda</h4>

      <p>Data Pribadi Anda akan/dapat disingkapkan oleh Go Rental kepada perusahaan
      afiliasi Go Rental. Go Rental (dan/atau perusahaan afiliasinya) juga dapat
      menyingkap Data Pribadi Anda kepada penyedia layanan pihak ketiga, pemasok
      atau agen untuk satu atau lebih tujuan di atas dari waktu ke waktu. Layanan
      pihak ketiga termasuk namun tidak terbatas pada hosting situs web, analisis
      data, pemasaran, memproses transaksi kartu kredit, dan penyediaan layanan.</p>

      <p>Harap dicatat bahwa Go Rental dapat menyingkap Data Pribadi Anda dalam situasi berikut:</p>

      <ol>
        <li>untuk mengadakan atau membela terhadap klaim atau gugatan apa pun;</li>
        <li>untuk mematuhi perintah pengadilan, proses peradilan, permintaan yang
        sah, surat perintah atau setara oleh pejabat penegak hukum atau pihak yang
        berwenang;</li>
        <li>untuk menyelidiki penipuan atau kesalahan lainnya, atau seperti yang
        dipersyaratkan lainnya atau diperlukan dalam rangka mematuhi hukum yang
        berlaku, atau untuk melindungi kepentingan sah kami;</li>
        <li>untuk pembeli sehubungan dengan penjualan, pekerjaan, atau pengalihan
        lain dari semua atau bagian dari bisnis atau perusahaan kami;</li>
        <li>untuk menegakkan atau menerapkan syarat dan ketentuan yang berlaku bagi
        produk dan layanan kami;</li>
        <li>untuk melindungi hak, properti atau keamanan Go Rental , Pengguna Situs
        lainnya, atau orang lain manapun sesuai dengan kebijaksanaan Go Rental; dan</li>
        <li>untuk setiap situasi lain yang diperbolehkan menurut hukum.</li>
      </ol>

      <h4>Persetujuan</h4>

      <p>Sebagaimana dinyatakan di atas, dengan menjelajah dan menggunakan Situs, atau dengan memesan produk dari Situs, atau dengan mendaftarkan untuk atau menggunakan layanan di Situs, atau dengan mengeklik tombol "Konfirmasi" atau setara ketika Anda membuat akun baru pada situs, Anda:</p>

      <ol>
        <li>menyetujui Go Rental dan/atau perusahaan afiliasi Go Rental mengumpulkan,
        menggunakan, menyingkap dan/atau mengolah data pribadi yang disebutkan di
        atas untuk tujuan seperti yang dijelaskan di atas; dan</li>
        <li>menyetujui Go Rental dan/atau perusahaan afiliasi Go Rental mentransfer
        data pribadi Anda keluar dari Singapura ke lokasi perusahaan afiliasi Go
        Rental lain yang perusahaan afiliasi Go Rental lain tersebut ini berada,
        dan untuk penyedia layanan pihak ketiga Go Rental (atau perusahaan afiliasi
        Go Rental ), pemasok atau agen untuk tujuan seperti dijelaskan di atas.</li>
      </ol>

      <h4>Penarikan Persetujuan</h4>

      <p>Anda dapat menarik persetujuan terhadap pengumpulan, penggunaan atau
      penyingkapan kami atas data pribadi Anda setiap saat, dengan memberikan
      kami pemberitahuan yang beralasan. Jika Anda ingin menarik persetujuan
      Anda, harap beritahu kami pada detail kontak kami yang tertera di bawah
      ini. Kami akan berhenti mengumpulkan, menggunakan atau menyingkap data
      pribadi Anda setelah pemberitahuan, kecuali diwajibkan oleh hukum atau
      jika kami memiliki bisnis yang sah atau tujuan hukum untuk mempertahankannya.
      Harap memperhatikan bahwa dengan menarik persetujuan Anda terhadap
      pengumpulan, penggunaan atau penyingkapan kami atas data pribadi Anda,
      kami mungkin tidak dapat terus memberikan layanan kami kepada Anda, dan
      Anda setuju bahwa kami tidak akan bertanggung jawab kepada Anda atas setiap
      kerugian atau kerusakan yang timbul dari atau terkait penghentian layanan
      tersebut.</p>

      <h4>Kebijakan Cookie</h4>

      <p>"Cookie" adalah pengidentifikasi alfanumerik yang kami transfer ke
      hard drive atau kartu memori Anda melalui web browser ketika Anda
      mengunjungi situs kami. Hal ini memungkinkan sistem milik kami sendiri
      untuk mengenali Anda ketika Anda kembali mengunjungi situs kami dan
      meningkatkan layanan kami kepada Anda. Informasi ini digunakan untuk
      melacak penggunaan pengunjung Situs dan mengkompilasi laporan statistik
      mengenai aktivitas Situs. Untuk informasi lebih lanjut tentang cookie,
      kunjungi www.aboutcookies.org atau www.allaboutcookies.org. Cookie juga
      dapat digunakan untuk mengkompilasi informasi keseluruhan tentang area
      Situs kami yang paling sering dikunjungi. Informasi lalu lintas ini dapat
      digunakan untuk meningkatkan konten Situs kami dan membuat penggunaan Anda
      menjadi lebih mudah. Jika Anda ingin menolak cookie kami, Anda dapat
      mengkonfigurasi browser Anda untuk melakukannya. Namun, beberapa fitur
      Situs kami mungkin tidak berfungsi jika Anda menghapus cookie dari browser
      Anda. Jika Anda tidak mengaktifkan cookie, Anda mungkin tidak dapat mengakses
      fungsi atau fitur penting pada Situs ini, dan Anda mungkin hanya dapat
      menggunakan Situs secara terbatas.</p>

      <h4>Akses ke atau Perbaikan Data Pribadi Anda</h4>

      <p>Ketika Anda memberikan kami Data Pribadi Anda, pastikan datanya akurat
      dan lengkap. Jika Anda percaya bahwa setiap informasi Anda yang kami miliki
      atau kendalikan mengandung kesalahan atau kelalaian, harap login ke akun Anda
      di Situs dan perbaiki informasinya. Selain itu, harap perbarui Data Pribadi
      Anda melalui akun Anda pada waktu yang tepat, jika terdapat perubahan.</p>

      <p>Jika Anda ingin memperbaiki kesalahan atau kelalaian pada Data Pribadi
      manapun di bawah kepemilikan atau kendali kami yang tidak dapat dikoreksi
      melalui Situs kami, atau untuk mengakses Data Pribadi Anda di bawah
      kepemilikan atau kendali kami, atau sebagaimana diatur oleh hukum yang
      berlaku, harap kirimkan permintaan Anda ke detail kontak kami yang tertera
      di bawah ini. Kami mungkin membebani Anda biaya yang wajar untuk akses ke
      Data Pribadi, untuk tujuan pemulihan biaya tambahan karena menanggapi
      permintaan akses tersebut.</p>

      <p>Untuk melindungi privasi dan keamanan Anda, kami akan memverifikasi
      identitas Anda sebelum memberikan akses atau melakukan perubahan pada
      Data Pribadi Anda. Kami akan berusaha menjawab permintaan untuk akses atau
      perbaikan Data Pribadi Anda sesegera mungkin, kecuali berlaku pengecualian.</p>

      <h4>Melindungi Data Pribadi Anda</h4>
      <p>Kami melindungi Data Pribadi di bawah kepemilikan atau kendali kami dengan mempertahankan pengaturan keamanan yang wajar, termasuk prosedur fisik, teknis dan organisasi, untuk mencegah akses, pengumpulan, penggunaan, penyingkapan, penyalinan, modifikasi, penghapusan yang tidak sah atau risiko yang sama.</p>

      <h4>Tautan ke Situs Web Lain</h4>
      <p>Situs kami dapat berisi tautan ke situs-situs lain yang menarik. Namun, setelah Anda menggunakan tautan tersebut untuk meninggalkan situs kami, Anda harus mengetahui bahwa kami tidak memiliki kendali atas situs web lain tersebut. Harap diketahui bahwa kami tidak bertanggung jawab atas praktik privasi situs web lain tersebut dan menyarankan Anda untuk membaca pernyataan privasi dari masing-masing situs web yang Anda kunjungi yang mengumpulkan informasi pribadi.</p>

      <h4>Pendaftaran dan Keanggotaan</h4>
      <p>Situs ini memungkinkan Anda untuk membuat satu akun pengguna dengan mendaftar keanggotaan, berdasarkan data yang Anda berikan. Dengan memberikan data, mendaftar, dan membuat akun, Anda menjamin bahwa:</p>
      <ol>
        <li>Anda setidaknya berusia 18 tahun;</li>
        <li>Informasi tentang Anda adalah benar dan akurat, terkini dan lengkap sebagaimana diperlukan dalam formulir pendaftaran di Situs ("Data Pendaftaran"); dan</li>
        <li>Anda akan memperbarui Data Pendaftaran ini agar selalu benar, akurat dan lengkap.</li>
      </ol>

      <h4>Pengalihan Bisnis</h4>
      <p>Dalam hal terjadi perubahan kendali atau perubahan kepemilikan atas semua atau sebagian dari bisnis Go Rental atau perusahaan, termasuk situs, maka Data Pribadi Pengguna mungkin/akan menjadi bagian dari pengalihan aset tersebut.
</p>

      <h4>Persetujuan Perubahan Kebijakan Privasi</h4>
      <p>Dengan menggunakan Situs atau layanan yang kami sediakan, Anda setuju dengan pengumpulan, penggunaan, penyingkapan dan pengolahan Data Pribadi Anda sebagaimana diatur dalam Kebijakan Privasi ini. Lebih lanjut, jika Anda menggunakan layanan kami, kami berhak mengumpulkan, menggunakan, menyingkap dan memproses Data Pribadi Anda sesuai dengan Kebijakan Privasi ini. Dari waktu ke waktu, Go Rental dapat mengubah Kebijakan Privasi ini. Sebagaimana dijelaskan pada awal Kebijakan Privasi ini, kami akan menampilkan perubahan pada Situs ini untuk informasi Anda.</p>

      <h4>Urutan Prioritas</h4>
      <p>Jika Anda telah menyetujui Persyaratan Situs Web kami, dalam hal terdapat perbedaan antara Persyaratan Situs Web tersebut dan Kebijakan Privasi ini, maka Persyaratan Situs Web yang akan berlaku.</p>

      <h4>Disclaimer</h4>
      <p>Harap perhatikan bahwa mengingat kami merupakan perusahaan yang terdaftar di Singapura, maka Kebijakan Privasi ini telah disusun semata-mata sesuai dengan hukum Singapura. Kami tidak mewakili atau menjamin bahwa Kebijakan Privasi ini mematuhi undang-undang privasi dari setiap yurisdiksi lainnya dan dengan demikian, Anda tidak akan menafsirkan Kebijakan Privasi ini begitu saja.</p>
      <p>Untuk menghindari keraguan, atas pengecualian yang hukum mengizinkan suatu organisasi seperti Go Rental untuk mengumpulkan, menggunakan atau menyingkap data pribadi Anda tanpa persetujuan Anda, maka izin tersebut yang diberikan oleh hukum akan terus berlaku.</p>

      <h4>Hubungi Kami</h4>
      <p>Jika Anda memiliki pertanyaan atau permintaan apa pun yang berkaitan dengan Kebijakan Privasi ini, harap hubungi Petugas Perlindungan Data kami di detail berikut ini, atau orang yang mungkin ditunjuk oleh Go Rental dengan memberikan informasi kepada Anda melalui surat, faktur, email atau Situs: cs@gorental-indonesia.com.</p>

      <p>Efektif May 2020</p>
      <p>© 2020 Go Rental</p>
    </Post>
  );
};

export default Privacy;
