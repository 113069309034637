import React from 'react'

const Flexbox = props => {

  let flexboxClass = 'o-grid';

  const {className, children, position, style} = props;

  return (
    <div
      className={flexboxClass+' '+(position || '') +' '+ (className || '')}
      style={style}
    >
      { children }
    </div>
  )
}

export default Flexbox
