import React from 'react';
import MediaQuery from 'react-responsive'
import moment from 'moment';
import './style.scss';

let cachedDate;

const DateTimeNative = ({ date, setDate, minDate }) => {
  const dt = date && date instanceof Date ? date.getTime() : Date.now();

  const handleDate = ({ target }) => {
    const format = 'HH:mm:ss';
    const { value } = target;
    const time = moment(dt).format(format);
    const newDate = moment(`${value || cachedDate || moment().format(format)} ${time}`);
    setDate(newDate.toDate());

    if (value) cachedDate = value;
  };

  const handleTime = ({ target }) => {
    const newDate = moment(`${moment(dt).format('YYYY-MM-DD')} ${target.value}:00`);
    setDate(newDate.toDate());
  };

  return (
    <>
      <MediaQuery maxDeviceWidth={959}>
        <div className="c-datetime-native">
          <div className="c-datetime-native__body">
            <div className="c-datetime-native__item">
              <div className="c-datetime-native__title">
                Pickup Date
              </div>
              <div className="c-datetime-native__date">
                <input
                  type="date"
                  min={moment(minDate).format('YYYY-MM-DD')}
                  value={moment(dt).format('YYYY-MM-DD')}
                  onChange={handleDate}
                />
              </div>
            </div>
            <div className="c-datetime-native__item">
              <div className="c-datetime-native__title">
                Pickup Time
              </div>
              <div className="c-datetime-native__time">
                <input
                  type="time"
                  value={moment(dt).format('HH:mm')}
                  onChange={handleTime}
                />
              </div>
            </div>
          </div>
        </div>
      </MediaQuery>
    </>
  )
};

export default DateTimeNative
