import React, { Component, Fragment } from 'react'
import Heading from '../../atoms/Heading/Heading';
import Accordion from '../../atoms/Accordion/Accordion'
import Tagline from '../../molecules/Tagline/Tagline';
import Modal from '../../molecules/Modal/Modal'
import Button from '../../atoms/Button/Button';
import Thumbnail from "../../atoms/Thumbnail/Thumbnail"
import ZoneMap from "./img/zoneMap.jpg"
import Text from '../../atoms/Text/Text';
import { Route } from 'react-router-dom'
import './style.scss';
import { withRouter } from 'react-router';

class Faq extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mapActiveClass: ''
    };
    this.showMap = this.showMap.bind(this);
    this.hideMapModal = this.hideMapModal.bind(this);
  }

  showMap() {
    this.setState({
      mapActiveClass: 'is-active',
    });
  }

  hideMapModal() {
    this.setState({
      mapActiveClass: '',
    })
  }

  render() {
    const { match, from, title } = this.props;
    const { path } = match;

    const tagLine = path !== '/modify-order/:idHash' && (
      <div className="u-mb-40">
        <Tagline />
      </div>
    );

    return (
      <div className="g-faq">
        <div className="g-faq__body">
          <div className="u-mt-45 u-mb-90 u-desktop-only">

            {/* title for airport shuttle */}
            <Route path="/airport-shuttle">
              <Heading tag="h1">
                Book Airport Transfer
              </Heading>
            </Route>

            {/* title for payment method */}
            <Route path="/payment-method">
              <Heading tag="h1" style={{ color: from === 'car-rental' ? 'white' : 'black' }}>
                {title}
              </Heading>
            </Route>

            {/* title for modify order */}
            <Route path="/modify-order">
              <Heading tag="h1" style={{ color: from === 'car-rental' ? 'white' : 'black' }}>
                Modify Order
              </Heading>
            </Route>

            {/* title for car rental */}
            {(path === '/car-rental' || path === '/manage-rent') && (
              <Heading tag="h1" className="c-heading--white">
                Rent a Car - With Driver
              </Heading>
            )}
          </div>
          <div className="u-mb-25">
            <Route path="/airport-shuttle">
              {tagLine}
            </Route>

            {(path === '/car-rental' || path === '/manage-rent') && (
              <div className="u-mb-40">
                <Tagline carRental />
              </div>
            )}

            <Heading tag="h2">
              FAQ
            </Heading>

            {/* faq for airport shuttle */}
            <Route path="/airport-shuttle">
              <Accordion>
                <div isOpen label="How to Reschedule or Cancel My Airport Transfer Booking?">
                  &bull; The time <strong>limit</strong> to cancel or reschedule is <strong>24 hours</strong> before your initial pick up time.
                  <br /><br />
                  &bull; Rescheduling is subject to <strong>availability</strong>.
                  <br /><br />
                  &bull; In the case of cancellation, we will refund you in the form of a <i>single use</i> <strong>e-voucher</strong> (equal to the paid price). This <strong>e-voucher</strong> can be used for future reservation and will expire in 1 year.
                </div>
                <div label="What is the maximum capacity per car?">
                  &bull; <strong>“Standard”</strong> car: maximum of 4 people + 4 luggage
                  <br /><br />
                  &bull; <strong>“Premium”</strong> car: maximum of 4 people + 5 luggage
                </div>
                <div label="How long in advance should I book an airport transfer?">
                  &bull; 08.00-19.59: <strong>4 hours</strong> before your pick up time
                  <br></br>
                  &bull; 20.00-07.59: <strong>12 hours</strong> before your pick up time
                </div>
                <div label="What happens if my flight is delayed?">
                  You should <strong>immediately inform</strong> your driver or our hotline number. Our free waiting time is 1 hour from your pick up time. Additional charges of <strong>Rp 25,000 / hour</strong> will apply afterwards.
                </div>
                <div label="What time will you pick me up on my departure day?">
                  Your driver should be at your door <strong>15-25 minutes</strong> prior to your pick up time. Our free waiting time is 30 minutes from your pick up time. Additional charges of <strong>Rp 25,000 / half hour</strong> will apply afterwards.
                </div>
                <div label="When will I receive the pick-up details (e.g. vehicle license plate, driver’s name and contact details)?">
                  You will receive your pick – up details via email at least <strong>3 hours</strong> before your pickup time. If it’s not, please check your spam folder or contact our hotline number +62 853-6135-6135
                </div>
              </Accordion>
            </Route>

            {/* faq for payment method */}
            {path === '/payment-method' && from !== 'car-rental' && (
              <Accordion>
                <div isOpen label="How to Reschedule or Cancel My Airport Transfer Booking?">
                  &bull; The time limit to cancel or reschedule is 24 hours before your initial pick up time.
                  <br /><br />
                  &bull; Rescheduling is subject to availability.
                  <br /><br />
                  &bull; In the case of cancellation, we will refund you in the form of an <strong>e-voucher</strong> (equal to the paid price). This e-voucher can be used for future reservation and will expire in 1 year.
                </div>
                <div label="What is the maximum capacity per car?">
                  &bull; <strong>“Standard”</strong> car: maximum of 4 people + 4 luggage
                  <br /><br />
                  &bull; <strong>“Premium”</strong> car: maximum of 4 people + 5 luggage
                </div>
                <div label="How long in advance should I book an airport transfer?">
                  &bull; 08.00-19.59: <strong>4 hours</strong> before your pick up time
                  <br></br>
                  &bull; 20.00-07.59: <strong>12 hours</strong> before your pick up time
                </div>
                <div label="What happens if my flight is delayed?">
                  You should <strong>immediately inform</strong> your driver or our hotline number. Our free waiting time is 1 hour from your pick up time. Additional charges of <strong>Rp 25,000 / hour</strong> will apply afterwards.
                </div>
                <div label="What time will you pick me up on my departure day?">
                  Your driver should be at your door <strong>15-25 minutes</strong> prior to your pick up time. Our free waiting time is 30 minutes from your pick up time. Additional charges of <strong>Rp 25,000 / half hour</strong> will apply afterwards.
                </div>
                <div label="When will I receive the pick-up details (e.g. vehicle license plate, driver’s name and contact details)?">
                  You will receive your pick – up details via email at least <strong>3 hours</strong> before your pickup time. If it’s not, please check your spam folder or contact our hotline number +62 853-6135-6135
                </div>
              </Accordion>
            )}


            {/* faq for modify order */}
            <Route path="/modify-order/:idHash">
              {from !== 'car-rental' && (
                <Accordion>
                  <div isOpen label="How to Reschedule or Cancel My Airport Transfer Booking?">
                    &bull; The time limit to cancel or reschedule is 24 hours before your initial pick up time.
                    <br /><br />
                    &bull; Rescheduling is subject to availability.
                    <br /><br />
                    &bull; In the case of cancellation, we will refund you in the form of an <strong>e-voucher</strong> (equal to the paid price). This e-voucher can be used for future reservation and will expire in 1 year.
                  </div>
                  <div label="How to pay the additional fee?">
                    Our driver will collected the additional fee in person. You need to prepare the exact amount of cash because our driver won't be carrying cash.
                  </div>
                  <div label="Can I change my destination Area?">
                    No, once you set the destination area it can't be change. You can cancel the order and book again.
                  </div>
                </Accordion>
              )}
              {from === 'car-rental' && (
                <Accordion>
                  <div isOpen label="How to Reschedule or Cancel My Airport Transfer Booking?">
                    &bull; The time limit to cancel or reschedule is 24 hours before your initial pick up time.
                    <br /><br />
                    &bull; Rescheduling is subject to availability.
                    <br /><br />
                    &bull; In the case of cancellation, we will refund you in the form of an <strong>e-voucher</strong> (equal to the paid price). This e-voucher can be used for future reservation and will expire in 1 year.
                  </div>
                  <div label="How to pay the additional fee?">
                    Our driver will collected the additional fee in person. You need to prepare the exact amount of cash because our driver won't be carrying cash.
                  </div>
                  <div label="Can I change my destination Area?">
                    No, once you set the destination area it can't be change. You can cancel the order and book again.
                  </div>
                </Accordion>
              )}
            </Route>

            {/* faq for car rental */}
            <Route path="/car-rental">
              <Accordion>
                <div isOpen label="Out-of-Town Usage Guide">
                  &bull; Out-of-Town destinations are divided into <strong>Zone 1</strong> and <strong>Zone 2</strong>, with Zone 2 being the farthest. It is only applicable if you travel to Out-of-Town destinations. <a href="#" onClick={this.showMap}>(see map)</a>
                  <br /><br />
                  &bull; Additional fee applies if you travel to Zone 1 or Zone 2.
                  <br /><br />
                  &bull; Usage in Zone 2 already includes Zone 1
                  <br /><br />
                  &bull; This fee <strong>excludes</strong> expenses for fuel, tolls, parking, driver’s meals, and driver’s accommodation.
                </div>
                <div label="How do I know if I need to book an out-of-town trip?">
                  Check if your location is in the list below or <a href="#" onClick={this.showMap}>(see map)</a>
                  <br/><br/>
                  &bull; <strong>Zone 1</strong>
                  <br />
                  Berastagi, Kisaran Timur, Lima Puluh, Merek, Kaban Jahe, Kutabuluh, Uruk Ndaholi, Bukit Lawang, Pangkalan Brandan, Pangkalan Susu, Danau Lau Kawar, Kawah Putih Dolok Raja
                  <br /><br />
                  &bull; <strong>Zone 2</strong>
                  <br />
                  Destinations in Zone 1 + Bandara Silangit, Pematang Siantar, Perapat (Danau Toba), Pulau Samosir, Sarimatondang, Sitorang, SIrkuit Alam Sibodiala, Tanjung Balai, Dolok Sanggul, Sidakalang Regency, Air Terjun Spiso-piso, Serang Jaya, Damar Condong.
                </div>
                <div label="How to Reschedule or Cancel My Car Rental Booking?">
                  &bull; The time <strong>limit</strong> to cancel or reschedule is <strong>24 hours</strong> before your initial pick up time.
                  <br /><br />
                  &bull; Rescheduling is subject to <strong>availability</strong>.
                  <br /><br />
                  &bull; In the case of cancellation, we will refund you in the form of a <i>single use</i> <strong>e-voucher</strong> (equal to the paid price). This <strong>e-voucher</strong> can be used for future reservation and will expire in 1 year.
                </div>
                <div label="How long in advance should I book a car rental?">
                  &bull; 08.00-19.59: <strong>4 hours</strong> before your pick up time
                  <br></br>
                  &bull; 20.00-07.59: <strong>12 hours</strong> before your pick up time
                </div>
                <div label="When will I receive the pick-up details (e.g. vehicle license plate, driver’s name and contact details)?">
                  You will receive your pick – up details via email at least <strong>3 hours</strong> before your pickup time. If it’s not, please check your spam folder or contact our hotline number +62 853-6135-6135
                </div>
                <div label={`What car models are listed as “Standard”, “Premium”, “Luxury”?`}>
                  &bull; Standard: Daihatsu Xenia, Toyota Avanza, Mazda VX-1
                  <br></br>
                  &bull; Premium: Mazda Biante, Nissan Serena, Toyota Innova
                  <br></br>
                  &bull; Luxury: Toyota Vellfire, Mazda CX-8, Nissan Elgrand
                  <br></br>
                  <br></br>
                  *Capacity: Max 6 people with 2 luggage or 4 people with 4 luggage
                  <br></br>
                  **Subject to availability
                </div>
              </Accordion>
            </Route>

            {/* faq for manage rent */}
            <Route path="/manage-rent">
              <Accordion>
                <div isOpen label="Out-of-Town Usage Guide">
                  &bull; Out-of-Town destinations are divided into <strong>Zone 1</strong> and <strong>Zone 2</strong>, with Zone 2 being the farthest. It is only applicable if you travel to Out-of-Town destinations. <a href="#" onClick={this.showMap}>(see map)</a>
                  <br /><br />
                  &bull; Additional fee applies if you travel to Zone 1 or Zone 2.
                  <br /><br />
                  &bull; Usage in Zone 2 already includes Zone 1
                  <br /><br />
                  &bull; This fee <strong>excludes</strong> expenses for fuel, tolls, parking, driver’s meals, and driver’s accommodation.
                </div>
                <div label="How do I know if I need to book an out-of-town trip?">
                  Check if your location is in the list below or <a href="#" onClick={this.showMap}>(see map)</a>
                  <br/><br/>
                  &bull; <strong>Zone 1</strong>
                  <br />
                  Berastagi, Kisaran Timur, Lima Puluh, Merek, Kaban Jahe, Kutabuluh, Uruk Ndaholi, Bukit Lawang, Pangkalan Brandan, Pangkalan Susu, Danau Lau Kawar, Kawah Putih Dolok Raja
                  <br /><br />
                  &bull; <strong>Zone 2</strong>
                  <br />
                  Destinations in Zone 1 + Bandara Silangit, Pematang Siantar, Perapat (Danau Toba), Pulau Samosir, Sarimatondang, Sitorang, SIrkuit Alam Sibodiala, Tanjung Balai, Dolok Sanggul, Sidakalang Regency, Air Terjun Spiso-piso, Serang Jaya, Damar Condong.
                </div>
                <div label="How to Reschedule or Cancel My Car Rental Booking?">
                  &bull; The time <strong>limit</strong> to cancel or reschedule is <strong>24 hours</strong> before your initial pick up time.
                  <br /><br />
                  &bull; Rescheduling is subject to <strong>availability</strong>.
                  <br /><br />
                  &bull; In the case of cancellation, we will refund you in the form of a <i>single use</i> <strong>e-voucher</strong> (equal to the paid price). This <strong>e-voucher</strong> can be used for future reservation and will expire in 1 year.
                </div>
                <div label="How long in advance should I book a car rental?">
                  &bull; 08.00-19.59: <strong>4 hours</strong> before your pick up time
                  <br></br>
                  &bull; 20.00-07.59: <strong>12 hours</strong> before your pick up time
                </div>
                <div label="When will I receive the pick-up details (e.g. vehicle license plate, driver’s name and contact details)?">
                  You will receive your pick – up details via email at least <strong>3 hours</strong> before your pickup time. If it’s not, please check your spam folder or contact our hotline number +62 853-6135-6135
                </div>
                <div label={`What car models are listed as “Standard”, “Premium”, “Luxury”?`}>
                  &bull; Standard: Daihatsu Xenia, Toyota Avanza, Mazda VX-1
                  <br></br>
                  &bull; Premium: Mazda Biante, Nissan Serena, Toyota Innova
                  <br></br>
                  &bull; Luxury: Toyota Vellfire, Mazda CX-8, Nissan Elgrand
                  <br></br>
                  <br></br>
                  *Capacity: Max 6 people with 2 luggage or 4 people with 4 luggage
                  <br></br>
                  **Subject to availability
                </div>
              </Accordion>
            </Route>

            {/* faq for payment method */}
            {path === '/payment-method' && from === 'car-rental' && (
              <Accordion>
                <div isOpen label="Out-of-Town Usage Guide">
                  &bull; Out-of-Town destinations are divided into <strong>Zone 1</strong> and <strong>Zone 2</strong>, with Zone 2 being the farthest. It is only applicable if you travel to Out-of-Town destinations. <a href="#" onClick={this.showMap}>(see map)</a>
                  <br /><br />
                  &bull; Additional fee applies if you travel to Zone 1 or Zone 2.
                  <br /><br />
                  &bull; Usage in Zone 2 already includes Zone 1
                  <br /><br />
                  &bull; This fee <strong>excludes</strong> expenses for fuel, tolls, parking, driver’s meals, and driver’s accommodation.
                </div>
                <div label="How do I know if I need to book an Out-of-Town trip?">
                  Check if your location is in the list below or <a href="#" onClick={this.showMap}>(see map)</a>
                  <br/><br/>
                  &bull; <strong>Zone 1</strong>
                  <br />
                  Berastagi, Kisaran Timur, Lima Puluh, Merek, Kaban Jahe, Kutabuluh, Uruk Ndaholi, Bukit Lawang, Pangkalan Brandan, Pangkalan Susu, Danau Lau Kawar, Kawah Putih Dolok Raja
                  <br /><br />
                  &bull; <strong>Zone 2</strong>
                  <br />
                  Destinations in Zone 1 + Bandara Silangit, Pematang Siantar, Perapat (Danau Toba), Pulau Samosir, Sarimatondang, Sitorang, SIrkuit Alam Sibodiala, Tanjung Balai, Dolok Sanggul, Sidakalang Regency, Air Terjun Spiso-piso, Serang Jaya, Damar Condong.
                </div>
                <div label="How to Reschedule or Cancel My Car Rental Booking?">
                  &bull; The time <strong>limit</strong> to cancel or reschedule is <strong>24 hours</strong> before your initial pick up time.
                  <br /><br />
                  &bull; Rescheduling is subject to <strong>availability</strong>.
                  <br /><br />
                  &bull; In the case of cancellation, we will refund you in the form of a <i>single use</i> <strong>e-voucher</strong> (equal to the paid price). This <strong>e-voucher</strong> can be used for future reservation and will expire in 1 year.
                </div>
                <div label="How long in advance should I book a car rental?">
                  &bull; 08.00-19.59: <strong>4 hours</strong> before your pick up time
                  <br></br>
                  &bull; 20.00-07.59: <strong>12 hours</strong> before your pick up time
                </div>
                <div label="When will I receive the pick-up details (e.g. vehicle license plate, driver’s name and contact details)?">
                  You will receive your pick – up details via email at least <strong>3 hours</strong> before your pickup time. If it’s not, please check your spam folder or contact our hotline number +62 853-6135-6135
                </div>
                <div label={`What car models are listed as “Standard”, “Premium”, “Luxury”?`}>
                  &bull; Standard: Daihatsu Xenia, Toyota Avanza, Mazda VX-1
                  <br></br>
                  &bull; Premium: Mazda Biante, Nissan Serena, Toyota Innova
                  <br></br>
                  &bull; Luxury: Toyota Vellfire, Mazda CX-8, Nissan Elgrand
                  <br></br>
                  <br></br>
                  *Capacity: Max 6 people with 2 luggage or 4 people with 4 luggage
                  <br></br>
                  **Subject to availability
                </div>
              </Accordion>
            )}
          </div>
        </div>

        {/* map modal info */}
        <Modal
          sizeSmall
          hideCloseButton
          className={`${this.state.mapActiveClass}`}
          handleClose={this.hideMapModal}
        >
          {this.state.mapActiveClass &&
            <Fragment>
              <div className="u-mb-25">
                <Text size="medium" bold>Zone Map</Text>
              </div>
              <Thumbnail
                imageUrl={ZoneMap}
                alt="Gorental"
                className="u-mb-25"
              />
              <Button
                onClick={this.hideMapModal}
                text="Close Map"
              />
            </Fragment>
          }
        </Modal>
        {/* map modal info */}
      </div>
    )
  }
}

export default withRouter(Faq)
