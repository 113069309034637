import React from 'react'
import './style.scss'

const RadioButton = props => {
  let baseClass = 'c-radio';
  const { title, description, checked, id, name, onChange } = props;

  return (
    <div className={baseClass}>
      <label className="c-radio__label" htmlFor={id}>
        <div className="c-radio__title">
          {title}
        </div>
        <input
          type="radio"
          id={id}
          name={name}
          checked={checked}
          onChange={onChange}
        />
        <span className="c-radio__mark" />
        <div className="c-radio__desc">
          {description}
        </div>
      </label>
    </div>
  )
};

RadioButton.defaultProps = {
  title: 'Title here',
  description: 'Description here'
};

export default RadioButton
