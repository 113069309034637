import React from 'react';
import { withFormsy } from 'formsy-react';
import TextField from '../TextField/TextField';
import './style.scss';

class FormsyTextField extends React.Component {
  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(event) {
    this.props.setValue(event.currentTarget.value);
  }

  render() {
    const { errorMessage, value } = this.props;

    return (
      <React.Fragment>
        <TextField {...this.props} error={!!errorMessage} />
        {value && errorMessage && (
          <div className="c-error">
            <span className="c-error--message">{errorMessage}</span>
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default withFormsy(FormsyTextField);
