import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { orderAction } from '../../../store/actions';
import Section from '../../molecules/Section/Section';
import Heading from '../../atoms/Heading/Heading';
import Text from '../../atoms/Text/Text';

const { getOrder } = orderAction;

class VoucherPay extends React.Component {
  constructor(props) {
    super(props);

    this.timer = null;
    this.state = {
      initialized: false,
    };
  }

  componentDidMount() {
    const { match, history, getOrder } = this.props;
    const { idHash } = match.params;

    if (!idHash) {
      return history.push('/');
    }

    getOrder(idHash).then(() => {
      this.setState({ initialized: true });
    });
  }

  render() {
    return (
      <React.Fragment>
        <Section
          variant="o-container--tiny"
          className="u-mt-80 u-mb-80"
          style={{ marginTop: '200px' }}
        >
          <div className="u-align-center u-mb-30">
            <Heading tag="h3" className="u-mb-8">
              <span>Your booking has been made using voucher</span>
            </Heading>
            <Text size="medium">
              <span>Please check your email for detailed information</span>
            </Text>
          </div>
        </Section>
      </React.Fragment>
    );
  }
};

const mapStateToProps = ({ order }) => ({ order });

const mapDispatchToProps = dispatch => bindActionCreators({ getOrder }, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(VoucherPay),
);
