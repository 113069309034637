import React, { Component } from 'react';
import Text from '../../atoms/Text/Text';
import Calendar from '../../molecules/Calendar/Calendar'
import Separator from '../../atoms/Separator/Separator';
import Flexbox from '../../molecules/Flexbox/Flexbox';
import FlexboxItem from '../../atoms/FlexboxItem/FlexboxItem';
import Shimmer from '../../atoms/Shimmer/Shimmer';
import Button from '../../atoms/Button/Button';
import Preloader from '../../atoms/Preloader/Preloader';
import './style.scss';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../store/actions';
import { withRouter } from 'react-router';
import {idr} from "api/helpers/common";
import {Link} from "react-router-dom";

class ManageRentForm extends Component {

  componentWillMount() {
    if (this.props && this.props.carRentalBooking.totalPrice && this.props.carRentalBooking.totalPrice.outOfTown[0].zoneID !== 1) {
      this.props.carRentalBooking.outOfTowns[
        this.props.carRentalBooking && this.props.carRentalBooking.totalPrice.outOfTown[0].zoneID === 2 ? 0 : 1
      ].push(this.props.carRentalBooking.totalPrice.outOfTown[0].date)
    }
    if (this.props && this.props.carRentalBooking.totalPrice && this.props.carRentalBooking.totalPrice.outOfTown[1] && this.props.carRentalBooking.totalPrice.outOfTown[1].zoneID !== 1) {
      this.props.carRentalBooking.outOfTowns[
        this.props.carRentalBooking && this.props.carRentalBooking.totalPrice.outOfTown[1].zoneID === 2 ? 0 : 1
      ].push(this.props.carRentalBooking.totalPrice.outOfTown[1].date)
    }
  }

  pricePayload(payload) {
    return {
      pickupAddress: payload.pickupAddress,
      pickupDistrictID: payload.pickupDistrictID,
      startRentalDate: payload.startRentalDate,
      dropoffAddress: payload.dropoffAddress,
      dropoffDistrict: payload.dropoffDistrict,
      numberOfCars: payload.numberOfCars,
      rentalDuration: payload.rentalDuration,
      rentalHours: payload.rentalHours,
      allInPackage: payload.allInPackage,
      productID: payload.productID,
      promoCode: payload.promoCode,
      paymentMethod: payload.paymentMethod,
      outOfTown: payload.outOfTown,
    };
  }

  onCalendarSelected(e, index, date) {
    const { checked } = e.target;
    if (checked) {
      this.props.addOutOfTown({ index, date });
    } else {
      this.props.removeOutOfTown({index, date});
    }

    setTimeout(() => {
      this.props.calculatePrice(this.pricePayload(this.props.carRentalBooking.payload));
    }, 0);
  }

  render() {
    const { carRentalBooking } = this.props;
    const {
      isCalculating,
      isValidPayload,
      outOfTowns,
      payload,
      totalPrice,
    } = carRentalBooking;
    const { startRentalDate, rentalDuration } = payload;
    return (
      <div className="g-manage-rent-form">
        <Preloader isActive={false} />
        <Preloader
          isActive={false}
          displayImage={true}
          title="Preloader Message"
          onClick={() => { }}
        />
        <div className="g-manage-rent-form__body">
          <div className="u-mb-30">
            <Text size="medium">Manage your Out-of-Town rental</Text>
          </div>
          <div className="u-mb-40">
            <Text
              size="medium"
              bold
            >
              Select your dates for Zone 1
            </Text>
            <div className="u-mb-15">
              <Text>
                Berastagi, Kisaran Timur, Lima Puluh, Merek, Kaban Jahe, Kutabuluh, Uruk Ndaholi, Bukit Lawang, Pangkalan Brandan, Pangkalan Susu, Danau Lau Kawar, Kawah Putih Dolok Raja.
              </Text>
            </div>

            <Calendar
              date={startRentalDate}
              days={rentalDuration}
              disabledDates={outOfTowns[1]}
              selectedDates={outOfTowns[0]}
              onChange={(e, date) => this.onCalendarSelected(e, 0, date)}
            />
          </div>

          <div className="u-mb-30">
            <Separator />
          </div>
          <div className="u-mb-40">
            <Text
              size="medium"
              bold
            >
              Select your dates for Zone 2
            </Text>
            <div className="u-mb-15">
              <Text>
                Bandara Silangit, Pematang Siantar, Perapat (Danau Toba), Pulau Samosir, Sarimatondang, Sitorang, SIrkuit Alam Sibodiala, Tanjung Balai, Dolok Sanggul, Sidakalang Regency, Air Terjun Spiso-piso, Serang Jaya, Damar Condong.
              </Text>
            </div>

            <Calendar
              date={startRentalDate}
              days={rentalDuration}
              disabledDates={outOfTowns[0]}
              selectedDates={outOfTowns[1]}
              onChange={(e, date) => this.onCalendarSelected(e, 1, date)}
            />

          </div>
          <div className="u-mb-30">
            <Separator />
          </div>
          <div className="u-mb-30">
            <Flexbox>
              <FlexboxItem columnClass="col-xs-5 col-sm-6">
                <Text bold size="medium">Total price</Text>
              </FlexboxItem>
              <FlexboxItem columnClass="col-xs-7 col-sm-6">
                <div className="u-align-right">
                  { !!isCalculating && (
                    <Shimmer style={{ width: '120px', height: '26px' }} isActive={true} />
                  )}
                  { !isCalculating && (
                    <Text size="medium" bold>{idr(totalPrice.amount || 0)}</Text>
                  )}
                  <Text>excludes fuel, toll, parking and driver's meal</Text>
                </div>
              </FlexboxItem>
              {!isCalculating && totalPrice.details && !!totalPrice.details.length && (
                <FlexboxItem columnClass="col-xs-12 col-sm-12">
                  <Separator className="u-mt-30" />
                  <div className="u-mt-30" style={{ textAlign: 'right' }}>
                    {totalPrice.details.map((dt, index) =>(
                      <div key={index}>{dt.text}</div>
                    ))}
                  </div>
                </FlexboxItem>
              )}
              {!!isCalculating && (
                <FlexboxItem columnClass="col-xs-12 col-sm-12">
                  <Separator className="u-mt-30" />
                  <div className="u-mt-30" style={{ textAlign: 'right' }}>
                    <Shimmer style={{ width: '70%', height: '18px' }} isActive={true} />
                    <Shimmer style={{ width: '50%', height: '18px', marginTop: '2px' }} isActive={true} />
                  </div>
                </FlexboxItem>
              )}
            </Flexbox>
          </div>
          <div className="u-mb-30">
            {!isCalculating && isValidPayload && (
              <Button
                text="Proceed to payment"
                onClick={() => this.props.history.push({
                  pathname: '/payment-method',
                  from: 'car-rental',
                })}
              />
            )}

            {(!!isCalculating || !isValidPayload) && (
              <Button
                text={isCalculating ? 'Loading, please wait...' : 'Proceed to payment' }
                disabled={true}
              />
            )}

            <div className="u-align-center">
              <Link to={{
                pathname: '/car-rental',
                state: {
                  back: true,
                },
              }}>
                <Button
                  isGhost
                  text="Back to Order Form"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ carRentalBooking }) => ({ carRentalBooking });

const {
  calculatePrice,
  addOutOfTown,
  removeOutOfTown,
  clearOutOfTown,
} = actions.carRentalBookingAction;

const mapDispatchToProps = dispatch => bindActionCreators({
  calculatePrice,
  addOutOfTown,
  removeOutOfTown,
  clearOutOfTown,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ManageRentForm));
