import React from 'react'
import './style.scss'

const ListStyle = props => {

  let baseClass = 'c-liststyle'
  const {icon, title, description, className} = props;

  return (
    <div className={baseClass +' '+ (className || '')}>
      <div className={baseClass + '__head'}>
        <img src={icon || 'https://placehold.it/20x20'} alt={title} />
      </div>
      <div className={baseClass + '__body'}>
        <div className={baseClass + '__title'}>
          {title}
        </div>
        <div className={baseClass + '__desc'}>
          {description}
        </div>
      </div>
    </div>
  )
}

export default ListStyle
