import React, {Fragment} from 'react'
import './style.scss'

const Button = props => {
  let buttonClass =  'c-button';

  const {useLinkTo, useIcon, isGhost, text, onClick, disabled, insideInput, hasBorder} = props;

  if (isGhost) {
    buttonClass += ' c-button--ghost'
  }

  if (useIcon) {
    buttonClass += ' c-button--icon'
  }

  if (insideInput) {
    buttonClass += ' c-button--inside-input'
  }

  if (hasBorder) {
    buttonClass += ' c-button--has-border'
  }

  return (
    <Fragment>
      {!useLinkTo &&
      <button
        className={buttonClass}
        onClick={onClick}
        disabled={disabled}
      >
        <div className="c-button__text">
          {text}
        </div>
        { useIcon &&
        <div className="c-button__icon">
          <img src={useIcon} alt={text}/>
        </div>
        }
      </button>
      }
      {useLinkTo &&
      <div className={buttonClass} onClick={onClick} disabled={disabled}>
        <div className="c-button__text">
          {text}
        </div>
        { useIcon &&
        <div className="c-button__icon">
          <img src={useIcon} alt={text}/>
        </div>
        }
      </div>
      }
    </Fragment>
  )
};

export default Button
