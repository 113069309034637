import {
  createReducer as apiCreateReducer,
  airportShuttleBooking,
  airportShuttleModify,
  area,
  carRentalBooking,
  district,
  order,
  paymentMethod,
  products,
} from 'api';

const reducers = {
  airportShuttleBooking,
  airportShuttleModify,
  area,
  carRentalBooking,
  district,
  order,
  paymentMethod,
  products,
};

export const createReducer = (asyncReducers = {}) => apiCreateReducer({
  asyncReducers,
  reducers,
});

export default createReducer;
