import React from 'react';
import Accordion from '../../../atoms/Accordion/Accordion';

const BRI = () => (
  <Accordion>
    <div label="BRI ATM">
      &bull; Select​ ​Other​ ​Transaction 
      <br /><br />
      &bull; Select​ ​Other 
      <br /><br />
      &bull; Select​ ​Payment​ ​menu 
      <br /><br />
      &bull; Select​ ​Other 
      <br /><br />
      &bull; Select​ ​BRIVA​ ​menu 
      <br /><br />
      &bull; Input​ ​​your​ ​BRI​ ​Virtual​ ​Account. (Example: 26215-xxxxxxxxxxxx),​ ​then press​ ​“Yes” 
      <br /><br />
      &bull; Payment​ ​confirmation,​ ​select  “Yes”​ ​if​ ​transaction​ ​details​ ​are correct 
    </div>
    <div label="BRI Mobile Banking">
      &bull; Log​ ​in​ ​to​ ​Mobile​ ​Banking 
      <br /><br />
      &bull; Select​ ​Payment​ ​menu 
      <br /><br />
      &bull; Select​ ​BRIVA 
      <br /><br />
      &bull; Input​ ​your​ ​BRI​ ​Virtual​ ​Account number​ ​and​ ​the​ ​amount​ ​billed  5. Input​ ​your​ ​PIN​ ​Number 
      <br /><br />
      &bull; Press​ ​“OK”​ ​to​ ​continue​ ​your transaction 
      <br /><br />
      &bull; The​ ​transaction​ ​is​ ​successful 
      <br /><br />
      &bull; A​ ​confirmation​ ​SMS​ ​will​ ​be sent​ ​to​ ​your​ ​phone​ ​number 
    </div>
    <div label="BRI Internet Banking">
      &bull; Input​ ​User​ ​ID​ ​and​ ​Password 
      <br /><br />
      &bull; Select​ ​Payment​ ​menu 
      <br /><br />
      &bull; Select​ ​BRIVA 
      <br /><br />
      &bull; Select​ ​payment​ ​account 
      <br /><br />
      &bull; Input​ ​​your​ ​BRI​ ​Virtual​ ​Account number.​ ​(Example: 26215-xxxxxxxxxxxx) 
      <br /><br />
      &bull; Input​ ​the​ ​amount​ ​billed 
      <br /><br />
      &bull; Input​ ​your​ ​password​ ​and  Mtoken
    </div>
    <div label="Other Banks' ATM">
      &bull; Select​ ​Others 
      <br /><br />
      &bull; Select​ ​Transfer 
      <br /><br />
      &bull; Select​ ​from​ ​saving 
      <br /><br />
      &bull; Select​ ​to​ ​other​ ​Bank 
      <br /><br />
      &bull; Enter​ ​Bank​ ​Code​ ​followed​ ​by  your​ ​Virtual​ ​Account​ ​number  (BRI​ ​002+​xxxxxx​xxxxxxxxx) 
      <br /><br />
      &bull; Input​ ​the​ ​amount​ ​billed​ ​as transfer​ ​amount 
      <br /><br />
      &bull; Finish,​ ​transaction​ ​has​ ​been successful
    </div>
    <div label="Other Banks' Mobile Banking or Internet Banking">
      &bull; Input​ ​User​ ​ID​ ​and​ ​Password 
      <br /><br />
      &bull; Select​ ​Transfer 
      <br /><br />
      &bull; Select​ ​Transfer​ ​to​ ​other​ ​bank 
      <br /><br />
      &bull; Select​ ​bank​ ​destination 
      <br /><br />
      &bull; Enter​ ​Virtual​ ​Account​ ​number  (BRI​ ​​xxxxxx​xxxxxxxxx) 
      <br /><br />
      &bull; Input​ ​the​ ​amount​ ​billed​ ​as transfer​ ​amount 
      <br /><br />
      &bull; Finish,​ ​transaction​ ​has​ ​been successful 
    </div>
  </Accordion>
);

export default BRI;
