import React from 'react';
import Accordion from '../../../atoms/Accordion/Accordion';

const BNI = () => (
  <Accordion>
    <div label="BNI ATM">
      &bull; Insert your ATM card and select "ENGLISH"
      <br /><br />
      &bull; Select your language. 
      <br /><br />
      &bull; Enter your ATM PIN 
      <br /><br />
      &bull; Select “Other Transaction”. 
      <br /><br />
      &bull; Select “Transfer”. 
      <br /><br />
      &bull; Select the type of account you are using to transfer (i.e. from Savings account). 
      <br /><br />
      &bull; Choose “Virtual Account Billing”. 
      <br /><br />
      &bull; Enter your Virtual Account Number (i.e.:  88089999XXXXXX). 
      <br /><br />
      &bull; Confirm the transaction, if it’s  correct, continue. 
      <br /><br />
      &bull; Your transaction is completed. 
    </div>
    <div label="BNI Mobile Banking">
      &bull; Access BNI Mobile Banking from your phone, and insert your user ID and password. 
      <br /><br />
      &bull; Select the menu “Transfer”. 
      <br /><br />
      &bull; Select the menu “Virtual Account Billing” and then choose debit account. 
      <br /><br />
      &bull; Enter your Virtual Account Number (i.e.: 88089999XXXXXX) on the menu “input new”. 
      <br /><br />
      &bull; Confirm your billing 
      <br /><br />
      &bull; Enter your password 
      <br /><br />
      &bull; Your transaction is complete
    </div>
    <div label="BNI Internet Banking">
      &bull; Enter the following address: https://ibank.bni.co.id and click “Enter”. 
      <br /><br />
      &bull; Insert your User ID and Password. 
      <br /><br />
      &bull; Select the menu “Transfer” 
      <br /><br />
      &bull; Select “Virtual Account Billing”. 
      <br /><br />
      &bull; Enter your Virtual Account Number (i.e.: 88089999XXXXXX). And choose the type of account you are using to transfer. And press “Continue”. 
      <br /><br />
      &bull; Reconfirm the transaction 
      <br /><br />
      &bull; Enter the authentication  token code. 
      <br /><br />
      &bull; Your transaction is completed.  
    </div>
    <div label="Other Banks' ATM">
      &bull; Select​ ​Others
      <br /><br />
      &bull; Select​ ​Transfer
      <br /><br />
      &bull; Select​ ​from​ ​saving
      <br /><br />
      &bull; Select​ ​to​ ​other​ ​Bank
      <br /><br />
      &bull; Enter​ ​Bank​ ​Code​ ​followed​ ​by your​ ​Virtual​ ​Account​ ​number​ ​(BNI 009+xxxxxxxxxxxxxxx)
      <br /><br />
      &bull; Input​ ​the​ ​amount​ ​billed​ ​as Transfer​ ​Amount
      <br /><br />
      &bull; Finish,​ ​transaction​ ​has​ ​been successful
    </div>
    <div label="Other Banks' Mobile Banking or Internet Banking">
      &bull; Input​ ​User​ ​ID​ ​and​ ​Password
      <br /><br />
      &bull; Select​ ​Transfer
      <br /><br />
      &bull; Select​ ​Transfer​ ​to​ ​other​ ​bank
      <br /><br />
      &bull; Select​ ​bank​ ​destination
      <br /><br />
      &bull; Enter​ ​Virtual​ ​Account​ ​number (BNI​ ​xxxxxxxxxxxxxxx)
      <br /><br />
      &bull; Input​ ​the​ ​amount​ ​billed​ ​as Transfer​ ​Amount
      <br /><br />
      &bull; Finish,​ ​transaction​ ​has​ ​been successful 
    </div>
  </Accordion>
);

export default BNI;
